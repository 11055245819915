// NOTE: This component is not used after integrating Azure AD Authentication

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/form";
import FormControl from "react-bootstrap/formcontrol";
import InputGroup from "react-bootstrap/inputgroup";
import { connect } from "react-redux";
import { checkUserLogin } from "../../../store/actions/loginActions/loginAction";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Logo from "../../../logo.png";
import Button from "../../button";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;
const successMessage = { value: false, message: "Username is available!" };

yup.addMethod(yup.string, "checkUsername", function (args) {
    const message = args;
    return this.test("checkUsername", message, async function (value) {
        const { path, createError } = this;
        try {
            const res = await axios.post("http://localhost:5000/checkUsername", { "username": value });
            if (res.data.length > 0) {
                return createError({ path, message });
            } else {
                return true;
            }
        } catch (error) {
            return createError({ path, message: "Server is down! Please try again later" });
        }
    });
});

const schema = yup.object().shape({
    username: yup.string().trim("").required("").checkUsername("Username not available!"),
    password: yup.string().required("")
})

function SignUpModal(props) {

    const { register, errors, handleSubmit } = useForm({ mode: "onChange", shouldUnregister: true, resolver: yupResolver(schema) });

    const [loginError, setLoginError] = useState("");

    const login = (username, isValidUser) => {
        successMessage.value = false;
        props.checkUserLogin({ username, isValidUser });
    }

    const submitForm = (data, e) => {
        e.preventDefault();
        successMessage.value = false;
        axios.post(`${basePath}/users`, data)
            .then(res => {
                if (res.data.length > 0) {
                    setLoginError("");
                    handleClose();
                    login(data.username, true);
                } else {
                    setLoginError("Server down! Please try again later");
                }
            })
            .catch(err => {
                setLoginError("Server down! Please try again later");
            })
    }

    const handleClose = () => {
        successMessage.value = false;
        props.onHide();
    }

    const onUsernameChange = (e) => {
        if (e.target.value.trim() === "") {
            successMessage.value = false;
        } else {
            successMessage.value = true;
        }
    }

    return (
        <Modal show={props.show} onHide={handleClose} dialogClassName="modal-90w medium-text" backdrop="static">
            <Modal.Body className="noMargin">
                <button type="button" className="close" onClick={handleClose}>&times;</button>
                <h3 className="centerText">
                    <img width="60" src={Logo} alt="Capgemini" />
                    <br />
                    <span>Sign Up</span>
                </h3>
                <hr />
                <Form onSubmit={handleSubmit(submitForm)}>
                    <Form.Row>
                        <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                            Username
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="fa fa-user" style={{ fontSize: "15px" }}></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="text" id="inlineFormInputGroup" placeholder="Username" name={"username"} autoComplete={"off"} onChange={onUsernameChange} ref={register} />
                        </InputGroup>
                        {errors && errors["username"] && errors["username"].type !== "required" && (<p className="error"><i className="fa fa-times-circle" style={{ color: "red" }}></i> {errors["username"].message}<hr /></p>)}
                        {isEmpty(errors["username"]) && successMessage && successMessage.value && (<p className="success"><i className="fa fa-check"></i> {successMessage.message}<hr /></p>)}
                    </Form.Row>
                    <Form.Row>
                        <Form.Label htmlFor="inlineFormInputGroup2" srOnly>
                            Password
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="fa fa-key" style={{ fontSize: "11px" }}></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="password" id="inlineFormInputGroup2" placeholder="Password" name={"password"} autoComplete={"off"} ref={register} />
                        </InputGroup>
                    </Form.Row>
                    <Form.Row>
                        <p className="error">{loginError}</p>
                    </Form.Row>
                    <hr />
                    <Button variant="primary" className="fullWidth" type="submit">Sign Up</Button>
                    <hr />
                    <hr />
                </Form>
            </Modal.Body>
        </Modal>
    )
}


function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


const mapStateToProps = (state) => {
    return {
        login: state.login
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkUserLogin: (data) => {
            dispatch(checkUserLogin(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);