import React from "react";

const calculatorColor = {
    fuel: { name: "Fuel", color: "#F99500" },
    electricity: { name: "Electricity", color: "#FFFF00" },
    paper: { name: "Paper", color: "#FFFFF0" },
    plastic: { name: "Plastic", color: "#40A0B5" },
    metal: { name: "Metal", color: "#d7dbdc" },
    /* transport: { name: "Transport", color: "#f37d7d" }, */
    electricalItems: { name: "ElectricalItems", color: "#228C22" },
    aviation: { name: "Aviation", color: "#6f91ad" },
    seaFreight: { name: "SeaFreight", color: "#009dc4" },
    dataCentre: { name: "Data Centre", color: "#66FFCC" },
    waste: { name: "Waste", color: "#40A0B5" },
    delivery: { name: "Delivery", color: "#7444DA" },
    water: { name: "Water", color: "#148AFF" }
}

const logoDashline = {
    0: {width: "15px", color: "#EDEDED"},
    1: {width: "9px", color: "#454545"},
    2: {width: "7px", color: "#C5C5C5"},
    3: {width: "6px", color: "#797979"},
    4: {width: "4px", color: "#FEFEFE"},
    5: {width: "3px", color: "#8D8D8D"},
    6: {width: "2px", color: "#DBDBDB"},
    7: {width: "2px", color: "#A0A0A0"}
}

const Legend = (props) => {

    const { type, data } = props;

    if (type === "label") {
        return (
            <div style={{margin: "0px", display: "flex", flexWrap: "nowrap", marginTop: "5px"}}>
                {
                    Object.keys(calculatorColor).map(k => {
                        return (
                            <div key={`legend-${props.name}-${k}`} style={{padding: "0px", margin: "0px", flexGrow: 1}}>
                                <span style={{ padding: "0px 7px", fontSize: "14px", backgroundColor: calculatorColor[k].color }}></span>&nbsp;&nbsp;<span style={{ fontSize: "14px", fontFamily: "Ubuntu" }}>{calculatorColor[k].name}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    } else if (type === "no-label" && data !== undefined && data !== null) {
        return (
            <>
                {
                    Object.keys(data).map(k => {
                        return (
                            <span style={{ borderRadius: "3.3px", padding: "0px 3.3px", fontSize: "6px", backgroundColor: calculatorColor[k].color }} key={`no-legend-${props.name}-${k}`}></span>
                        )
                    })
                }
            </>
        )
    } else {
        return (
            <>
                {
                    Object.keys(logoDashline).map(k => {
                        return (
                            <span style={{ padding: "0px "+logoDashline[k].width, fontSize: "4px", backgroundColor: logoDashline[k].color }} key={`logoDashline-${props.name}-${k}`} ></span>
                        )
                    })
                }
            </>
        )
    }
}

export default Legend;