import React, { useState, useEffect, useRef, useReducer } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row, Col, CardDeck, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Popover from "react-bootstrap/popover";
import OverlayTrigger from "react-bootstrap/overlaytrigger";
import ToastCustom from "../../components/toastCustom/ToastCustom";
import { clearSession } from "../../store/actions/loginActions/clearSessionAction";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import Pagination from "../../components/pagination/PaginationCustom";
import Card from "../../components/card/CardCustom";
import Select from "../../components/select/SelectCustom";
import SearchInput from "../../components/input/SearchInput";
import { setCurrentProject, unsetCurrentProject } from "../../store/actions/projectActions/projectAction";
import FilterCustom from "../../components/filter/FilterCustom";
import { confirm } from "../../components/confirm";
import "./Projects.scss";
import { clearFilter } from "../../store/actions/filterActions/filterAction";
import { setSort } from "../../store/actions/sortActions/sortAction";
import {
    setFilterProjectId,
    setFilterProjectName,
    setFilterCTFuel,
    setFilterCTElectricity,
    setFilterCTDelivery,
    setFilterCTDataCentre,
    setFilterCTWater,
    setFilterCTPaper,
    setFilterCTPlastic,
    setFilterCTMetal,
   /*  setFilterCTTransport, */
    setFilterCTElectricalItems,
    setFilterCTAviation,
    setFilterCTSeaFreight,
} from "../../store/actions/filterActions/filterAction";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const sortOptions = [
    { value: "updatedAt,DESC", label: "Most Recent" },
    {
        value: "name,ASC", label: (<span>Name&nbsp;&nbsp;<FontAwesomeIcon
            color="#f0f0f0"
            icon={faArrowUp}
            size="sm"
            style={{ paddingBottom: "1px" }}
        /></span>)
    },
    {
        value: "name,DESC", label: (<span>Name&nbsp;&nbsp;<FontAwesomeIcon
            color="#f0f0f0"
            icon={faArrowDown}
            size="sm"
            style={{ paddingBottom: "1px" }}
        /></span>)
    },
    {
        value: "carbonFootprint,ASC", label: (<span>CF&nbsp;&nbsp;<FontAwesomeIcon
            color="#f0f0f0"
            icon={faArrowUp}
            size="sm"
            style={{ paddingBottom: "1px" }}
        /></span>)
    },
    {
        value: "carbonFootprint,DESC", label: (<span>CF&nbsp;&nbsp;<FontAwesomeIcon
            color="#f0f0f0"
            icon={faArrowDown}
            size="sm"
            style={{ paddingBottom: "1px" }}
        /></span>)
    }
];

let filterQueryString = "";
let isFilterApplied = false;
let sortQueryString = "";
let ctQueryString = "";

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });
    return isMountedRef;
}

const ctInitialState = {
    fuel: false,
    electricity: false,
    delivery: false,
    dataCentre: false,
    water: false,
    paper: false,
    plastic: false,
    metal: false,
   /*  transport: false, */
    electricalItems: false,
    aviation: false,
    seaFreight: false
}

const Projects = (props) => {

    const isMountedRef = useIsMountedRef();
    const [show, setShow] = useState(false);
    const [loaderShow, setLoaderShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastSession, setShowToastSession] = useState(false);
    const [totalProjectsLength, setTotalProjectsLength] = useState(0);
    const [currentProjects, setCurrentProjects] = useState([]);
    const [shouldBeRouted, setShouldBeRouted] = useState({ path: "", status: false });
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [sortBy, setSortBy] = useState(sortOptions[0]);
    const [cPage, setCPage] = useState(1);
    const [refreshPagination, setRefreshPagination] = useState(0);
    const [runFilter, setRunFilter] = useState(false);

    const ctReducer = (state, action) => {
        switch (action.type) {
            case "CT_FUEL":
                state = {
                    ...state,
                    fuel: action.payload
                }
                break;
            case "CT_ELECTRICITY":
                state = {
                    ...state,
                    electricity: action.payload
                }
                break;
            case "CT_DELIVERY":
                state = {
                    ...state,
                    delivery: action.payload
                }
                break;
            case "CT_DATACENTRE":
                state = {
                    ...state,
                    dataCentre: action.payload
                }
                break;
            case "CT_WATER":
                state = {
                    ...state,
                    water: action.payload
                }
                break;
            case "CT_PAPER":
                state = {
                    ...state,
                    paper: action.payload
                }
                break;
            case "CT_PLASTIC":
                state = {
                    ...state,
                    plastic: action.payload
                }
                break;
            case "CT_METAL":
                state = {
                    ...state,
                    metal: action.payload
                }
                break;
            /* case "CT_TRANSPORT":
                state = {
                    ...state,
                    transport: action.payload
                }
                break; */
            case "CT_ELECTRICAL_ITEMS":
                state = {
                    ...state,
                    electricalItems: action.payload
                }
                break;
            case "CT_AVIATION":
                state = {
                    ...state,
                    aviation: action.payload
                }
                break;
            case "CT_SEA_FREIGHT":
                state = {
                    ...state,
                    seaFreight: action.payload
                }
                break;
            default:
                break;
        }
        return state;
    }

    const [ctState, ctDistpatch] = useReducer(ctReducer, ctInitialState);

    let newBox = 0;
    let noBox = false;

    const getSortOptions = (inputValue, callback) => {
        callback(sortOptions);
    }

    const onSortOptionChange = (val) => {
        props.setSort(val.value);
    }

    const onPageChanged = data => {
        if (runFilter === true) {
            const { currentPage, pageLimit } = data;
            if (cPage !== currentPage) {
                setLoaderShow(true);
                setCPage(currentPage);
                const offset = (currentPage - 1) * pageLimit;
                getProjectData(pageLimit, offset)
                    .then(res => {
                        setCurrentProjects(res?.data?.value === undefined || res?.data?.value === null ? [] : res?.data?.value);
                        setLoaderShow(false);
                    })
                    .catch(err => {
                        setLoaderShow(false);
                    });
            }
        }
    }

    const routeTo = (path, projectId, primaryCountry, startDate, endDate) => {
        if (isMountedRef.current) {
            props.setCP({ projectId, primaryCountry, startDate, endDate });
            setShouldBeRouted({ path: path, status: true });
        }
    }

    const getProjectData = async (limit, offset, count = false) => {
        if (props.login.isValidUser) {
            if (count) {
                return axios.get(`${basePath}/project/count?${sortQueryString}${filterQueryString}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                });
            } else {
                return axios.get(`${basePath}/project?${sortQueryString}&limit=${limit}&offset=${offset}${filterQueryString}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                });
            }
        } else {
            return await { data: [] };
        }
    }
    //console.log(getProjectData,'getProjectData');

    const getAllData = () => {
        getProjectData(8, 0, true)
            .then(res => {
                setTotalProjectsLength(res?.data?.count === undefined || res?.data?.count === null ? 0 : res?.data?.count);
                setCurrentProjects(res?.data?.value === undefined || res?.data?.value === null ? [] : res?.data?.value.slice(0, 8));
                setLoaderShow(false);
                setRefreshPagination(prev => ++prev);
            })
            .catch(err => {
                setLoaderShow(false);
            });
    }
    // console.log(getAllData,'getAllData');

    const removeFilters = () => {
        setCPage(1);
        setLoaderShow(true);
        filterQueryString = "";
        isFilterApplied = !(filterQueryString === "");
        props.clearFilter();
        getAllData();
    }

    const applyFilter = () => {
        setCPage(1);
        newBox = 0;
        noBox = false;
        setLoaderShow(true);
        filterQueryString = "&";
        let isOtherFilterPresent = false;
        var temp = [];
        if (props.filter.projectId !== "") {
            filterQueryString += "q=" + props.filter.projectId;
        }
        if (props.filter.projectName !== "") {
            filterQueryString += "q=" + props.filter.projectName;
        }
        if (props.filter.country.length > 0) {
            temp = [];
            for (const item of props.filter.country) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "country=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.sector.length > 0) {
            temp = [];
            for (const item of props.filter.sector) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "se=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.salesStage.length > 0) {
            temp = [];
            for (const item of props.filter.salesStage) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "ss=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.deliveryLine.length > 0) {
            temp = [];
            for (const item of props.filter.deliveryLine) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "dl=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.typeOfEngagement.length > 0) {
            temp = [];
            for (const item of props.filter.typeOfEngagement) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "toe=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.activity.length > 0) {
            temp = [];
            for (const item of props.filter.activity) {
                temp.push(item.value);
            }
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "activity=" + temp.join();
            isOtherFilterPresent = true;
        }
        if (props.filter.testProject.value !== "" && undefined !== props.filter.testProject.value) {
            temp = '';
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "tp=" + props.filter.testProject.value;
            isOtherFilterPresent = true;
        }
        if (props.filter.flagCf.value !== "" && undefined !== props.filter.flagCf.value) {
            temp = '';
            if (filterQueryString !== "") {
                filterQueryString += "&";
            }
            filterQueryString += "fc=" + props.filter.flagCf.value;
            isOtherFilterPresent = true;
        }
        let isCTSelected = false;
        ctQueryString = "";
        if (props.filter.ctFuel) {
            ctQueryString += "ct=fuel";
            isCTSelected = true;
        }
        if (props.filter.ctElectricity) {
            if (ctQueryString === "") {
                ctQueryString += "ct=electricity";
            } else {
                ctQueryString += ",electricity";
            }
            isCTSelected = true;
        }
        if (props.filter.ctDelivery) {
            if (ctQueryString === "") {
                ctQueryString += "ct=people";
            } else {
                ctQueryString += ",people";
            }
            isCTSelected = true;
        }
        if (props.filter.ctDataCentre) {
            if (ctQueryString === "") {
                ctQueryString += "ct=dataCentre";
            } else {
                ctQueryString += ",dataCentre";
            }
            isCTSelected = true;
        }
        if (props.filter.ctWater) {
            if (ctQueryString === "") {
                ctQueryString += "ct=water";
            } else {
                ctQueryString += ",water";
            }
            isCTSelected = true;
        }
        if (props.filter.ctPaper) {
            if (ctQueryString === "") {
                ctQueryString += "ct=paper";
            } else {
                ctQueryString += ",paper";
            }
            isCTSelected = true;
        }
        if (props.filter.ctPlastic) {
            if (ctQueryString === "") {
                ctQueryString += "ct=plastic";
            } else {
                ctQueryString += ",plastic";
            }
            isCTSelected = true;
        }
        if (props.filter.ctMetal) {
            if (ctQueryString === "") {
                ctQueryString += "ct=metal";
            } else {
                ctQueryString += ",metal";
            }
            isCTSelected = true;
        }
       /*  if (props.filter.ctTransport) {
            if (ctQueryString === "") {
                ctQueryString += "ct=transport";
            } else {
                ctQueryString += ",transport";
            }
            isCTSelected = true;
        } */
        if (props.filter.ctElectricalItems) {
            if (ctQueryString === "") {
                ctQueryString += "ct=electricalItems";
            } else {
                ctQueryString += ",electricalItems";
            }
            isCTSelected = true;
        }
        if (props.filter.ctAviation) {
            if (ctQueryString === "") {
                ctQueryString += "ct=aviation";
            } else {
                ctQueryString += ",aviation";
            }
            isCTSelected = true;
        }
        if (props.filter.ctSeaFreight) {
            if (ctQueryString === "") {
                ctQueryString += "ct=seaFreight";
            } else {
                ctQueryString += ",seaFreight";
            }
            isCTSelected = true;
        }
        if (isCTSelected) {
            if (filterQueryString === "&") {
                filterQueryString += ctQueryString;
            } else if (filterQueryString !== "") {
                filterQueryString += "&" + ctQueryString;
            }
        }
        filterQueryString = filterQueryString === "&" ? "" : filterQueryString;
        isFilterApplied = isOtherFilterPresent;
        getAllData();
    }

    useEffect(() => {
        if (isMountedRef.current) {
            props.unsetCP();
            if (props.login.isValidUser === false || props.login.token === "") {
                if (show) {
                    setShow(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.login, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.login.isValidUser === true && props.login.token !== "") {
                sortQueryString = `sort=${props.sort.sortBy}`;
                applyFilter();
                for (const item of sortOptions) {
                    if (item.value === props.sort.sortBy) {
                        setSortBy(item);
                        break;
                    }
                }
            } else {
                if (show) {
                    setShow(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.sort.sortBy, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (shouldBeRouted.status === true) {
                props.history.push(shouldBeRouted.path);
            }
        }
        // eslint-disable-next-line
    }, [shouldBeRouted, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (runFilter && !show) {
                setShow(true);
                setLoaderShow(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProjects, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (runFilter) {
                if (!show) {
                    setShow(true);
                }
            }
        }
    }, [totalProjectsLength, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.login.sessionExpired) {
                props.clearSession();
                setShowToastSession(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.login.sessionExpired, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (runFilter) {
                applyFilter();
            }
        }
    }, [
        props.filter.salesStage,
        props.filter.sector,
        props.filter.deliveryLine,
        props.filter.typeOfEngagement,
        props.filter.country,
        props.filter.activity,
        props.filter.ctFuel,
        props.filter.ctElectricity,
        props.filter.ctDelivery,
        props.filter.ctDataCentre,
        props.filter.ctWater,
        props.filter.ctPaper,
        props.filter.ctPlastic,
        props.filter.ctMetal,
      /*   props.filter.ctTransport, */
        props.filter.ctElectricalItems,
        props.filter.ctAviation,
        props.filter.ctSeaFreight,
        props.filter.testProject,
        props.filter.flagCf,
        isMountedRef
    ]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (runFilter) {
                if (ctState?.fuel !== props.filter.ctFuel) {
                    props.setFilterCTFuel(ctState?.fuel);
                }
                if (ctState?.electricity !== props.filter.ctElectricity) {
                    props.setFilterCTElectricity(ctState?.electricity);
                }
                if (ctState?.delivery !== props.filter.ctDelivery) {
                    props.setFilterCTDelivery(ctState?.delivery);
                }
                if (ctState?.dataCentre !== props.filter.ctDataCentre) {
                    props.setFilterCTDataCentre(ctState?.dataCentre);
                }
                if (ctState?.water !== props.filter.ctWater) {
                    props.setFilterCTWater(ctState?.water);
                }
                if (ctState?.paper !== props.filter.ctPaper) {
                    props.setFilterCTPaper(ctState?.paper);
                }
                if (ctState?.plastic !== props.filter.ctPlastic) {
                    props.setFilterCTPlastic(ctState?.plastic);
                }
                if (ctState?.metal !== props.filter.ctMetal) {
                    props.setFilterCTMetal(ctState?.metal);
                }
              /*   if (ctState?.transport !== props.filter.ctTransport) {
                    props.setFilterCTTransport(ctState?.transport);
                } */
                if (ctState?.electricalItems !== props.filter.ctElectricalItems) {
                    props.setFilterCTElectricalItems(ctState?.electricalItems);
                }
                if (ctState?.aviation !== props.filter.ctAviation) {
                    props.setFilterCTAviation(ctState?.aviation);
                }
                if (ctState?.seaFreight !== props.filter.ctSeaFreight) {
                    props.setFilterCTSeaFreight(ctState?.seaFreight);
                }
            } else {
                setRunFilter(prev => !prev);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctState, isMountedRef]);

    const popover = (
        <Popover id="popover-basic" style={{ maxWidth: "520px", textAlign: "center", backgroundColor: "inherit", fontFamily: "inherit" }}>
            <Popover.Content style={{ color: "inherit", fontFamily: "inherit" }}>
                <FilterCustom />
            </Popover.Content>
        </Popover>
    )

    return (
        <>
            {
                props.login.isValidUser ? (
                    <React.Fragment>
                        <Container style={{ width: "90%" }}>
                            <Row style={{ textAlign: "left" }}>
                                <Col sm={12} md={12} lg={12} style={{ paddingLeft: "15px", marginBottom: "-12px" }}>
                                    <span style={{ fontSize: "52px", fontWeight: "bold", letterSpacing: "1.333px" }}>Client Carbon Impact</span>
                                </Col>
                                <Col sm={12} md={5} lg={4} style={{ paddingLeft: "15px" }}>
                                    <span style={{ fontSize: "52px", fontWeight: "normal", letterSpacing: "1.333px", fontFamily: "Ubuntu Mono" }}>Calculator</span>
                                </Col>
                                <Col sm={12} md={7} lg={8} style={{ paddingTop: "20px", paddingLeft: "0px", verticalAlign: "middle" }}>
                                    <span style={{ fontFamily: "Ubuntu Light", fontSize: "16px", verticalAlign: "middle", cursor: "pointer", padding: "8px 15px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "rgba(255, 255, 255, 0.87)" }} onClick={() => confirm("Please select :", "Client Carbon Impact", "Delivery Carbon Impact", () => props.history.push("/calculator"), () => props.history.push({ pathname: "/calculator", state: { calculatorType: "detailedDelivery" } }))} >
                                        <span>New&nbsp;&nbsp;</span><span style={{ fontFamily: "Ubuntu Light", fontSize: "24px" }}>+</span>
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{ margin: "30px 0px -6px 0px" }}>
                                <Col sm={12} md={7} lg={10} style={{ paddingLeft: "2px", textAlign: "left", paddingTop: "0px" }}>
                                    <div style={{ margin: "0px", display: "flex", flexWrap: "nowrap", marginTop: "-4px" }}>
                                        <div key={`filter-${"fuel"}`} style={{ padding: "0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Land Transport" cssClass="e-fuel" checked={props.filter.ctFuel} change={(val) => ctDistpatch({ type: "CT_FUEL", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"electricty"}`} style={{ paddingLeft:"0", margin: "0px 5px 0px 0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Electricity" cssClass="e-electricity" checked={props.filter.ctElectricity} change={(val) => ctDistpatch({ type: "CT_ELECTRICITY", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"delivery"}`} style={{ padding: "0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Delivery" cssClass="e-delivery" checked={props.filter.ctDelivery} change={(val) => ctDistpatch({ type: "CT_DELIVERY", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"water"}`} style={{ paddingLeft:"0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Water" cssClass="e-water" checked={props.filter.ctWater} change={(val) => ctDistpatch({ type: "CT_WATER", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"paper"}`} style={{ padding: "0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Paper" cssClass="e-paper" checked={props.filter.ctPaper} change={(val) => ctDistpatch({ type: "CT_PAPER", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"metal"}`} style={{ padding: "0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Metal" cssClass="e-metal" checked={props.filter.ctMetal} change={(val) => ctDistpatch({ type: "CT_METAL", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"plastic"}`} style={{ padding: "0px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Plastic" cssClass="e-plastic" checked={props.filter.ctPlastic} change={(val) => ctDistpatch({ type: "CT_PLASTIC", payload: val.checked })} />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={2} md={3} lg={2} style={{ textAlign: "right", paddingRight: "2px" }}>
                                    <div style={{ textAlign: "left", width: "130px", fontSize: "14px", marginLeft: "auto", marginRight: "0px" }}>
                                        <Select name={"landing-sort"} label={"Sorted By"} isSearchable={false} value={sortBy} onChange={(val) => onSortOptionChange(val)} loadOptions={getSortOptions} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2", paddingTop: "2px" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ minHeight: "25px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "20px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} singleValueFontSize={"14px"} valueContainer={{ position: "reset" }} dropdownIndicator={{ padding: "0px 8px", width: "28px" }} onClickColor="#525252" />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ margin: "30px 0px -6px 0px", marginTop: "-35px", width: "50%" }}>
                                <Col sm={12} md={7} lg={10} style={{ paddingLeft: "2px", textAlign: "left", paddingTop: "0px" }}>
                                    <div style={{ margin: "0px", display: "flex", flexWrap: "nowrap", marginTop: "-4px" }}>
                                        <div key={`filter-${"dataCentre"}`} style={{ padding: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Data&nbspCentre" cssClass="e-dataCentre" checked={props.filter.ctDataCentre} change={(val) => ctDistpatch({ type: "CT_DATACENTRE", payload: val.checked })} />
                                        </div>
                                       {/*  <div key={`filter-${"transport"}`} style={{ padding: "0px", flexGrow: 1, paddingLeft:"15px" }}>
                                            <CheckBoxComponent label="Transport" cssClass="e-transport" checked={props.filter.ctTransport} change={(val) => ctDistpatch({ type: "CT_TRANSPORT", payload: val.checked })} />
                                        </div> */}
                                        <div key={`filter-${"electricalItems"}`} style={{ padding: "0px", flexGrow: 1 , paddingLeft:"60px"}}>
                                            <CheckBoxComponent label="Electrical&nbspItems" cssClass="e-electricalItems" checked={props.filter.ctElectricalItems} change={(val) => ctDistpatch({ type: "CT_ELECTRICAL_ITEMS", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"aviation"}`} style={{ paddingLeft:"18px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Aviation" cssClass="e-aviation" checked={props.filter.ctAviation} change={(val) => ctDistpatch({ type: "CT_AVIATION", payload: val.checked })} />
                                        </div>
                                        <div key={`filter-${"seaFreight"}`} style={{ paddingLeft:"41px", margin: "0px", flexGrow: 1 }}>
                                            <CheckBoxComponent label="Sea&nbspFreight" cssClass="e-seaFreight" checked={props.filter.ctSeaFreight} change={(val) => ctDistpatch({ type: "CT_SEA_FREIGHT", payload: val.checked })} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "15px", paddingLeft: "2px", paddingRight: "2px", textAlign: "left", paddingBottom: "8px", fontSize: "14px", fontFamily: "Ubuntu" }}>
                                <Col sm={12} md={6} lg={6} style={{ paddingRight: "8px" }}>
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose onExit={() => setIsFilterVisible(false)} onEnter={() => setIsFilterVisible(true)}>
                                        <div className={"filterDiv"} style={{ color: isFilterApplied ? "#a0ad3b" : "#f0f0f0", backgroundColor: "#262626" }}>
                                            <div>
                                                <span>
                                                    {isFilterApplied ? "Filters Applied" : "Choose Filters"}
                                                </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                    {
                                                        isFilterVisible ? (
                                                            <FontAwesomeIcon
                                                                color={isFilterApplied ? "#a0ad3b" : "#f0f0f0"}
                                                                icon={faCaretUp}
                                                                size="1x"
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                color={isFilterApplied ? "#a0ad3b" : "#f0f0f0"}
                                                                icon={faCaretDown}
                                                                size="1x"
                                                            />
                                                        )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={12} md={4} lg={4} >
                                    <SearchInput id={`projectName`} name={`projectName`} value={props.filter.projectName} onChange={(e) => props.setFilterProjectName(e.target.value)} onSearchClick={() => applyFilter()} placeholder={"Search..."} autoComplete={"off"} label={"Project Name"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ flexGrow: 1, marginRight: "0px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ paddingRight: "0px", borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "38px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", lineHeight: "36px", color: "rgba(255, 255, 255, 0.87)" }} />
                                    {/* <SearchInput id={`projectId`} name={`projectId`} value={props.filter.projectId } onChange={(e) => props.setFilterProjectId(e.target.value)} onSearchClick={() => applyFilter()} placeholder={"Search..."} autoComplete={"off"} label={"Project Name"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ flexGrow: 1, marginRight: "0px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ paddingRight: "0px", borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "38px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", lineHeight: "36px", color: "rgba(255, 255, 255, 0.87)" }} /> */}
                                </Col>
                                <Col sm={12} md={1} lg={1} >
                                    <div className={"filterDiv"} onClick={() => applyFilter()} style={{ color: "rgba(255, 255, 255, 0.87)", width: "62px" }}>
                                        <div><strong> Apply </strong></div>
                                    </div>
                                </Col>
                                <Col sm={12} md={1} lg={1} style={{ paddingLeft: "8px" }}>
                                    <div className={"filterDiv"} onClick={() => removeFilters()} style={{ color: "rgb(235, 79, 107)", width: "62px" }}>
                                        <div><strong> Clear </strong></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{ position: "relative", pointerEvents: ((loaderShow) ? "none" : "all"), display: "flex", flexDirection: "column" }}>
                                    {
                                        show ? (
                                            <>
                                                <CardDeck style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", margin: 0 }}>
                                                    {
                                                        [
                                                            0, 1, 2, 3, 4, 5, 6, 7, 8
                                                        ].map((id) => {
                                                            let idNew = id;
                                                            if (currentProjects.length > idNew) {
                                                                return (<Card key={id} id style={{ cursor: (currentProjects.length > idNew) ? "pointer" : "auto", height: "348px", flex: "1 0 328px", border: 0, backgroundColor: "#262626" }} plus={false} text={`white`} data={(currentProjects.length > idNew) ? currentProjects[idNew] : null} routeTo="/calculator" onClick={(path, projectId, primaryCountry, startDate, endDate) => routeTo(path, projectId, primaryCountry, startDate, endDate)} />);
                                                            } else {
                                                                if (newBox === 0) {
                                                                    ++newBox;
                                                                    if ((id + 1) % 3 === 0) {
                                                                        noBox = true;
                                                                    }
                                                                    return (<Card key={id} id style={{ cursor: "pointer", height: "348px", flex: "1 0 328px", border: 0, backgroundColor: "#e7e6e8" }} plus={true} text={`dark`} data={null} routeTo="/calculator" onClick={() => confirm("Please select :", "Client Carbon Impact", "Delivery Carbon Impact", () => props.history.push("/calculator"), () => props.history.push({ pathname: "/calculator", state: { calculatorType: "detailedDelivery" } }))} />);
                                                                } else {
                                                                    if (noBox === false) {
                                                                        if ((id + 1) % 3 === 0) {
                                                                            noBox = true;
                                                                        }
                                                                        return (<Card key={id} id style={{ cursor: (currentProjects.length > idNew) ? "pointer" : "auto", height: "348px", flex: "1 0 328px", border: 0, backgroundColor: "transparent" }} plus={false} text={`white`} data={(currentProjects.length > idNew) ? currentProjects[idNew] : null} routeTo="/calculator" onClick={(path, projectId, primaryCountry, startDate, endDate) => routeTo(path, projectId, primaryCountry, startDate, endDate)} />);
                                                                    } else {
                                                                        return false;
                                                                    }

                                                                }
                                                            }
                                                        })

                                                    }
                                                </CardDeck>
                                                <Pagination refreshPagination={refreshPagination} cPage={cPage} totalRecords={totalProjectsLength} pageLimit={8} pageNeighbours={1} onPageChanged={onPageChanged} styles={{ width: (totalProjectsLength < 22) ? "150px" : totalProjectsLength < 36 ? "200px" : "300px", marginTop: "15px", alignSelf: "center" }} onClick={(val) => null} />
                                            </>
                                        ) : (<></>)
                                    }
                                    {
                                        loaderShow ? (
                                            <Spinner animation="border" role="status" style={{ zIndex: "100", top: "43.5%", position: "absolute", left: "48%" }}>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        ) : (<React.Fragment></React.Fragment>)
                                    }
                                </Col>
                            </Row>
                        </Container>
                        <ToastCustom variant="success" onClose={() => setShowToast(false)} show={showToast} delay={3000} header="Info" body="Data inserted!" autoHide={true} />
                        <ToastCustom variant="warning" onClose={() => setShowToastSession(false)} show={showToastSession} delay={3000} header="Info" body="Session Expired!" autoHide={true} />
                    </React.Fragment>
                ) : (<div>Loading...</div>)
            }
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        login: state.login,
        project: state.project,
        filter: state.filter,
        sort: state.sort
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSession: () => {
            dispatch(clearSession());
        },
        setCP: (data) => {
            dispatch(setCurrentProject({ projectId: data.projectId, primaryCountry: data.primaryCountry, startDate: data.startDate, endDate: data.endDate }));
        },
        unsetCP: () => {
            dispatch(unsetCurrentProject());
        },
        clearFilter: () => {
            dispatch(clearFilter());
        },
        setSort: (sortData) => {
            dispatch(setSort(sortData));
        },
        setFilterProjectName: (data) => {
            dispatch(setFilterProjectName(data));
        },
        setFilterProjectId: (data) => {
            dispatch(setFilterProjectId(data));
        },
        setFilterCTFuel: (data) => {
            dispatch(setFilterCTFuel(data));
        },
        setFilterCTElectricity: (data) => {
            dispatch(setFilterCTElectricity(data));
        },
        setFilterCTDelivery: (data) => {
            dispatch(setFilterCTDelivery(data));
        },
        setFilterCTDataCentre: (data) => {
            dispatch(setFilterCTDataCentre(data));
        },
        setFilterCTWater: (data) => {
            dispatch(setFilterCTWater(data));
        },
        setFilterCTPaper: (data) => {
            dispatch(setFilterCTPaper(data));
        },
        setFilterCTPlastic: (data) => {
            dispatch(setFilterCTPlastic(data));
        },
        setFilterCTMetal: (data) => {
            dispatch(setFilterCTMetal(data));
        },
        /* setFilterCTTransport: (data) => {
            dispatch(setFilterCTTransport(data));
        }, */
        setFilterCTElectricalItems: (data) => {
            dispatch(setFilterCTElectricalItems(data));
        },
        setFilterCTAviation: (data) => {
            dispatch(setFilterCTAviation(data));
        },
        setFilterCTSeaFreight: (data) => {
            dispatch(setFilterCTSeaFreight(data));
        },
    }
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects)));