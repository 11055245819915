
const initialState = {
    salesStage: [],
    sector: [],
    deliveryLine: [],
    typeOfEngagement: [],
    country: [],
    continent: [],
    activity: [],
    waterSourceType: [],
    dataCentreSourceType: [],
    electricitySourceType: [],
    peopleSourceType: [],
    fuelSourceType: [],
    fuelType: [],
    unitOfMeasurementType: [],
    unitOfMeasurement: [],
    countryElectricEfficiency: {},
    vehicleType: {},
    vehicleType1: {},
    fuelVehicle: {},
    fuelFactorMaster: {},
    fuelFactorVolume: {},
    electricityFactorMaster: {},
    peopleFactorMaster: {},
    waterFactorMaster: {},
    transportFactorMaster: {},
    wasteFactorMaster: {},
    foodFactorMaster: {},
    digitalFactorMaster: {},
    hotelFactorMaster: {},
    paperSourceType: [],
    paperMaterialUse: [],
    paperWasteDisposal: [],
    paperPostage: [],
    paperMaterialUseMaster: {},
    paperWasteDisposalMaster: {},
    paperPostageMaster: {},
    paperUnitMeasurment: [],
    plasticSourceType: [],
    plasticMaterialUse: [],
    plasticWasteDisposal: [],
    plasticMaterialUseMaster: {},
    plasticWasteDisposalMaster: {},
    plasticUnitMeasurment: [],
    metalSourceType: [],
    metalMaterialUse: [],
    metalWasteDisposal: [],
    metalMaterialUseMaster: {},
    metalWasteDisposalMaster: {},
    metalUnitMeasurement: [],
   /*  transportType: [],
    transportLiquidFactorMaster: {},
    transportUnitMeasurement: [], */
    electricalItemsSourceType: [],
    electricalItemsMaterialUse: [],
    electricalItemsWasteDisposal: [],
    electricalItemsMaterialUseMaster: {},
    electricalItemsWasteDisposalMaster: {},
    electricalItemsUnitMeasurement: [],
    aviationType: [],
    aviationDistanceType: [],
    aviationClassFactor: [],
    aviationClassFactorMaster: {},
    aviationFreightFactor: [],
    aviationFreightFactorMaster: {},
    seaFreightActivity: [],
    seaFreightType: [],
    seaFreightSeaTankerSize: [],
    seaFreightCargoShipSize: [],
    seaFreightSeaTankerSizeMaster: {},
    seaFreightCargoShipSizeMaster: {},
    serverType: {},
    status: false,
    testProjectOptions: [],
    flagCfOptions: [],
}

const masterReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_SALES_STAGE":
            state = {
                ...state,
                salesStage: action.payload.salesStage
            }
            break;
        case "SET_SECTOR":
            state = {
                ...state,
                sector: action.payload.sector
            }
            break;
        case "SET_DELIVERY_LINE":
            state = {
                ...state,
                deliveryLine: action.payload.deliveryLine
            }
            break;
        case "SET_TYPE_OF_ENGAGEMENT":
            state = {
                ...state,
                typeOfEngagement: action.payload.typeOfEngagement
            }
            break;
        case "SET_COUNTRY":
            state = {
                ...state,
                country: action.payload.country
            }
            break;
        case "SET_CONTINENT":
            state = {
                ...state,
                continent: action.payload.continent
            }
            break;
        case "SET_ACTIVITY":
            state = {
                ...state,
                activity: action.payload.activity
            }
            break;
        case "SET_WATER_SOURCE_TYPE":
            state = {
                ...state,
                waterSourceType: action.payload.waterSourceType
            }
            break;
        case "SET_DATA_CENTRE_SOURCE_TYPE":
            state = {
                ...state,
                dataCentreSourceType: action.payload.dataCentreSourceType
            }
            break;
        case "SET_ELECTRICITY_SOURCE_TYPE":
            state = {
                ...state,
                electricitySourceType: action.payload.electricitySourceType
            }
            break;
        case "SET_PEOPLE_SOURCE_TYPE":
            state = {
                ...state,
                peopleSourceType: action.payload.peopleSourceType
            }
            break;
        case "SET_FUEL_SOURCE_TYPE":
            state = {
                ...state,
                fuelSourceType: action.payload.fuelSourceType
            }
            break;
        case "SET_PAPER_SOURCE_TYPE":
            state = {
                ...state,
                paperSourceType: action.payload.paperSourceType
            }
            break;
        case "SET_PAPER_MATERIAL_USE":
            state = {
                ...state,
                paperMaterialUse: action.payload.paperMaterialUse
            }
            break;
        case "SET_PAPER_WASTE_DISPOSAL":
            state = {
                ...state,
                paperWasteDisposal: action.payload.paperWasteDisposal
            }
            break;
        case "SET_PAPER_POSTAGE":
            state = {
                ...state,
                paperPostage: action.payload.paperPostage
            }
            break;
        case "SET_PAPER_MATERIALUSE_MASTER":
            state = {
                ...state,
                paperMaterialUseMaster: action.payload.paperMaterialUseMaster
            }
            break;
        case "SET_PAPER_WASTEDISPOSAL_MASTER":
            state = {
                ...state,
                paperWasteDisposalMaster: action.payload.paperWasteDisposalMaster
            }
            break;
        case "SET_PAPER_POSTAGE_MASTER":
            state = {
                ...state,
                paperPostageMaster: action.payload.paperPostageMaster
            }
            break;
        case "SET_PAPER_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                paperUnitMeasurment: action.payload.paperUnitMeasurment
            }
            break;
        case "SET_PLASTIC_SOURCE_TYPE":
            state = {
                ...state,
                plasticSourceType: action.payload.plasticSourceType
            }
            break;
        case "SET_PLASTIC_MATERIAL_USE":
            state = {
                ...state,
                plasticMaterialUse: action.payload.plasticMaterialUse
            }
            break;
        case "SET_PLASTIC_WASTE_DISPOSAL":
            state = {
                ...state,
                plasticWasteDisposal: action.payload.plasticWasteDisposal
            }
            break;
        case "SET_PLASTIC_MATERIALUSE_MASTER":
            state = {
                ...state,
                plasticMaterialUseMaster: action.payload.plasticMaterialUseMaster
            }
            break;
        case "SET_PLASTIC_WASTEDISPOSAL_MASTER":
            state = {
                ...state,
                plasticWasteDisposalMaster: action.payload.plasticWasteDisposalMaster
            }
            break;
        case "SET_PLASTIC_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                plasticUnitMeasurment: action.payload.plasticUnitMeasurment
            }
            break;
        case "SET_METAL_SOURCE_TYPE":
            state = {
                ...state,
                metalSourceType: action.payload.metalSourceType
            }
            break;
        case "SET_METAL_MATERIAL_USE":
            state = {
                ...state,
                metalMaterialUse: action.payload.metalMaterialUse
            }
            break;
        case "SET_METAL_WASTE_DISPOSAL":
            state = {
                ...state,
                metalWasteDisposal: action.payload.metalWasteDisposal
            }
            break;
        case "SET_METAL_MATERIALUSE_MASTER":
            state = {
                ...state,
                metalMaterialUseMaster: action.payload.metalMaterialUseMaster
            }
            break;
        case "SET_METAL_WASTEDISPOSAL_MASTER":
            state = {
                ...state,
                metalWasteDisposalMaster: action.payload.metalWasteDisposalMaster
            }
            break;
        case "SET_METAL_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                metalUnitMeasurement: action.payload.metalUnitMeasurement
            }
            break;
       /*  case "SET_TRANSPORT_TYPE":
            state = {
                ...state,
                transportType: action.payload.transportType
            }
            break; */
        /* case "SET_TRANSPORT_LIQUID_FACTOR_MASTER":
            state = {
                ...state,
                transportLiquidFactorMaster: action.payload.transportLiquidFactorMaster
            }
            break;
        case "SET_TRANSPORT_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                transportUnitMeasurement: action.payload.transportUnitMeasurement
            }
            break; */
        case "SET_ELECTRICAL_ITEMS_SOURCE_TYPE":
            state = {
                ...state,
                electricalItemsSourceType: action.payload.electricalItemsSourceType
            }
            break;
        case "SET_ELECTRICAL_ITEMS_MATERIAL_USE":
            state = {
                ...state,
                electricalItemsMaterialUse: action.payload.electricalItemsMaterialUse
            }
            break;
        case "SET_ELECTRICAL_ITEMS_WASTE_DISPOSAL":
            state = {
                ...state,
                electricalItemsWasteDisposal: action.payload.electricalItemsWasteDisposal
            }
            break;
        case "SET_ELECTRICAL_ITEMS_MATERIALUSE_MASTER":
            state = {
                ...state,
                electricalItemsMaterialUseMaster: action.payload.electricalItemsMaterialUseMaster
            }
            break;
        case "SET_ELECTRICAL_ITEMS_WASTEDISPOSAL_MASTER":
            state = {
                ...state,
                electricalItemsWasteDisposalMaster: action.payload.electricalItemsWasteDisposalMaster
            }
            break;
        case "SET_ELECTRICAL_ITEMS_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                electricalItemsUnitMeasurement: action.payload.electricalItemsUnitMeasurement
            }
            break;
        case "SET_AVIATION_TYPE":
            state = {
                ...state,
                aviationType: action.payload.aviationType
            }
            break;
        case "SET_AVIATION_DISTANCE_TYPE":
            state = {
                ...state,
                aviationDistanceType: action.payload.aviationDistanceType
            }
            break;
        case "SET_AVIATION_CLASS_FACTOR":
            state = {
                ...state,
                aviationClassFactor: action.payload.aviationClassFactor
            }
            break;
        case "SET_AVIATION_CLASS_FACTOR_MASTER":
            state = {
                ...state,
                aviationClassFactorMaster: action.payload.aviationClassFactorMaster
            }
            break;
        case "SET_AVIATION_FREIGHT_FACTOR":
            state = {
                ...state,
                aviationFreightFactor: action.payload.aviationFreightFactor
            }
            break;
        case "SET_AVIATION_FREIGHT_FACTOR_MASTER":
            state = {
                ...state,
                aviationFreightFactorMaster: action.payload.aviationFreightFactorMaster
            }
            break;
        case "SET_SEA_FREIGHT_ACTIVITY":
            state = {
                ...state,
                seaFreightActivity: action.payload.seaFreightActivity
            }
            break;
        case "SET_SEA_FREIGHT_TYPE":
            state = {
                ...state,
                seaFreightType: action.payload.seaFreightType
            }
            break;
        case "SET_SEA_FREIGHT_SEA_TANKER_SIZE":
            state = {
                ...state,
                seaFreightSeaTankerSize: action.payload.seaFreightSeaTankerSize
            }
            break;
        case "SET_SEA_FREIGHT_SEA_TANKER_SIZE_MASTER":
            state = {
                ...state,
                seaFreightSeaTankerSizeMaster: action.payload.seaFreightSeaTankerSizeMaster
            }
            break;
        case "SET_SEA_FREIGHT_CARGO_SHIP_SIZE":
            state = {
                ...state,
                seaFreightCargoShipSize: action.payload.seaFreightCargoShipSize
            }
            break;
        case "SET_SEA_FREIGHT_CARGO_SHIP_SIZE_MASTER":
            state = {
                ...state,
                seaFreightCargoShipSizeMaster: action.payload.seaFreightCargoShipSizeMaster
            }
            break;
        case "SET_FUEL_TYPE":
            state = {
                ...state,
                fuelType: action.payload.fuelType
            }
            break;
        case "SET_UNIT_OF_MEASUREMENT_TYPE":
            state = {
                ...state,
                unitOfMeasurementType: action.payload.unitOfMeasurementType
            }
            break;
        case "SET_UNIT_OF_MEASUREMENT":
            state = {
                ...state,
                unitOfMeasurement: action.payload.unitOfMeasurement
            }
            break;
        case "SET_COUNTRY_ELECTRIC_EFFICIENCY":
            state = {
                ...state,
                countryElectricEfficiency: action.payload.countryElectricEfficiency
            }
            break;
        case "SET_VEHICLE_TYPE":
            state = {
                ...state,
                vehicleType: action.payload.vehicleType
            }
            break;
        case "SET_VEHICLE_TYPE1":
            state = {
                ...state,
                vehicleType1: action.payload.vehicleType1
            }
            break;
        case "SET_FUEL_VEHICLE":
            state = {
                ...state,
                fuelVehicle: action.payload.fuelVehicle
            }
            break;
        case "SET_FUEL_VEHICLE1":
            state = {
                ...state,
                fuelVehicle1: action.payload.fuelVehicle1
            }
            break;
        case "SET_FUEL_FACTOR_MASTER":
            state = {
                ...state,
                fuelFactorMaster: action.payload.fuelFactorMaster
            }
            break;
        case "SET_FUEL_FACTOR_VOLUME":
            state = {
                ...state,
                fuelFactorVolume: action.payload.fuelFactorVolume
            }
            break;
        case "SET_ELECTRICITY_FACTOR_MASTER":
            state = {
                ...state,
                electricityFactorMaster: action.payload.electricityFactorMaster
            }
            break;
        case "SET_PEOPLE_FACTOR_MASTER":
            state = {
                ...state,
                peopleFactorMaster: action.payload.peopleFactorMaster
            }
            break;
        case "SET_WATER_FACTOR_MASTER":
            state = {
                ...state,
                waterFactorMaster: action.payload.waterFactorMaster
            }
            break;
        case "SET_TRANSPORT_FACTOR_MASTER":
            state = {
                ...state,
                transportFactorMaster: action.payload.transportFactorMaster
            }
            break;
        case "SET_FOOD_FACTOR_MASTER":
            state = {
                ...state,
                foodFactorMaster: action.payload.foodFactorMaster
            }
            break;
        case "SET_WASTE_FACTOR_MASTER":
            state = {
                ...state,
                wasteFactorMaster: action.payload.wasteFactorMaster
            }
            break;
        case "SET_DIGITAL_FACTOR_MASTER":
            state = {
                ...state,
                digitalFactorMaster: action.payload.digitalFactorMaster
            }
            break;
        case "SET_HOTEL_FACTOR_MASTER":
            state = {
                ...state,
                hotelFactorMaster: action.payload.hotelFactorMaster
            }
            break;
        case "SET_SERVER_TYPE":
            state = {
                ...state,
                serverType: action.payload.serverType
            }
            break;
        case "SET_MASTER_STATUS":
            state = {
                ...state,
                status: action.payload.status
            }
            break;
        case "SET_TEST_PROJECT_OPTIONS":
            state = {
                ...state,
                testProjectOptions: action.payload.testProjectOptions
            }
            break;
        case "SET_FLAG_CF_OPTIONS":
            state = {
                ...state,
                flagCfOptions: action.payload.flagCfOptions
            }
            break;
        default:
            break;
    }
    return state;
}

export default masterReducer;