import React from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ProgressBarCustom from "../progressBar/ProgressBarCustom";
import ReactTooltip from "react-tooltip";
import "./CardCustom.scss";
import { projectCardTitleLimit } from "../../utilities/libjs/utilityjsfunctions";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-tooltip";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CardCustom = (props) => {
    const { id, style, type, plus, text, title, subTitle, data, routeTo, noMargin, isCalculatorTypeButton, calculatorType, clickable, message, tooltip, tooltipPosition, tooltipMessage, loaderShow } = props;
    let calculators = {};
    let estimatedCarbonSaved = 0;
    let estimatedDeliveryImpact = 0;

    //console.log(" data.calculatorData new");
    //console.log( data?.calculatorData);

   // Remove the Transport calculator
   var calculatorData = data?.calculatorData.filter(item => item.name !== 'transport');
    if (data !== undefined && data !== null) {
        for (const cData of calculatorData) {
            if (Math.abs(cData.totalCarbonFootprint) > 0) {
                if (cData.name === "people" || cData.name === "detailedDelivery") {
                    estimatedDeliveryImpact += cData.totalCarbonFootprint;
                    // if(cData.name === "detailedDelivery") {
                    calculators = {
                        ...calculators,
                        [cData.name]: { cf: cData.totalCarbonFootprint }
                    }
                } else {
                    estimatedCarbonSaved += cData.totalCarbonFootprint;
                    calculators = {
                        ...calculators,
                        [cData.name]: { cf: cData.totalCarbonFootprint }
                    }
                }

            }
        }
    }

    return (
        <>
            {
                plus === true ? (
                    <Card
                        key={id}
                        text={text}
                        style={style}
                        className={noMargin ? "newCard" : "m-2 newCard"}
                        onClick={(typeof props.onClick === "function" && props.onClick !== undefined) ? () => props.onClick(routeTo) : null}
                    >
                        <Card.Body style={{ padding: 0, margin: "16px", textAlign: "left", color: "rgba(0, 0, 0, 0.87)" }}>
                            <Card.Title>
                                <Card.Text style={{ fontFamily: "Ubuntu Mono", letterSpacing: "0.1px", fontSize: "12px", marginBottom: "5px", lineHeight: "16px" }}>
                                    <span>New Calculator</span>
                                </Card.Text>
                                <Card.Text style={{ marginTop: "35px", marginBottom: "34px" }}>
                                    <span style={{ margin: "1px", padding: "17px 34px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}></span>
                                    <span style={{ margin: "1px", padding: "17px 34px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}></span>
                                    <span style={{ margin: "1px", padding: "17px 34px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}></span>
                                </Card.Text>
                                <Card.Text>
                                    <span style={{ margin: "1px", padding: "17px 34px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}></span>
                                    <span style={{ margin: "1px", padding: "17px 34px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}></span>
                                </Card.Text>
                            </Card.Title>
                            <div style={{ bottom: "8px", position: "absolute", width: "100%" }}>
                                <Row>
                                    <Col sm={6} md={6} lg={6} style={{ textAlign: "left" }} className={`cardIcon${calculatorType}`.split(" ").join("")}>
                                    </Col>
                                    <Col sm={6} md={6} lg={6} style={{ textAlign: "right", paddingRight: "38px" }}>
                                        <span style={{ fontSize: "52px", paddingRight: "15px", fontFamily: "Ubuntu Light" }}>+</span>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                ) : (
                    <>
                        {
                            isCalculatorTypeButton ? (
                                <React.Fragment key={`${id}-card-fragment`}>
                                    <Card
                                        key={id}
                                        text={text}
                                        style={style}
                                        className={noMargin ? (clickable ? "calculatorCard" : "noCalculatorCard") : (clickable ? "m-2 calculatorCard" : "noCalculatorCard")}
                                        onClick={(clickable === true && typeof props.onClick === "function" && props.onClick !== undefined) ? () => props.onClick(routeTo) : null}
                                        data-tip={tooltipMessage !== undefined && typeof tooltipMessage === "string" ? tooltipMessage : ""} data-for={`${id}-tooltip`}
                                    >
                                        <Card.Body style={{ padding: 0, margin: "14px 12px 14px 12px", textAlign: "left" }}>
                                            <Card.Title>
                                                <Card.Text style={{ fontSize: "13px", fontWeight: "normal", marginBottom: "5px", color: "#f0f0f0" }}>
                                                    <span style={{ color: clickable ? "#f0f0f0" : "rgba(255, 255, 255, 0.5)" }}>{calculatorType}</span>
                                                   { calculatorType == "Land Transport"  ?
                                                   ( 
                                                  <p style={{ color: clickable ? "#f0f0f0" : "rgba(255, 255, 255, 0.5)" }}><sub>Fuel is renamed to <i>Land Transport</i></sub></p>
                                                )
                                                :(<></>)} 
                                                    { 
                                                        message !== undefined && typeof message === "string" ? (
                                                            <span style={{ fontSize: "10px", color: "rgba(255, 255, 255, 0.87)", float: "right", paddingTop: "2px" }}>{message}</span>
                                                        ) : (<></>)
                                                    }
                                                </Card.Text>
                                            </Card.Title>
                                            <div style={{ bottom: "8px", position: "absolute", width: "100%" }}>
                                                <Row>
                                                    <Col sm={6} md={6} lg={6} style={{ textAlign: "left" }} className={`cardIcon${calculatorType}`.split(" ").join("")}>
                                                        {
                                                            calculatorType === "Land Transport" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M11.1012 30C9.21881 29.9968 7.41448 29.248 6.08345 27.9176C4.75242 26.5873 4.00323 24.7838 4 22.9024C4 21.2805 5.00051 18.8049 6.96492 15.5854C9.91764 10.7073 10.6253 10.7073 11.1012 10.7073C11.577 10.7073 12.3213 10.7073 15.2374 15.5854C17.2018 18.8537 18.2023 21.3293 18.2023 22.9024C18.1991 24.7838 17.4499 26.5873 16.1189 27.9176C14.7879 29.248 12.9835 29.9968 11.1012 30ZM23.5221 13.7927C22.3345 13.7927 21.1955 13.3211 20.3558 12.4818C19.516 11.6425 19.0442 10.5041 19.0442 9.31707C19.0442 7.82927 21.9603 2 23.5221 2C25.0839 2 28 7.85366 28 9.31707C27.9904 10.4989 27.5147 11.6291 26.6763 12.4625C25.838 13.2959 24.7045 13.7651 23.5221 13.7683V13.7927Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Electricity" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M14.3529 30V18.6417C14.3529 18.4803 14.291 18.3255 14.1807 18.2113C14.0703 18.0972 13.9207 18.033 13.7647 18.033H6L17.6471 2V13.3583C17.6471 13.5197 17.709 13.6745 17.8193 13.7887C17.9297 13.9028 18.0793 13.967 18.2353 13.967H26L14.3529 30Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Simple Delivery Impact" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M27.7229 4.29001C27.5878 4.15569 27.4171 4.06268 27.2308 4.02194C27.0446 3.9812 26.8506 3.99441 26.6717 4.06001L4.6461 12.06C4.45615 12.132 4.29261 12.26 4.17721 12.427C4.06181 12.594 4 12.7921 4 12.995C4 13.1979 4.06181 13.3961 4.17721 13.5631C4.29261 13.7301 4.45615 13.858 4.6461 13.93L14.2573 17.77L18.1017 27.37C18.1739 27.5514 18.2978 27.7077 18.4579 27.8197C18.618 27.9316 18.8074 27.9943 19.0028 28C19.2051 27.9959 19.4014 27.9306 19.5658 27.8128C19.7302 27.6949 19.8551 27.5301 19.9238 27.34L27.9331 5.34001C28.0013 5.16309 28.0178 4.97044 27.9806 4.78454C27.9433 4.59863 27.854 4.42712 27.7229 4.29001ZM19.0028 24.2L16.2095 17.2L21.0051 12.41L19.5934 11L14.7578 15.83L7.78975 13L25.3401 6.67001L19.0028 24.2Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Data Centre" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M16.0005 30L4.00049 22.8309L4.00049 9.16908L16.0005 2L28.0005 9.16908V22.7633L16.0005 30ZM16.6672 16.744V28.0386L26.0005 22.4251L23.6672 21.0048L24.3338 19.8551L26.6672 21.2754V10.7246L16.6672 16.744ZM6.00049 22.4251L15.3338 28.0386L15.3338 16.744L5.33382 10.7923L5.33382 21.2754L7.73382 19.8551L8.40049 21.0048L6.00049 22.4251ZM6.00049 9.57488L16.0005 15.5266L26.0005 9.57488L16.6672 3.96135V6.86957H15.3338V3.96135L6.00049 9.57488ZM21.6672 19.8551L19.2506 18.3658L19.9173 17.2161L22.3338 18.7053L21.6672 19.8551ZM10.4005 19.7874L9.73382 18.6377L12.0814 17.2036L12.748 18.3534L10.4005 19.7874ZM16.6672 11.9565H15.3338V8.89855H16.6672V11.9565Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M15.9492 30.0858L16.0007 30.1166L16.0521 30.0856L28.0521 22.8489L28.1005 22.8198V22.7633V9.16908V9.11234L28.0518 9.08324L16.0518 1.91415L16.0005 1.88351L15.9492 1.91415L3.9492 9.08324L3.90049 9.11234V9.16908L3.90049 22.8309V22.8877L3.9492 22.9168L15.9492 30.0858ZM26.5672 10.9015V21.0974L24.3858 19.7697L24.2986 19.7165L24.2473 19.8049L23.5806 20.9547L23.5315 21.0394L23.6152 21.0903L25.8073 22.4246L16.7672 27.8618V16.8005L26.5672 10.9015ZM5.43382 10.9682L15.2338 16.8008L15.2338 27.8618L6.19567 22.4258L8.45142 21.0909L8.53671 21.0404L8.487 20.9547L7.82033 19.8049L7.76972 19.7176L7.68289 19.769L5.43382 21.1L5.43382 10.9682ZM16.6672 6.96957H16.7672V6.86957V4.13819L25.8057 9.57443L16.0005 15.4102L6.19526 9.57443L15.2338 4.13819V6.86957V6.96957H15.3338H16.6672ZM21.6147 19.9402L21.7021 19.9941L21.7537 19.9052L22.4203 18.7555L22.4692 18.6713L22.3863 18.6202L19.9697 17.1309L19.8823 17.0771L19.8307 17.1659L19.1641 18.3157L19.1152 18.3999L19.1981 18.451L21.6147 19.9402ZM10.314 19.8376L10.3653 19.9261L10.4526 19.8728L12.8002 18.4387L12.8836 18.3878L12.8346 18.3032L12.1679 17.1535L12.1166 17.0649L12.0292 17.1183L9.68169 18.5523L9.59829 18.6033L9.64731 18.6878L10.314 19.8376ZM16.6672 12.0565H16.7672V11.9565V8.89855V8.79855H16.6672H15.3338H15.2338V8.89855V11.9565V12.0565H15.3338H16.6672Z" stroke="white" strokeOpacity="0.87" strokeWidth="0.2" />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Paper" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M3.96283 8.28874L15.9999 1.16212L28.0371 8.28874L15.9999 15.4157L3.96283 8.28874Z" stroke="white" strokeOpacity="0.2" strokeWidth="2" />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M3.9132 12L2 13.1327L3.96281 14.2949L15.9999 21.4218L28.0372 14.2949L30 13.1327L28.0868 12L15.9999 19.1563L3.9132 12Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M3.9132 17L2 18.1327L3.96281 19.2949L15.9999 26.4218L28.0372 19.2949L30 18.1327L28.0868 17L15.9999 24.1563L3.9132 17Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M3.9132 22L2 23.1327L3.96281 24.2949L15.9999 31.4218L28.0372 24.2949L30 23.1327L28.0868 22L15.9999 29.1563L3.9132 22Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Plastic" ? (
                                                                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M200 0C213.3 0 224 10.75 224 24V64H96V24C96 10.75 106.7 0 120 0H200zM32 151.7C32 136.1 41.04 121.9 55.19 115.3L79.6 103.8C90.58 98.67 102.6 96 114.7 96H205.3C217.4 96 229.4 98.67 240.4 103.8L264.8 115.3C278.1 121.9 288 136.1 288 151.7C288 166.1 280.5 178.7 269.1 185.8C280.6 194.6 288 208.4 288 223.1C288 240.7 279.5 255.4 266.5 263.1C279.5 272.6 288 287.3 288 303.1C288 320.7 279.5 335.4 266.5 344C279.5 352.6 288 367.3 288 384C288 400.7 279.5 415.4 266.5 424C279.5 432.6 288 447.3 288 464C288 490.5 266.5 512 240 512H80C53.49 512 32 490.5 32 464C32 447.3 40.52 432.6 53.46 424C40.52 415.4 32 400.7 32 384C32 367.3 40.52 352.6 53.46 344C40.52 335.4 32 320.7 32 303.1C32 287.3 40.52 272.6 53.46 263.1C40.52 255.4 32 240.7 32 223.1C32 208.4 39.4 194.6 50.87 185.8C39.53 178.7 32 166.1 32 151.7L32 151.7zM112 256H208C216.8 256 224 248.8 224 240C224 231.2 216.8 224 208 224H112C103.2 224 96 231.2 96 240C96 248.8 103.2 256 112 256zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H208C216.8 384 224 376.8 224 368C224 359.2 216.8 352 208 352H112z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Metal" ? (
                                                                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M369.96,101.6l-18.314-61.048C356.17,36.266,359,30.209,359,23.5C359,10.542,348.458,0,335.5,0h-208 C114.542,0,104,10.542,104,23.5c0,6.709,2.83,12.766,7.354,17.051L93.04,101.6C89.695,112.746,88,124.299,88,135.938v287.856 c0,1.873,0.246,3.742,0.73,5.555l4.495,16.78c2.661,9.933,12.023,16.87,22.767,16.87h231.016c10.743,0,20.105-6.938,22.768-16.871 l4.491-16.77c0.486-1.814,0.733-3.689,0.733-5.571v-287.85C375,124.299,373.305,112.746,369.96,101.6z M127.5,15h208 c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5h-208c-4.687,0-8.5-3.813-8.5-8.5S122.813,15,127.5,15z M107.407,105.91 l17.709-59.031C125.9,46.959,126.695,47,127.5,47h208c0.805,0,1.6-0.041,2.384-0.12l17.709,59.031 c2.925,9.747,4.407,19.85,4.407,30.028V416H103v-43.518c8.024,1.671,16.234,2.518,24.5,2.518c65.893,0,119.5-53.607,119.5-119.5 S193.393,136,127.5,136c-8.269,0-16.476,0.852-24.5,2.523v-2.585C103,125.76,104.482,115.657,107.407,105.91z M103,187.251 c7.833-2.816,16.054-4.251,24.5-4.251c39.977,0,72.5,32.523,72.5,72.5S167.477,328,127.5,328c-8.441,0-16.665-1.427-24.5-4.242 V187.251z M103,339.504c7.918,2.31,16.12,3.496,24.5,3.496c48.248,0,87.5-39.252,87.5-87.5S175.748,168,127.5,168 c-8.383,0-16.584,1.19-24.5,3.499v-17.6c7.985-1.917,16.204-2.899,24.5-2.899c57.621,0,104.5,46.879,104.5,104.5 S185.121,360,127.5,360c-8.293,0-16.515-0.975-24.5-2.891V339.504z M347.008,448H115.992c-3.967,0-7.37-2.365-8.277-5.752 L104.702,431h253.597l-3.013,11.247C354.378,445.635,350.975,448,347.008,448z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                       {/*  {
                                                            calculatorType === "Transport" ? (
                                                                <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M3,2H6C6.28,2 6.53,2.11 6.71,2.29L8.79,4.38L9.59,3.59C10,3.2 10.5,3 11,3H17C17.5,3 18,3.2 18.41,3.59L19.41,4.59C19.8,5 20,5.5 20,6V19A2,2 0 0,1 18,21H8A2,2 0 0,1 6,19V13L6,12V8C6,7.5 6.2,7 6.59,6.59L7.38,5.79L5.59,4H3V2M11,5V7H17V5H11M11.41,11L9.41,9H8V10.41L10,12.41V15.59L8,17.59V19H9.41L11.41,17H14.59L16.59,19H18V17.59L16,15.59V12.41L18,10.41V9H16.59L14.59,11H11.41M12,13H14V15H12V13Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        } */}
                                                        {
                                                            calculatorType === "Electrical Items" ? (
                                                                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M439.124,81.16h-68.511V59.424c0-3.946-3.199-7.146-7.146-7.146h-48.999c-3.946,0-7.146,3.199-7.146,7.146V81.16H138.947 V59.424c0-3.946-3.199-7.146-7.146-7.146H82.802c-3.946,0-7.146,3.199-7.146,7.146V81.16H7.146C3.2,81.16,0,84.359,0,88.306v45.614c0,3.946,3.199,7.146,7.146,7.146h23.201h385.577h23.201c3.945,0,7.146-3.199,7.146-7.146V88.306C446.27,84.36,443.07,81.16,439.124,81.16z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M30.346,384.723c0,5.119,4.149,9.27,9.268,9.27h367.041c5.119,0,9.269-4.15,9.269-9.27V165.404H30.346V384.723z M179.262,290.014l21.716-69.401c0.373-1.192,1.478-2.004,2.727-2.004h33.864c0.914,0,1.771,0.438,2.311,1.177 c0.538,0.738,0.689,1.691,0.406,2.561l-13.221,40.839h37.217c1.047,0,2.008,0.572,2.51,1.49c0.5,0.918,0.459,2.035-0.107,2.914 l-49.719,77.149c-0.538,0.835-1.453,1.31-2.402,1.31c-0.343,0-0.689-0.062-1.025-0.19c-1.263-0.485-2.012-1.793-1.793-3.129 l8.028-49.005H181.99c-0.909,0-1.764-0.434-2.302-1.166C179.148,291.826,178.99,290.881,179.262,290.014z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Aviation" ? (
                                                                <svg width="32" height="32" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0" fill="none" width="20" height="20" />
                                                                    <g>
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M17.6 2.4c-.8-.8-2.1-.1-2.7.5l-3.5 3.8-2.4-1 .6-.6c.2-.2.2-.5 0-.7-.1-.2-.4-.1-.6 0l-.9.9-1.8-.8.5-.5c.2-.2.2-.5 0-.6-.2-.2-.5-.2-.7 0l-.8.8-.5-.2c-.8-.4-1.7-.3-2.3.4l5.8 5.8L6 12.6l-3.5-.2-.5.7 3.1 1.8L6.9 18l.7-.5-.2-3.5 2.5-2.3 5.8 5.8c.6-.6.8-1.6.4-2.3l-.2-.5.8-.8c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-.5.5-.8-1.9.9-.9c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.6 0l-.6.6-1-2.4L17.2 5c.6-.5 1.2-1.9.4-2.6z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    </g>
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Sea Freight" ? (
                                                                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                                    <g>
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M206.112,258.427c-33.724-9.37-26.227-56.198-26.227-56.198S17.18,156.958,14.772,156.272c-11.904-3.174-15.446,8.148-14.67,14.337c19.075,152.213,114.212,228.323,114.212,228.323h369.058C509.502,377.134,512,312.18,512,312.18S239.832,267.797,206.112,258.427z M93.587,271.056c-7.636,0-13.83-6.19-13.83-13.83c0-7.64,6.193-13.829,13.83-13.829c7.64,0,13.834,6.189,13.834,13.829C107.42,264.866,101.227,271.056,93.587,271.056z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <polygon className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" points="338.049,135.059 235.419,113.067 207.245,133.415 207.245,151.848 338.049,179.886 	" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <polygon className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" points="454.544,160.024 350.313,137.689 350.313,182.509 454.544,204.844 	" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <polygon className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" points="372.112,199.955 272.191,178.535 272.191,209.633 372.112,228.087 	" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M272.191,258.827c29.318,5.296,65.028,11.464,99.921,17.389v-32.884l-99.921-18.462V258.827z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <polygon className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" points="482.025,223.505 387.1,203.157 387.1,230.853 482.025,248.385 	" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M387.1,278.753c36.63,6.196,71.158,11.921,94.925,15.846v-30.976L387.1,246.097V278.753z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M209.453,246.391c6.915,1.923,24.426,5.41,47.754,9.705v-33.992l-65.479-12.093 C191.296,220.439,192.564,241.695,209.453,246.391z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} fillRule="evenodd" clipRule="evenodd" d="M193.976,193.187l-0.307,1.937l63.538,11.736v-31.534L132.306,148.56l-27.445,19.819 c39.428,10.978,77.582,21.599,78.369,21.813L193.976,193.187z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    </g>
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Water" ? (
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M8.80001 26.652C9.49601 25.852 9.77601 25.6 10.4 25.6C11.024 25.6 11.3 25.856 12 26.652C12.7 27.448 13.876 28.8 16 28.8C18.124 28.8 19.256 27.508 20 26.652C20.744 25.796 20.98 25.6 21.6 25.6C22.22 25.6 22.5 25.856 23.2 26.652C23.9 27.448 25.076 28.8 27.2 28.8H28.8V25.6H27.2C26.58 25.6 26.3 25.344 25.6 24.548C24.9 23.752 23.728 22.4 21.6 22.4C19.472 22.4 18.344 23.692 17.6 24.548C16.856 25.404 16.624 25.6 16 25.6C15.376 25.6 15.1 25.344 14.4 24.548C13.7 23.752 12.528 22.4 10.4 22.4C8.27201 22.4 7.14401 23.692 6.40001 24.548C5.65601 25.404 5.42401 25.6 4.80001 25.6H3.20001V28.8H4.80001C6.92801 28.8 8.05601 27.508 8.80001 26.652Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M8.80001 17.052C9.49601 16.252 9.77601 16 10.4 16C11.024 16 11.3 16.256 12 17.052C12.7 17.848 13.876 19.2 16 19.2C18.124 19.2 19.256 17.908 20 17.052C20.744 16.196 20.98 16 21.6 16C22.22 16 22.5 16.256 23.2 17.052C23.9 17.848 25.076 19.2 27.2 19.2H28.8V16H27.2C26.58 16 26.3 15.744 25.6 14.948C24.9 14.152 23.728 12.8 21.6 12.8C19.472 12.8 18.344 14.092 17.6 14.948C16.856 15.804 16.624 16 16 16C15.376 16 15.1 15.744 14.4 14.948C13.7 14.152 12.528 12.8 10.4 12.8C8.27201 12.8 7.14401 14.092 6.40001 14.948C5.65601 15.804 5.42401 16 4.80001 16H3.20001V19.2H4.80001C6.92801 19.2 8.05601 17.908 8.80001 17.052Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M8.80001 7.45201C9.49601 6.65201 9.77601 6.40001 10.4 6.40001C11.024 6.40001 11.3 6.65601 12 7.45201C12.7 8.24801 13.876 9.60001 16 9.60001C18.124 9.60001 19.256 8.30801 20 7.45201C20.744 6.59601 20.98 6.40001 21.6 6.40001C22.22 6.40001 22.5 6.65601 23.2 7.45201C23.9 8.24801 25.076 9.60001 27.2 9.60001H28.8V6.40001H27.2C26.58 6.40001 26.3 6.14401 25.6 5.34801C24.9 4.55201 23.728 3.20001 21.6 3.20001C19.472 3.20001 18.344 4.49201 17.6 5.34801C16.856 6.20401 16.624 6.40001 16 6.40001C15.376 6.40001 15.1 6.14401 14.4 5.34801C13.7 4.55201 12.528 3.20001 10.4 3.20001C8.27201 3.20001 7.14401 4.49201 6.40001 5.34801C5.65601 6.20401 5.42401 6.40001 4.80001 6.40001H3.20001V9.60001H4.80001C6.92801 9.60001 8.05601 8.30801 8.80001 7.45201Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                </svg>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            calculatorType === "Detailed Project Impact" && (
                                                                <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0)">
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M6.33268 19C8.08158 19 9.49934 17.5823 9.49934 15.8334C9.49934 14.0845 8.08158 12.6667 6.33268 12.6667C4.58378 12.6667 3.16602 14.0845 3.16602 15.8334C3.16602 17.5823 4.58378 19 6.33268 19Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M10.5608 21.2166C8.96167 22.42 7.91667 24.3516 7.91667 26.5208V26.9166H1.1875C0.538333 26.9166 0 26.3783 0 25.7291V24.9375C0 22.5308 1.9475 20.5833 4.35417 20.5833H8.3125C9.13583 20.5833 9.91167 20.8208 10.5608 21.2166Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M31.6667 19C33.4156 19 34.8333 17.5823 34.8333 15.8334C34.8333 14.0845 33.4156 12.6667 31.6667 12.6667C29.9178 12.6667 28.5 14.0845 28.5 15.8334C28.5 17.5823 29.9178 19 31.6667 19Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M37.9993 24.9375V25.7291C37.9993 26.3783 37.461 26.9166 36.8118 26.9166H30.0826V26.5208C30.0826 24.3516 29.0376 22.42 27.4385 21.2166C28.0876 20.8208 28.8635 20.5833 29.6868 20.5833H33.6451C36.0518 20.5833 37.9993 22.5308 37.9993 24.9375Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M19 19.7917C21.6234 19.7917 23.75 17.665 23.75 15.0417C23.75 12.4183 21.6234 10.2917 19 10.2917C16.3766 10.2917 14.25 12.4183 14.25 15.0417C14.25 17.665 16.3766 19.7917 19 19.7917Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                        <path className={`img${calculatorType.toString().split(" ").join("")}`} d="M23.3535 22.1667H14.6452C12.2433 22.1667 10.291 24.1189 10.291 26.5208V28.8958C10.291 29.5513 10.823 30.0833 11.4785 30.0833H26.5202C27.1757 30.0833 27.7077 29.5513 27.7077 28.8958V26.5208C27.7077 24.1189 25.7554 22.1667 23.3535 22.1667Z" fill="white" fillOpacity={!clickable ? "0.2" : "0.87"} />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0">
                                                                            <rect width="38" height="32" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            )
                                                        }
                                                    </Col>
                                                    <Col sm={6} md={6} lg={6} style={{ textAlign: "right", paddingRight: "38px", paddingTop: "7px" }}>
                                                        <FontAwesomeIcon
                                                            color={clickable ? "#f0f0f0" : "rgba(255, 255, 255, 0.5)"}
                                                            icon={faPlus}
                                                            size="1x"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    {
                                        tooltip === true && tooltipMessage !== undefined && typeof tooltipMessage === "string" && (
                                            <ReactTooltip id={`${id}-tooltip`} className='customCardTooltip' place={tooltipPosition} offset={{ top: 20 }} arrowColor="#000000" multiline={true} />
                                        )
                                    }
                                </React.Fragment>
                            ) : (
                                <>
                                    {

                                        data !== null ? (
                                            <Card
                                                key={id}
                                                // text={text}
                                                style={style}
                                                className={noMargin ? "dataCard" : "m-2 dataCard"}
                                                onClick={(typeof props.onClick === "function" && props.onClick !== undefined) ? () => props.onClick(routeTo, data.id, data.country.name, data.startDate, data.endDate) : null}
                                            >
                                                <Card.Body style={{ padding: 0, margin: "16px", textAlign: "left", color: "rgba(255, 255, 255, 0.87)" }}>
                                                    <Card.Title>
                                                        <Card.Text style={{ fontSize: "12px", marginBottom: "5px", lineHeight: "16px", letterSpacing: "0.4px" }} >
                                                            <span>{data.country.name}&nbsp;&nbsp;&nbsp;{data.deliveryLine.name}&nbsp;&nbsp;&nbsp;{data.sector.name}
                                                                <span style={{ float: 'right' }}> CCIC- {data.id} </span> <br />
                                                                {data.e3DId !== null ?
                                                                    <>
                                                                        <span data-tip={`E3D-ID: ${data.e3DId}`} data-for='e3did' style={{ float: 'right', paddingLeft: "300px", paddingTop: "3px", color: '#FFFF00' }}> {data.e3DId} </span> <br />
                                                                        <ReactTooltip id="e3did" className='customTooltip' place="top" />
                                                                    </>
                                                                    : null}
                                                                <span style={{ float: 'right', paddingTop: "5px", color: 'rgb(235, 79, 107)' }}>
                                                                    {data.calculatorData[0].totalCarbonFootprint + calculatorData[1].totalCarbonFootprint + calculatorData[2].totalCarbonFootprint + calculatorData[3].totalCarbonFootprint + calculatorData[6].totalCarbonFootprint + calculatorData[7].totalCarbonFootprint > 50000 && (data.testProject === 'no') ?
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                color={clickable ? "red" : "red"}
                                                                                icon={faFlag}
                                                                                size="2x"
                                                                            />
                                                                        </> : (data.testProject === 'yes') ? 'Test Project' : ""}
                                                                </span>
                                                            </span>
                                                        </Card.Text>
                                                        {data.e3DId !== null ?
                                                            <Col sm={12} md={12} lg={12} style={{ paddingLeft: "0px" }}>

                                                                <Card.Text style={{ fontSize: "24px", marginBottom: "5px", fontWeight: "500", lineHeight: "28px", letterSpacing: "0.216px" }}>
                                                                    {projectCardTitleLimit(data.name)}
                                                                </Card.Text>

                                                            </Col>
                                                            :
                                                            <Card.Text style={{ fontSize: "24px", marginTop: "15px", marginBottom: "5px", fontWeight: "500", lineHeight: "28px", letterSpacing: "0.216px" }}>
                                                                {projectCardTitleLimit(data.name)}
                                                            </Card.Text>

                                                        }

                                                        {data.typeOfEngagement?.name &&
                                                            <Card.Text style={{ fontSize: "14px", marginTop: "20px" }}>
                                                                <span style={{ border: "1px solid rgba(255, 255, 255, 0.87)", padding: "8px 10px" }}>{data.typeOfEngagement.name}</span>
                                                            </Card.Text>
                                                        }
                                                        <div style={{ bottom: "120px", position: "absolute", left: parseFloat(estimatedCarbonSaved).toFixed(3) < 0 ? "0px" : "16px" }}>
                                                            <Card.Text style={{ fontSize: "32px", fontWeight: "normal", marginBottom: "-3px", bottom: "0px" }} className="numberType">
                                                                {typeof estimatedCarbonSaved === "number" ? (Math.abs(parseFloat(estimatedCarbonSaved).toFixed(3)) > 0 ? numberWithCommas(parseFloat(estimatedCarbonSaved).toFixed(3)) : "0.0") : "0.0"}
                                                            </Card.Text>
                                                            <Card.Text style={{ fontSize: "12px", fontWeight: "normal", marginBottom: "5px", marginLeft: (parseFloat(estimatedCarbonSaved).toFixed(3)) < 0 ? "16px" : "0px" }} className="numberType">
                                                                <span>estimated tonnes of CO<sub>2</sub>e saved per year</span>
                                                            </Card.Text>
                                                        </div>
                                                        <div style={{ bottom: "60px", position: "absolute", left: parseFloat(estimatedDeliveryImpact).toFixed(3) > 0 ? "0px" : "16px" }}>
                                                            <Card.Text style={{ fontSize: "32px", fontWeight: "normal", marginBottom: "-3px", bottom: "0px" }} className="numberType">
                                                                {typeof estimatedDeliveryImpact === "number" ? (parseFloat(estimatedDeliveryImpact).toFixed(3) > 0 ? numberWithCommas(parseFloat(-estimatedDeliveryImpact).toFixed(3)) : "0.0") : "0.0"}
                                                            </Card.Text>
                                                            <Card.Text style={{ fontSize: "12px", fontWeight: "normal", marginBottom: "5px", marginLeft: (parseFloat(estimatedDeliveryImpact).toFixed(3)) > 0 ? "16px" : "0px" }} className="numberType">
                                                                <span>estimated delivery impact</span>
                                                            </Card.Text>
                                                        </div>
                                                    </Card.Title>
                                                </Card.Body>
                                                <Card.Footer style={{ padding: "0px", margin: "0px", border: "0px", backgroundColor: "inherit" }}>
                                                    <ProgressBarCustom calculators={calculators} type={"value"} height={"48px"} projectId={data.id} />
                                                </Card.Footer>
                                            </Card>
                                        ) : (
                                            <>
                                                {
                                                    title !== undefined && typeof title === "string" ? (
                                                        <>
                                                            <Card
                                                                key={id}
                                                                text={text}
                                                                style={style}
                                                                className={noMargin ? `homeCard ${clickable === true ? "calculatorCard" : "noCalculatorCard"}` : `m-2 homeCard ${clickable === true ? "calculatorCard" : "noCalculatorCard"}`}
                                                                onClick={(typeof props.onClick === "function" && props.onClick !== undefined) ? () => props.onClick() : null}
                                                                data-tip={tooltipMessage !== undefined && typeof tooltipMessage === "string" ? tooltipMessage : ""} data-for={`${id}-tooltip`}
                                                            >
                                                                <Card.Body style={{ padding: 0, margin: "16px", textAlign: "left", color: clickable ? "rgba(255, 255, 255, 0.87)" : "rgba(255, 255, 255, 0.5)", display: "flex", alignItems: "center" }}>
                                                                    <div>
                                                                        <Card.Text style={{ fontSize: "24px", marginBottom: "10px", fontWeight: "500", lineHeight: "28px", letterSpacing: "0.216px" }}>
                                                                            {title}
                                                                        </Card.Text>
                                                                        {
                                                                            subTitle && (
                                                                                <Card.Text style={{ fontSize: "12px", fontWeight: "500", letterSpacing: "0.216px" }}>
                                                                                    {subTitle}
                                                                                </Card.Text>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        loaderShow === true && (
                                                                            <Spinner animation="border" role="status" style={{ zIndex: "100", top: "40%", position: "absolute", left: "45%" }}>
                                                                                <span className="sr-only">Loading...</span>
                                                                            </Spinner>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Card>
                                                            {
                                                                tooltip === true && tooltipMessage !== undefined && typeof tooltipMessage === "string" && (
                                                                    <ReactTooltip id={`${id}-tooltip`} className='customCardTooltip' place={tooltipPosition} offset={{ top: 20 }} arrowColor="#000000" multiline={true} />
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <Card
                                                            key={id}
                                                            text={text}
                                                            style={style}
                                                            className={type === "homeCard" ? noMargin ? "homeCard" : "homeCard m-2" : noMargin ? "" : "m-2"}
                                                        >
                                                            <Card.Header></Card.Header>
                                                            <Card.Body>
                                                                <Card.Title>
                                                                </Card.Title>
                                                                <Card.Text>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    )


                                                }

                                            </>

                                        )
                                    }
                                </>
                            )
                        }

                    </>
                )
            }
        </>
    )
}

CardCustom.defaultProps = {
    tooltipPosition: "top"
}

export default CardCustom;