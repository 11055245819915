import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import ProgressBarCustom from "../progressBar/ProgressBarCustom";
import { FaDownload, FaSave, FaList, FaArrowDown } from 'react-icons/fa';
import { Card, Container, Row, Col } from "react-bootstrap";
import "./Summary.scss"
import CreatePDF from "../createPDF/CreatePDF";
import { PDFDownloadLink, BlobProvider, Document, Page, PDFViewer } from "@react-pdf/renderer";
import { Link as ScrollLink } from "react-scroll";
import ApexCharts from "apexcharts";
import ReactTooltip from 'react-tooltip';
import ToastCustomPDF from '../toastCustom/ToastCustomPdf';
import ToastCustom from '../toastCustom/ToastCustom';

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;
const sasBlobStorage = environment === "production" ? process.env.REACT_APP_API_PDF_BLOB_STORAGE_SAS_PROD : process.env.REACT_APP_API_PDF_BLOB_STORAGE_SAS_UAT;

var input, waterData, fuelData, simpleData, plasticData, metalData, /* transportData, */ electricalItemsData, aviationData, seaFreightData, ddAssumpData, dcAssumpData, electricityData, dataCentreData, blobData;

var initialtonnes, targettonnes, initialtargetimpact, actualtonnes, comparedtoinitial, comparedtotarget, characterdata;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const camelcaseToSpaceSeparated = (text) => {
    return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, "$1").toString();
}

const calculatorColor = {
    electricity: { color: "#FFFF00" },
    fuel: { color: "#F99500" },
    paper: { color: "#FFFFF0" },
    plastic: { color: "#40A0B5" },
    metal: { color: "#d7dbdc" },
    electricalItems: { color: "#228C22" },
    dataCentre: { color: "#66FFCC" },
    waste: { color: "#40A0B5" },
    delivery: { color: "#7444DA" },
    water: { color: "#148AFF" },
    detailedDelivery: { color: "#7444DA" },/* 
    transport: { color: "#f37d7d" }, */
    aviation: { color: "#6f91ad" },
    seaFreight: { color: "#009dc4" }
}

var fuelType = {
    Diesel: true,
    Petrol: true,
    CNG: true,
    Electric: true,
    LPG: true,
    Hybrid: true,
}

var aviationDistanceType = {
    Shorthaul: true,
    Longhaul: true,
    International: true
}

var seaFreightType = {
    'Crude tanker': true,
    'Products tanker': true,
    'Chemical tanker': true,
    'LNG tanker': true,
    'LPG tanker': true,
    'Bulk carrier': true,
    'General cargo': true,
    'Container ship': true,
    'Vehicle transport': true,
    'RoRo-Ferry': true
}

var fuel = {};
var electricity = {};
var people = {};
var dataCentre = {};
var water = {};
var paper = {};
var plastic = {};
var metal = {};
/* var transport = {}; */
var electricalItems = {};
var detailedDelivery = {};
var aviation = {};
var seaFreight = {};
var fuelName = "";
var electricityName = "";
var peopleName = "";
var dataCentreName = "";
var detailedDeliveryName = "";
var waterName = "";
var paperName = "";
var plasticName = "";
var metalName = "";
var electricalItemsName = "";
/* var transportName = ""; */
var aviationName = "";
var seaFreightName = "";
var commonActiveTab = "initial";
let graphData = {
    initial: {},
    target: {},
    actual: {}
}
let graphData1 = [];
let graphData2 = [];
let graphData3 = [];
let graphData4 = [];
var calculators = {};
var rd = {};

var ecsSubTotal = 0;
var piSubTotal = 0;

var isFuelCalcExist = false;
var isEleCalcExist = false;
var isDataCenterCalcExist = false;
var isWaterCalcExist = false;
var isPlasticCalcExist = false;
var isMetalCalcExist = false;
var isElectricalItemsCalcExist = false;
/* var isTransportCalcExist = false; */
var isAviationCalcExist = false;
var isSeaFreightCalcExist = false;
var isDetailDeliveryCalcExist = false;
var isSimpleDeliveryCalcExist = false;

function SummaryComponent(props) {

    const { projectId, reload, timeStampVal, initialimpacttonnes, targetimpacttonnes, reductiontoinitialtargetimpact, actualimpacttonnes, reductiontoinitial, reductiontotarget, characterdetails } = props;
    const [reportData, setReportData] = useState({});
    const [generatedImage, setGeneratedImage] = useState(false);
    const [imgUri, setImgUri] = useState({});
    const [showToast, setShowToast] = useState({ state: false, header: "", body: "" });
    const [showToastUploadPdf, setShowToastUploadPdf] = useState({ state: false, header: '', body: '' });

    var detailCf;

    useEffect(() => {
        initialtonnes = ""
        targettonnes = ""
        initialtargetimpact = ""
        actualtonnes = ""
        comparedtoinitial = ""
        comparedtotarget = ""
    }, []);

    useEffect(() => {
        characterdata = ""
    }, []);

    if (props.characterdetails !== null && props.characterdetails !== undefined && props.characterdetails !== "") {
        characterdata = props.characterdetails
    }

    if (props.initialimpacttonnes !== null && props.initialimpacttonnes !== undefined && props.initialimpacttonnes !== "") {
        initialtonnes = props.initialimpacttonnes
    }

    if (props.targetimpacttonnes !== null && props.targetimpacttonnes !== undefined && props.targetimpacttonnes !== "") {
        targettonnes = props.targetimpacttonnes

    }

    if (props.reductiontoinitialtargetimpact !== null && props.reductiontoinitialtargetimpact !== undefined && props.reductiontoinitialtargetimpact !== "") {
        initialtargetimpact = props.reductiontoinitialtargetimpact

    }

    if (props.actualimpacttonnes !== null && props.actualimpacttonnes !== undefined && props.actualimpacttonnes !== "") {
        actualtonnes = props.actualimpacttonnes

    }

    if (props.reductiontoinitial !== null && props.reductiontoinitial !== undefined && props.reductiontoinitial !== "") {
        comparedtoinitial = props.reductiontoinitial

    }

    if (props.reductiontotarget !== null && props.reductiontotarget !== undefined && props.reductiontotarget !== "") {
        comparedtotarget = props.reductiontotarget

    }

    const getImage = () => {
        let options = {
            series: graphData1.reverse(),
            chart: {
                type: 'bar',
                height: 500,
                width: 500,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ['Estimated project carbon footprint', 'with/without implementation of reduction measures'],
                align: 'center',
                margin: 30,
            },
            xaxis: {
                categories: [['Initial impact', '(i.e. no carbon', 'reduction measures)'], ['Target impact', '(i.e. with carbon', 'reduction measures)']],
            },
            yaxis: {
                title: {
                    text: "Impact (kgCO2eq)",
                    style: {
                        fontWeight: 500,
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " CO2/e"
                    }
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'right',
                horizontalAlign: 'left',
                offsetY: 180,
                inverseOrder: true,
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#f032e6', '#911eb4', '#4363d8', '#42d4f4', '#3cb44b', '#bfef45', '#ffe119', '#f58231', '#e6194B', '#800000', '#9A6324', '#245551']
        };

        var chart1 = new ApexCharts(document.querySelector("#chart1"), options);
        chart1.render().then(() => {
            document.getElementById('chart1')
                .setAttribute(
                    'hidden', true
                );
            setTimeout(() => {
                chart1.dataURI().then(({ imgURI }) => {
                    setImgUri(prev => ({
                        ...prev,
                        0: imgURI
                    }));
                })
                    .catch(err => {

                    });
            }, 2000);
        });

        options = {
            series: graphData2.reverse(),
            chart: {
                type: 'bar',
                height: 500,
                width: 500,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ['Estimated project carbon footprint (plane excluded)', 'with/without implementation of reduction measures'],
                align: 'center',
                margin: 30,
            },
            xaxis: {
                categories: [['Initial impact', '(i.e. no carbon', 'reduction measures)'], ['Target impact', '(i.e. with carbon', 'reduction measures)']],
            },
            yaxis: {
                title: {
                    text: "Impact (kgCO2eq)",
                    style: {
                        fontWeight: 500,
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " CO2/e"
                    }
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'right',
                horizontalAlign: 'left',
                offsetY: 200,
                inverseOrder: true,
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#911eb4', '#4363d8', '#42d4f4', '#3cb44b', '#bfef45', '#ffe119', '#f58231', '#e6194B', '#800000', '#9A6324', '#245551']
        };

        var chart2 = new ApexCharts(document.querySelector("#chart2"), options);
        chart2.render().then(() => {
            document.getElementById('chart2')
                .setAttribute(
                    'hidden', true
                );
            setTimeout(() => {
                chart2.dataURI().then(({ imgURI }) => {
                    setImgUri(prev => ({
                        ...prev,
                        1: imgURI
                    }));
                })
                    .catch(err => {

                    });
            }, 2000);
        });

        options = {
            series: graphData3.reverse(),
            chart: {
                type: 'bar',
                height: 555,
                width: 500,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ['Estimated project carbon footprint', 'compare to target and initial impacts'],
                align: 'center',
                margin: 30,
            },
            xaxis: {
                categories: [['Initial', 'impact', '(i.e. no', 'carbon', 'reduction', 'measures)'], ['Target', 'impact', '(i.e. with', 'carbon', 'reduction', 'measures)'], ["Final", "impact", "(i.e. impact", "estimated", "at the", "project's", "end)"]],
            },
            yaxis: {
                title: {
                    text: "Impact (kgCO2eq)",
                    style: {
                        fontWeight: 500,
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " CO2/e"
                    }
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'right',
                horizontalAlign: 'left',
                offsetY: 180,
                inverseOrder: true,
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#f032e6', '#911eb4', '#4363d8', '#42d4f4', '#3cb44b', '#bfef45', '#ffe119', '#f58231', '#e6194B', '#800000', '#9A6324', '#245551']
        };

        var chart3 = new ApexCharts(document.querySelector("#chart3"), options);
        chart3.render().then(() => {
            document.getElementById('chart3')
                .setAttribute(
                    'hidden', true
                );
            setTimeout(() => {
                chart3.dataURI().then(({ imgURI }) => {
                    setImgUri(prev => ({
                        ...prev,
                        2: imgURI
                    }));
                })
                    .catch(err => {

                    });
            }, 2000);
        });

        options = {
            series: graphData4.reverse(),
            chart: {
                type: 'bar',
                height: 555,
                width: 500,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ['Estimated project carbon footprint (plane excluded)', 'compare to target and initial impacts'],
                align: 'center',
                margin: 30,
            },
            xaxis: {
                categories: [['Initial', 'impact', '(i.e. no', 'carbon', 'reduction', 'measures)'], ['Target', 'impact', '(i.e. with', 'carbon', 'reduction', 'measures)'], ["Final", "impact", "(i.e. impact", "estimated", "at the", "project's", "end)"]],
            },
            yaxis: {
                title: {
                    text: "Impact (kgCO2eq)",
                    style: {
                        fontWeight: 500,
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " CO2/e"
                    }
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'right',
                horizontalAlign: 'left',
                offsetY: 200,
                inverseOrder: true,
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#911eb4', '#4363d8', '#42d4f4', '#3cb44b', '#bfef45', '#ffe119', '#f58231', '#e6194B', '#800000', '#9A6324', '#245551']
        };

        var chart4 = new ApexCharts(document.querySelector("#chart4"), options);
        chart4.render().then(() => {
            document.getElementById('chart4')
                .setAttribute(
                    'hidden', true
                );
            setTimeout(() => {
                chart4.dataURI().then(({ imgURI }) => {
                    setImgUri(prev => ({
                        ...prev,
                        3: imgURI
                    }));
                })
                    .catch(err => {

                    });
            }, 2000);
        });
    }

    useEffect(() => {
        if (imgUri !== null && Object.keys(imgUri).length === 4) {
            setReportData(prev => ({
                ...prev,
                ...rd,
                imgUri: imgUri,
                commonActiveTab: commonActiveTab
            }));
        }
    }, [imgUri]);

    const getData = () => {
        setGeneratedImage(false);
        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/project/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    ecsSubTotal = 0;
                    piSubTotal = 0;
                    var isGraphDataPresent = false;
                  //  console.log("response.data[0].calculatorData")
                  //  console.log(response.data[0].calculatorData)
                  //  var calcArray = response.data[0].calculatorData;
                 // Remove the Transport calculator
                  var calcArray = response.data[0].calculatorData.filter(item => item.name !== 'transport');
                 //  console.log(calcArray);
                    var calc = {};
                    var tempReportData = [];
                    tempReportData = response.data[0];
                    for (var i = 0; i < calcArray.length; i++) {
                        if (calcArray[i].name !== "people" && calcArray[i].name !== "detailedDelivery") {
                            ecsSubTotal += calcArray[i].totalCarbonFootprint;
                        } else {
                            if (calcArray[i].name === "people") {
                                piSubTotal += calcArray[i].totalCarbonFootprint;
                            }
                        }
                        if (calcArray[i].name === "fuel") {
                            let temp = {};
                            var fCF = 0;
                            for (const item of calcArray[i].data) {
                                fCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.fuelType.name]: { itemType: item.fuelType.name, st: item.fuelSourceType.name, cf: (temp[item.fuelType.name] !== undefined && temp[item.fuelType.name]["cf"] !== undefined && temp[item.fuelType.name]["cf"] !== null) ? (temp[item.fuelType.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            fuelName = "Land Transport";// (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            fuel = {};
                            for (const item of Object.keys(fuelType)) {
                                if (temp.hasOwnProperty(item)) {
                                    fuel = {
                                        ...fuel,
                                        [item]: temp[item]
                                    }
                                }
                            }

                            let sources = {};
                            let years = [];

                            for (const ffm of Object.keys(props.master.fuelFactorMaster)) {
                                if (!years.includes(props.master.fuelFactorMaster[ffm].year)) {
                                    years.push(props.master.fuelFactorMaster[ffm].year);
                                }
                                if (sources[props.master.fuelFactorMaster[ffm].year] === undefined) {
                                    sources[props.master.fuelFactorMaster[ffm].year] = { source: [] };
                                }
                                if (!sources[props.master.fuelFactorMaster[ffm].year].source.includes(props.master.fuelFactorMaster[ffm].source)) {
                                    sources[props.master.fuelFactorMaster[ffm].year].source.push(props.master.fuelFactorMaster[ffm].source);
                                    sources[props.master.fuelFactorMaster[ffm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                fuel: { cf: fCF, sources, years }
                            };
                        } else if (calcArray[i].name === "aviation") {
                            let temp = {};
                            var fCF = 0;
                            for (const item of calcArray[i].data) {
                                fCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.aviationDistanceType.name]: { itemType: item.aviationDistanceType.name, st: item.aviationType.name, cf: (temp[item.aviationDistanceType.name] !== undefined && temp[item.aviationDistanceType.name]["cf"] !== undefined && temp[item.aviationDistanceType.name]["cf"] !== null) ? (temp[item.aviationDistanceType.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            aviationName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            aviation = {};
                            for (const item of Object.keys(aviationDistanceType)) {
                                if (temp.hasOwnProperty(item)) {
                                    aviation = {
                                        ...aviation,
                                        [item]: temp[item]
                                    }
                                }
                            }

                            let sources = {};
                            let years = [];

                            for (const ffm of Object.keys(props.master.aviationFreightFactorMaster)) {
                                if (!years.includes(props.master.aviationFreightFactorMaster[ffm].year)) {
                                    years.push(props.master.aviationFreightFactorMaster[ffm].year);
                                }
                                if (sources[props.master.aviationFreightFactorMaster[ffm].year] === undefined) {
                                    sources[props.master.aviationFreightFactorMaster[ffm].year] = { source: [] };
                                }
                                if (!sources[props.master.aviationFreightFactorMaster[ffm].year].source.includes(props.master.aviationFreightFactorMaster[ffm].source)) {
                                    sources[props.master.aviationFreightFactorMaster[ffm].year].source.push(props.master.aviationFreightFactorMaster[ffm].source);
                                    sources[props.master.aviationFreightFactorMaster[ffm].year].source.sort();
                                }
                            }
                            for (const ffm of Object.keys(props.master.aviationClassFactorMaster)) {
                                if (!years.includes(props.master.aviationClassFactorMaster[ffm].year)) {
                                    years.push(props.master.aviationClassFactorMaster[ffm].year);
                                }
                                if (sources[props.master.aviationClassFactorMaster[ffm].year] === undefined) {
                                    sources[props.master.aviationClassFactorMaster[ffm].year] = { source: [] };
                                }
                                if (!sources[props.master.aviationClassFactorMaster[ffm].year].source.includes(props.master.aviationClassFactorMaster[ffm].source)) {
                                    sources[props.master.aviationClassFactorMaster[ffm].year].source.push(props.master.aviationClassFactorMaster[ffm].source);
                                    sources[props.master.aviationClassFactorMaster[ffm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                aviation: { cf: fCF, sources, years }
                            };
                        } else if (calcArray[i].name === "seaFreight") {
                            let temp = {};
                            var sfCF = 0;
                            for (const item of calcArray[i].data) {
                                sfCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.seaFreightType.name]: { itemType: item.seaFreightType.name, st: item.seaFreightActivity.name, cf: (temp[item.seaFreightType.name] !== undefined && temp[item.seaFreightType.name]["cf"] !== undefined && temp[item.seaFreightType.name]["cf"] !== null) ? (temp[item.seaFreightType.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            seaFreightName = camelcaseToSpaceSeparated(calcArray[i].name);
                            seaFreightName = seaFreightName.charAt(0).toUpperCase() + seaFreightName.slice(1)
                            seaFreight = {};
                            for (const item of Object.keys(seaFreightType)) {
                                if (temp.hasOwnProperty(item)) {
                                    seaFreight = {
                                        ...seaFreight,
                                        [item]: temp[item]
                                    }
                                }
                            }
                            let sources = {};
                            let years = [];

                            for (const ffm of Object.keys(props.master.seaFreightSeaTankerSizeMaster)) {
                                if (!years.includes(props.master.seaFreightSeaTankerSizeMaster[ffm].year)) {
                                    years.push(props.master.seaFreightSeaTankerSizeMaster[ffm].year);
                                }
                                if (sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year] === undefined) {
                                    sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year] = { source: [] };
                                }
                                if (!sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.includes(props.master.seaFreightSeaTankerSizeMaster[ffm].source)) {
                                    sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.push(props.master.seaFreightSeaTankerSizeMaster[ffm].source);
                                    sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.sort();
                                }
                            }
                            for (const ffm of Object.keys(props.master.seaFreightCargoShipSizeMaster)) {
                                if (!years.includes(props.master.seaFreightCargoShipSizeMaster[ffm].year)) {
                                    years.push(props.master.seaFreightCargoShipSizeMaster[ffm].year);
                                }
                                if (sources[props.master.seaFreightCargoShipSizeMaster[ffm].year] === undefined) {
                                    sources[props.master.seaFreightCargoShipSizeMaster[ffm].year] = { source: [] };
                                }
                                if (!sources[props.master.seaFreightCargoShipSizeMaster[ffm].year].source.includes(props.master.seaFreightCargoShipSizeMaster[ffm].source)) {
                                    sources[props.master.seaFreightCargoShipSizeMaster[ffm].year].source.push(props.master.seaFreightCargoShipSizeMaster[ffm].source);
                                    sources[props.master.seaFreightCargoShipSizeMaster[ffm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                seaFreight: { cf: sfCF, sources, years }
                            };
                        } else if (calcArray[i].name === "electricity") {
                            let temp = {};
                            var eCF = 0;
                            for (const item of calcArray[i].data) {
                                eCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.country.name]: { itemType: item.country.name, st: item.electricitySourceType.name, cf: (temp[item.country.name] !== undefined && temp[item.country.name]["cf"] !== undefined && temp[item.country.name]["cf"] !== null) ? (temp[item.country.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            electricityName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            electricity = temp;

                            let sources = {};
                            let years = [];

                            for (const efm of Object.keys(props.master.electricityFactorMaster)) {
                                for (const ft of Object.keys(props.master.electricityFactorMaster[efm])) {
                                    if (ft.split("Source").length > 1) {
                                        if (!years.includes(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"])) {
                                            years.push(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]);
                                        }

                                        if (sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] === undefined) {
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] = { source: [] };
                                        }
                                        if (!sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.includes(props.master.electricityFactorMaster[efm][ft])) {
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.push(props.master.electricityFactorMaster[efm][ft]);
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.sort();
                                        }
                                    }
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                electricity: { cf: eCF, sources, years }
                            };
                        } else if (calcArray[i].name === "dataCentre") {
                            let temp = {};
                            var dcCF = 0;
                            for (const item of calcArray[i].data) {
                                dcCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.country.name]: { itemType: item.country.name, st: item.dataCentreSourceType.name, cf: (temp[item.country.name] !== undefined && temp[item.country.name]["cf"] !== undefined && temp[item.country.name]["cf"] !== null) ? (temp[item.country.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            dataCentreName = camelcaseToSpaceSeparated(calcArray[i].name);
                            dataCentreName = dataCentreName.charAt(0).toUpperCase() + dataCentreName.slice(1)
                            dataCentre = temp;

                            let sources = {};
                            let years = [];

                            for (const efm of Object.keys(props.master.electricityFactorMaster)) {
                                for (const ft of Object.keys(props.master.electricityFactorMaster[efm])) {
                                    if (ft.split("Source").length > 1) {
                                        if (!years.includes(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"])) {
                                            years.push(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]);
                                        }

                                        if (sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] === undefined) {
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] = { source: [] };
                                        }
                                        if (!sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.includes(props.master.electricityFactorMaster[efm][ft])) {
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.push(props.master.electricityFactorMaster[efm][ft]);
                                            sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.sort();
                                        }
                                    }
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                dataCentre: { cf: dcCF, sources, years }
                            };
                        } else if (calcArray[i].name === "water") {
                            let temp = {};
                            var wCF = 0;
                            for (const item of calcArray[i].data) {
                                wCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.country.name]: { itemType: item.country.name, st: item.waterSourceType.name, cf: (temp[item.country.name] !== undefined && temp[item.country.name]["cf"] !== undefined && temp[item.country.name]["cf"] !== null) ? (temp[item.country.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            waterName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            water = temp;

                            let sources = {};
                            let years = [];

                            for (const wfm of Object.keys(props.master.waterFactorMaster)) {
                                for (const wt of Object.keys(props.master.waterFactorMaster[wfm])) {
                                    if (wt.split("Source").length > 1) {
                                        if (!years.includes(props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"])) {
                                            years.push(props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]);
                                        }

                                        if (sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]] === undefined) {
                                            sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]] = { source: [] };
                                        }
                                        if (!sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.includes(props.master.waterFactorMaster[wfm][wt])) {
                                            sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.push(props.master.waterFactorMaster[wfm][wt]);
                                            sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.sort();
                                        }
                                    }
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                water: { cf: wCF, sources, years }
                            };
                        } else if (calcArray[i].name === "paper") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[i].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.paperSourceType.name]: { itemType: item.paperSourceType.name, st: item.paperSourceType.name, cf: item.carbonFootprint }
                                }
                            }
                            paperName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            paper = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.paperMaterialUseMaster)) {
                                if (!years.includes(props.master.paperMaterialUseMaster[pfm].year)) {
                                    years.push(props.master.paperMaterialUseMaster[pfm].year);
                                }
                                if (sources[props.master.paperMaterialUseMaster[pfm].year] === undefined) {
                                    sources[props.master.paperMaterialUseMaster[pfm].year] = { source: [] };
                                }
                                if (!sources[props.master.paperMaterialUseMaster[pfm].year].source.includes(props.master.paperMaterialUseMaster[pfm].source)) {
                                    sources[props.master.paperMaterialUseMaster[pfm].year].source.push(props.master.paperMaterialUseMaster[pfm].source);
                                    sources[props.master.paperMaterialUseMaster[pfm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                paper: { cf: pCF, sources, years }
                            };
                        } else if (calcArray[i].name === "plastic") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[i].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.plasticSourceType.name]: { itemType: item.plasticSourceType.name, st: item.plasticSourceType.name, cf: item.carbonFootprint }
                                }
                            }
                            plasticName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            plastic = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.plasticMaterialUseMaster)) {
                                if (!years.includes(props.master.plasticMaterialUseMaster[pfm].year)) {
                                    years.push(props.master.plasticMaterialUseMaster[pfm].year);
                                }
                                if (sources[props.master.plasticMaterialUseMaster[pfm].year] === undefined) {
                                    sources[props.master.plasticMaterialUseMaster[pfm].year] = { source: [] };
                                }
                                if (!sources[props.master.plasticMaterialUseMaster[pfm].year].source.includes(props.master.plasticMaterialUseMaster[pfm].source)) {
                                    sources[props.master.plasticMaterialUseMaster[pfm].year].source.push(props.master.plasticMaterialUseMaster[pfm].source);
                                    sources[props.master.plasticMaterialUseMaster[pfm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                plastic: { cf: pCF, sources, years }
                            };
                        } else if (calcArray[i].name === "metal") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[i].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.metalSourceType.name]: { itemType: item.metalSourceType.name, st: item.metalSourceType.name, cf: item.carbonFootprint }
                                }
                            }
                            metalName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            metal = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.metalMaterialUseMaster)) {
                                if (!years.includes(props.master.metalMaterialUseMaster[pfm].year)) {
                                    years.push(props.master.metalMaterialUseMaster[pfm].year);
                                }
                                if (sources[props.master.metalMaterialUseMaster[pfm].year] === undefined) {
                                    sources[props.master.metalMaterialUseMaster[pfm].year] = { source: [] };
                                }
                                if (!sources[props.master.metalMaterialUseMaster[pfm].year].source.includes(props.master.metalMaterialUseMaster[pfm].source)) {
                                    sources[props.master.metalMaterialUseMaster[pfm].year].source.push(props.master.metalMaterialUseMaster[pfm].source);
                                    sources[props.master.metalMaterialUseMaster[pfm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                metal: { cf: pCF, sources, years }
                            };
                        }/*  else if (calcArray[i].name === "transport") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[i].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.transportType.name]: { itemType: item.transportType.name, st: item.transportType.name, cf: item.carbonFootprint }
                                }
                            }
                            transportName = (calcArray[i].name.charAt(0).toUpperCase() + calcArray[i].name.slice(1));
                            transport = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.transportLiquidFactorMaster)) {
                                if (!years.includes(props.master.transportLiquidFactorMaster[pfm].year)) {
                                    years.push(props.master.transportLiquidFactorMaster[pfm].year);
                                }
                                if (sources[props.master.transportLiquidFactorMaster[pfm].year] === undefined) {
                                    sources[props.master.transportLiquidFactorMaster[pfm].year] = { source: [] };
                                }
                                if (!sources[props.master.transportLiquidFactorMaster[pfm].year].source.includes(props.master.transportLiquidFactorMaster[pfm].source)) {
                                    sources[props.master.transportLiquidFactorMaster[pfm].year].source.push(props.master.transportLiquidFactorMaster[pfm].source);
                                    sources[props.master.transportLiquidFactorMaster[pfm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                transport: { cf: pCF, sources, years }
                            };
                        } */
                        else if (calcArray[i].name === "electricalItems") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[i].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.electricalItemsSourceType.name]: { itemType: item.electricalItemsSourceType.name, st: item.electricalItemsSourceType.name, cf: item.carbonFootprint }
                                }
                            }
                            electricalItemsName = camelcaseToSpaceSeparated(calcArray[i].name);
                            electricalItemsName = electricalItemsName.charAt(0).toUpperCase() + electricalItemsName.slice(1)
                            electricalItems = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.electricalItemsMaterialUseMaster)) {
                                if (!years.includes(props.master.electricalItemsMaterialUseMaster[pfm].year)) {
                                    years.push(props.master.electricalItemsMaterialUseMaster[pfm].year);
                                }
                                if (sources[props.master.electricalItemsMaterialUseMaster[pfm].year] === undefined) {
                                    sources[props.master.electricalItemsMaterialUseMaster[pfm].year] = { source: [] };
                                }
                                if (!sources[props.master.electricalItemsMaterialUseMaster[pfm].year].source.includes(props.master.electricalItemsMaterialUseMaster[pfm].source)) {
                                    sources[props.master.electricalItemsMaterialUseMaster[pfm].year].source.push(props.master.electricalItemsMaterialUseMaster[pfm].source);
                                    sources[props.master.electricalItemsMaterialUseMaster[pfm].year].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                electricalItems: { cf: pCF, sources, years }
                            };
                        }
                    }
                    calculators = calc;
                    for (var j = 0; j < calcArray.length; j++) {
                        if (calcArray[j].name === "people") {
                            let temp = {};
                            var pCF = 0;
                            for (const item of calcArray[j].data) {
                                pCF += item.carbonFootprint;
                                temp = {
                                    ...temp,
                                    [item.country.name]: { itemType: item.country.name, st: item.peopleSourceType.name, cf: (temp[item.country.name] !== undefined && temp[item.country.name]["cf"] !== undefined && temp[item.country.name]["cf"] !== null) ? (temp[item.country.name]["cf"] + item.carbonFootprint) : item.carbonFootprint }
                                }
                            }
                            peopleName = "Delivery";
                            people = temp;

                            let sources = {};
                            let years = [];

                            for (const pfm of Object.keys(props.master.peopleFactorMaster)) {
                                for (const pt of Object.keys(props.master.peopleFactorMaster[pfm])) {
                                    if (pt.split("Source").length > 1) {
                                        if (!years.includes(props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"])) {
                                            years.push(props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]);
                                        }

                                        if (sources[props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]] === undefined) {
                                            sources[props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]] = { source: [] };
                                        }
                                        if (!sources[props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]].source.includes(props.master.peopleFactorMaster[pfm][pt])) {
                                            sources[props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]].source.push(props.master.peopleFactorMaster[pfm][pt]);
                                            sources[props.master.peopleFactorMaster[pfm][pt.split("Source")[0] + "Year"]].source.sort();
                                        }
                                    }
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                delivery: { cf: pCF, sources, years }
                            };
                        } else if (calcArray[j].name === "detailedDelivery") {
                            let initial = 0;
                            let target = 0;
                            let actual = 0;
                            calcArray[j].data.forEach(item => {
                                if (typeof item === "object") {
                                    initial += item.initialVisited;
                                    target += item.targetVisited;
                                    actual += item.actualVisited;
                                    isGraphDataPresent = true;
                                }
                            });
                            let len = calcArray[j].data.length;
                            while (len !== 0) {
                                if (len === actual) {
                                    commonActiveTab = "actual";
                                    break;
                                }
                                if (len === target) {
                                    commonActiveTab = "target";
                                    break;
                                }
                                if (len === initial) {
                                    commonActiveTab = "initial";
                                    break;
                                }
                                len--;
                            }
                            let temp = {};
                            let ddCF = 0;
                            graphData = {
                                initial: {},
                                target: {},
                                actual: {}
                            }
                            graphData1 = [];
                            graphData2 = [];
                            graphData3 = [];
                            graphData4 = [];
                            for (const item of calcArray[j].data) {
                                let total = item[`${commonActiveTab}OfficeCF`] +
                                    item[`${commonActiveTab}WfhCF`] +
                                    item[`${commonActiveTab}PlaneCF`] +
                                    item[`${commonActiveTab}HighSpeedTrainCF`] +
                                    item[`${commonActiveTab}AverageTrainCF`] +
                                    item[`${commonActiveTab}TaxiCF`] +
                                    item[`${commonActiveTab}CarCF`] +
                                    item[`${commonActiveTab}PublicTransportCF`] +
                                    item[`${commonActiveTab}DigitalCF`] +
                                    item[`${commonActiveTab}FoodCF`] +
                                    item[`${commonActiveTab}WasteCF`] +
                                    item[`${commonActiveTab}HotelCF`];

                                graphData.initial["Office"] = graphData.initial["Office"] === undefined ? (Math.round(item[`initialOfficeCF`] * 2000) / 2000) : (Math.round((graphData.initial["Office"] + (Math.round(item[`initialOfficeCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["WFH"] = graphData.initial["WFH"] === undefined ? (Math.round(item[`initialWfhCF`] * 2000) / 2000) : (Math.round((graphData.initial["WFH"] + (Math.round(item[`initialWfhCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Waste"] = graphData.initial["Waste"] === undefined ? (Math.round(item[`initialWasteCF`] * 2000) / 2000) : (Math.round((graphData.initial["Waste"] + (Math.round(item[`initialWasteCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Food"] = graphData.initial["Food"] === undefined ? (Math.round(item[`initialFoodCF`] * 2000) / 2000) : (Math.round((graphData.initial["Food"] + (Math.round(item[`initialFoodCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Hotel"] = graphData.initial["Hotel"] === undefined ? (Math.round(item[`initialHotelCF`] * 2000) / 2000) : (Math.round((graphData.initial["Hotel"] + (Math.round(item[`initialHotelCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Digital"] = graphData.initial["Digital"] === undefined ? (Math.round(item[`initialDigitalCF`] * 2000) / 2000) : (Math.round((graphData.initial["Digital"] + (Math.round(item[`initialDigitalCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Public Transport"] = graphData.initial["Public Transport"] === undefined ? (Math.round(item[`initialPublicTransportCF`] * 2000) / 2000) : (Math.round((graphData.initial["Public Transport"] + (Math.round(item[`initialPublicTransportCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Car"] = graphData.initial["Car"] === undefined ? (Math.round(item[`initialCarCF`] * 2000) / 2000) : (Math.round((graphData.initial["Car"] + (Math.round(item[`initialCarCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Taxi"] = graphData.initial["Taxi"] === undefined ? (Math.round(item[`initialTaxiCF`] * 2000) / 2000) : (Math.round((graphData.initial["Taxi"] + (Math.round(item[`initialTaxiCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Average Train"] = graphData.initial["Average Train"] === undefined ? (Math.round(item[`initialAverageTrainCF`] * 2000) / 2000) : (Math.round((graphData.initial["Average Train"] + (Math.round(item[`initialAverageTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["High Speed Train"] = graphData.initial["High Speed Train"] === undefined ? (Math.round(item[`initialHighSpeedTrainCF`] * 2000) / 2000) : (Math.round((graphData.initial["High Speed Train"] + (Math.round(item[`initialHighSpeedTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.initial["Plane"] = graphData.initial["Plane"] === undefined ? (Math.round(item[`initialPlaneCF`] * 2000) / 2000) : (Math.round((graphData.initial["Plane"] + (Math.round(item[`initialPlaneCF`] * 2000) / 2000)) * 2000) / 2000);

                                graphData.target["Office"] = graphData.target["Office"] === undefined ? (Math.round(item[`targetOfficeCF`] * 2000) / 2000) : (Math.round((graphData.target["Office"] + (Math.round(item[`targetOfficeCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["WFH"] = graphData.target["WFH"] === undefined ? (Math.round(item[`targetWfhCF`] * 2000) / 2000) : (Math.round((graphData.target["WFH"] + (Math.round(item[`targetWfhCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Waste"] = graphData.target["Waste"] === undefined ? (Math.round(item[`targetWasteCF`] * 2000) / 2000) : (Math.round((graphData.target["Waste"] + (Math.round(item[`targetWasteCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Food"] = graphData.target["Food"] === undefined ? (Math.round(item[`targetFoodCF`] * 2000) / 2000) : (Math.round((graphData.target["Food"] + (Math.round(item[`targetFoodCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Hotel"] = graphData.target["Hotel"] === undefined ? (Math.round(item[`targetHotelCF`] * 2000) / 2000) : (Math.round((graphData.target["Hotel"] + (Math.round(item[`targetHotelCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Digital"] = graphData.target["Digital"] === undefined ? (Math.round(item[`targetDigitalCF`] * 2000) / 2000) : (Math.round((graphData.target["Digital"] + (Math.round(item[`targetDigitalCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Public Transport"] = graphData.target["Public Transport"] === undefined ? (Math.round(item[`targetPublicTransportCF`] * 2000) / 2000) : (Math.round((graphData.target["Public Transport"] + (Math.round(item[`targetPublicTransportCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Car"] = graphData.target["Car"] === undefined ? (Math.round(item[`targetCarCF`] * 2000) / 2000) : (Math.round((graphData.target["Car"] + (Math.round(item[`targetCarCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Taxi"] = graphData.target["Taxi"] === undefined ? (Math.round(item[`targetTaxiCF`] * 2000) / 2000) : (Math.round((graphData.target["Taxi"] + (Math.round(item[`targetTaxiCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Average Train"] = graphData.target["Average Train"] === undefined ? (Math.round(item[`targetAverageTrainCF`] * 2000) / 2000) : (Math.round((graphData.target["Average Train"] + (Math.round(item[`targetAverageTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["High Speed Train"] = graphData.target["High Speed Train"] === undefined ? (Math.round(item[`targetHighSpeedTrainCF`] * 2000) / 2000) : (Math.round((graphData.target["High Speed Train"] + (Math.round(item[`targetHighSpeedTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.target["Plane"] = graphData.target["Plane"] === undefined ? (Math.round(item[`targetPlaneCF`] * 2000) / 2000) : (Math.round((graphData.target["Plane"] + (Math.round(item[`targetPlaneCF`] * 2000) / 2000)) * 2000) / 2000);

                                graphData.actual["Office"] = graphData.actual["Office"] === undefined ? (Math.round(item[`actualOfficeCF`] * 2000) / 2000) : (Math.round((graphData.actual["Office"] + (Math.round(item[`actualOfficeCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["WFH"] = graphData.actual["WFH"] === undefined ? (Math.round(item[`actualWfhCF`] * 2000) / 2000) : (Math.round((graphData.actual["WFH"] + (Math.round(item[`actualWfhCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Waste"] = graphData.actual["Waste"] === undefined ? (Math.round(item[`actualWasteCF`] * 2000) / 2000) : (Math.round((graphData.actual["Waste"] + (Math.round(item[`actualWasteCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Food"] = graphData.actual["Food"] === undefined ? (Math.round(item[`actualFoodCF`] * 2000) / 2000) : (Math.round((graphData.actual["Food"] + (Math.round(item[`actualFoodCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Hotel"] = graphData.actual["Hotel"] === undefined ? (Math.round(item[`actualHotelCF`] * 2000) / 2000) : (Math.round((graphData.actual["Hotel"] + (Math.round(item[`actualHotelCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Digital"] = graphData.actual["Digital"] === undefined ? (Math.round(item[`actualDigitalCF`] * 2000) / 2000) : (Math.round((graphData.actual["Digital"] + (Math.round(item[`actualDigitalCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Public Transport"] = graphData.actual["Public Transport"] === undefined ? (Math.round(item[`actualPublicTransportCF`] * 2000) / 2000) : (Math.round((graphData.actual["Public Transport"] + (Math.round(item[`actualPublicTransportCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Car"] = graphData.actual["Car"] === undefined ? (Math.round(item[`actualCarCF`] * 2000) / 2000) : (Math.round((graphData.actual["Car"] + (Math.round(item[`actualCarCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Taxi"] = graphData.actual["Taxi"] === undefined ? (Math.round(item[`actualTaxiCF`] * 2000) / 2000) : (Math.round((graphData.actual["Taxi"] + (Math.round(item[`actualTaxiCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Average Train"] = graphData.actual["Average Train"] === undefined ? (Math.round(item[`actualAverageTrainCF`] * 2000) / 2000) : (Math.round((graphData.actual["Average Train"] + (Math.round(item[`actualAverageTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["High Speed Train"] = graphData.actual["High Speed Train"] === undefined ? (Math.round(item[`actualHighSpeedTrainCF`] * 2000) / 2000) : (Math.round((graphData.actual["High Speed Train"] + (Math.round(item[`actualHighSpeedTrainCF`] * 2000) / 2000)) * 2000) / 2000);
                                graphData.actual["Plane"] = graphData.actual["Plane"] === undefined ? (Math.round(item[`actualPlaneCF`] * 2000) / 2000) : (Math.round((graphData.actual["Plane"] + (Math.round(item[`actualPlaneCF`] * 2000) / 2000)) * 2000) / 2000);

                                ddCF += total;
                                piSubTotal += total;
                                temp = {
                                    ...temp,
                                    [item.country.name]: { itemType: item.country.name, cf: (temp[item.country.name] !== undefined && temp[item.country.name]["cf"] !== undefined && temp[item.country.name]["cf"] !== null) ? (temp[item.country.name]["cf"] + total) : total }
                                }
                            }

                            Object.keys(graphData.initial).forEach(key => {
                                graphData1.push({
                                    name: key,
                                    data: [graphData.initial[key], graphData.target[key]]
                                });
                                graphData3.push({
                                    name: key,
                                    data: [graphData.initial[key], graphData.target[key], graphData.actual[key]]
                                });
                                if (key !== "Plane") {
                                    graphData2.push({
                                        name: key,
                                        data: [graphData.initial[key], graphData.target[key]]
                                    });
                                    graphData4.push({
                                        name: key,
                                        data: [graphData.initial[key], graphData.target[key], graphData.actual[key]]
                                    });
                                }
                            });
                            detailedDeliveryName = camelcaseToSpaceSeparated(calcArray[j].name);
                            detailedDeliveryName = detailedDeliveryName.charAt(0).toUpperCase() + detailedDeliveryName.slice(1)
                            detailedDelivery = temp;

                            let sources = {};
                            let years = [];

                            for (const tfm of Object.keys(props.master.transportFactorMaster)) {
                                if (!years.includes(props.master.transportFactorMaster[tfm]["year"])) {
                                    years.push(props.master.transportFactorMaster[tfm]["year"]);
                                }

                                if (sources[props.master.transportFactorMaster[tfm]["year"]] === undefined) {
                                    sources[props.master.transportFactorMaster[tfm]["year"]] = { source: [] };
                                }
                                if (!sources[props.master.transportFactorMaster[tfm]["year"]].source.includes(props.master.transportFactorMaster[tfm]["source"])) {
                                    sources[props.master.transportFactorMaster[tfm]["year"]].source.push(props.master.transportFactorMaster[tfm]["source"]);
                                    sources[props.master.transportFactorMaster[tfm]["year"]].source.sort();
                                }
                            }
                            for (const ffm of Object.keys(props.master.foodFactorMaster)) {
                                if (!years.includes(props.master.foodFactorMaster[ffm]["year"])) {
                                    years.push(props.master.foodFactorMaster[ffm]["year"]);
                                }

                                if (sources[props.master.foodFactorMaster[ffm]["year"]] === undefined) {
                                    sources[props.master.foodFactorMaster[ffm]["year"]] = { source: [] };
                                }
                                if (!sources[props.master.foodFactorMaster[ffm]["year"]].source.includes(props.master.foodFactorMaster[ffm]["source"])) {
                                    sources[props.master.foodFactorMaster[ffm]["year"]].source.push(props.master.foodFactorMaster[ffm]["source"]);
                                    sources[props.master.foodFactorMaster[ffm]["year"]].source.sort();
                                }
                            }
                            for (const wfm of Object.keys(props.master.wasteFactorMaster)) {
                                if (!years.includes(props.master.wasteFactorMaster[wfm]["year"])) {
                                    years.push(props.master.wasteFactorMaster[wfm]["year"]);
                                }

                                if (sources[props.master.wasteFactorMaster[wfm]["year"]] === undefined) {
                                    sources[props.master.wasteFactorMaster[wfm]["year"]] = { source: [] };
                                }
                                if (!sources[props.master.wasteFactorMaster[wfm]["year"]].source.includes(props.master.wasteFactorMaster[wfm]["source"])) {
                                    sources[props.master.wasteFactorMaster[wfm]["year"]].source.push(props.master.wasteFactorMaster[wfm]["source"]);
                                    sources[props.master.wasteFactorMaster[wfm]["year"]].source.sort();
                                }
                            }
                            for (const dfm of Object.keys(props.master.digitalFactorMaster)) {
                                if (!years.includes(props.master.digitalFactorMaster[dfm]["year"])) {
                                    years.push(props.master.digitalFactorMaster[dfm]["year"]);
                                }

                                if (sources[props.master.digitalFactorMaster[dfm]["year"]] === undefined) {
                                    sources[props.master.digitalFactorMaster[dfm]["year"]] = { source: [] };
                                }
                                if (!sources[props.master.digitalFactorMaster[dfm]["year"]].source.includes(props.master.digitalFactorMaster[dfm]["source"])) {
                                    sources[props.master.digitalFactorMaster[dfm]["year"]].source.push(props.master.digitalFactorMaster[dfm]["source"]);
                                    sources[props.master.digitalFactorMaster[dfm]["year"]].source.sort();
                                }
                            }
                            for (const hfm of Object.keys(props.master.hotelFactorMaster)) {
                                if (!years.includes(props.master.hotelFactorMaster[hfm]["year"])) {
                                    years.push(props.master.hotelFactorMaster[hfm]["year"]);
                                }

                                if (sources[props.master.hotelFactorMaster[hfm]["year"]] === undefined) {
                                    sources[props.master.hotelFactorMaster[hfm]["year"]] = { source: [] };
                                }
                                if (!sources[props.master.hotelFactorMaster[hfm]["year"]].source.includes(props.master.hotelFactorMaster[hfm]["source"])) {
                                    sources[props.master.hotelFactorMaster[hfm]["year"]].source.push(props.master.hotelFactorMaster[hfm]["source"]);
                                    sources[props.master.hotelFactorMaster[hfm]["year"]].source.sort();
                                }
                            }
                            years = years.sort((a, b) => a - b);

                            calc = {
                                ...calc,
                                detailedDelivery: { cf: ddCF, sources, years }
                            };
                        }
                    }

                    rd = { report: tempReportData, sourceYear: calc };
                    if (isGraphDataPresent) {
                        document.getElementById("chart1").innerHTML = "";
                        document.getElementById('chart1').removeAttribute("hidden");
                        document.getElementById("chart2").innerHTML = "";
                        document.getElementById('chart2').removeAttribute("hidden");
                        document.getElementById("chart3").innerHTML = "";
                        document.getElementById('chart3').removeAttribute("hidden");
                        document.getElementById("chart4").innerHTML = "";
                        document.getElementById('chart4').removeAttribute("hidden");
                        getImage();
                    } else {
                        document.getElementById('chart1')
                            .setAttribute(
                                'hidden', true
                            );
                        document.getElementById('chart2')
                            .setAttribute(
                                'hidden', true
                            );
                        document.getElementById('chart3')
                            .setAttribute(
                                'hidden', true
                            );
                        document.getElementById('chart4')
                            .setAttribute(
                                'hidden', true
                            );
                        setReportData(prev => ({
                            ...prev,
                            ...rd,
                            imgUri: undefined,
                            commonActiveTab: commonActiveTab
                        }));
                    }
                })

                .catch(err => {
                    console.log(`getData() => :${err}`)
                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/calculateCarbonValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    input = response;
                })

                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/waterInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    waterData = response;
                    if (response.data.length > 0) {
                        isWaterCalcExist = true;
                    } else {
                        isWaterCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/simpleInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    simpleData = response;
                    if (response.data.length > 0) {
                        isSimpleDeliveryCalcExist = true;
                    } else {
                        isSimpleDeliveryCalcExist = false;
                    }
                })

                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/plasticInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    plasticData = response;
                    if (response.data.length > 0) {
                        isPlasticCalcExist = true;
                    } else {
                        isPlasticCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/metalInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    metalData = response;
                    if (response.data.length > 0) {
                        isMetalCalcExist = true;
                    } else {
                        isMetalCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/electricalItemsInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    electricalItemsData = response;
                    if (response.data.length > 0) {
                        isElectricalItemsCalcExist = true;
                    } else {
                        isElectricalItemsCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/aviationInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    aviationData = response;
                    if (response.data.length > 0) {
                        isAviationCalcExist = true;
                    } else {
                        isAviationCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/seaFreightInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    seaFreightData = response;
                    if (response.data.length > 0) {
                        isSeaFreightCalcExist = true;
                    } else {
                        isSeaFreightCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/calculateDetailDeliveryAssumption/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    ddAssumpData = response;
                    if (response.data.length > 0) {
                        isDetailDeliveryCalcExist = true;
                    } else {
                        isDetailDeliveryCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/calculateDataCenterAssumption/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    dcAssumpData = response;
                })
                .catch(err => {

                });
        }

        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/electricityInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    electricityData = response;
                    if (response.data.length > 0) {
                        isEleCalcExist = true;
                    } else {
                        isEleCalcExist = false;
                    }
                })
                .catch(err => {

                });
        }
        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/dataCentreInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    dataCentreData = response;
                    if (response.data.length > 0) {
                        isDataCenterCalcExist = true;
                    } else {
                        isDataCenterCalcExist = false;
                    }
                })
                .catch(err => {
                });
        }
        if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/fuelInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    fuelData = response;
                    if (response.data.length > 0) {
                        isFuelCalcExist = true;
                    } else {
                        isFuelCalcExist = false;
                    }
                })
                .catch(err => {
                });
        }
       /*  if (projectId !== undefined && projectId !== '') {
            axios.get(`${basePath}/calculator/transportInputValue/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(response => {
                    transportData = response;
                    if (response.data.length > 0) {
                        isTransportCalcExist = true;
                    } else {
                        isTransportCalcExist = false;
                    }
                })
                .catch(err => {

                });
        } */
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [reload]);

    useEffect(() => {
        setGeneratedImage(true);
    }, [reportData])

    const ColoredLine = ({ color, height }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: height,
                margin: "0px"
            }}
        />
    );

    const itemWise = (data, type) => {
        if (type === "detailedDelivery") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -105} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else if (type === "plastic") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -105} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else if (type === "metal") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={7} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -200} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else if (type === "electricalItems") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={7} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -200} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else if (type === "aviation") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={7} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -200} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else if (type === "seaFreight") {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={7} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -200} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row key={`summary-${type}-${data.itemType}`} className={"summary-entry"} style={{ backgroundColor: "transparent" }} >
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <ScrollLink className="scrollLink" to={`scroll${type.charAt(0).toUpperCase() + type.slice(1)}${data.st.replace(" ", "")}${data.itemType.replace(" ", "")}`} spy={true} smooth={true} duration={500} offset={(type !== "dataCentre" && type !== "detailedDelivery") ? -111 : -105} >
                            <span className={"itemType"} style={{ backgroundColor: calculatorColor[type].color }}>{data.itemType}</span>
                        </ScrollLink>
                    </Col>
                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "0px" }}>
                        <span className="numberType">{numberWithCommas(parseFloat(data.cf).toFixed(3))}</span>
                    </Col>
                </Row>
            )
        }
    }

    let totalData;
    if (ecsSubTotal > 0) {
        totalData = (ecsSubTotal * 3) - piSubTotal
    } else if (piSubTotal > 0) {
        totalData = piSubTotal
    }

    let date = new Date();
    let year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'short' });
    let day = date.toLocaleString('default', { weekday: 'short' });
    let hour = date.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
    let dateVal = date.getDate();
    let minutes = date.getMinutes();
    let secconds = date.getSeconds();
    let timeStampPdf = day + ', ' + month + ' ' + dateVal + ', ' + year + ' ' + hour;
    const currentDate = new Date().toLocaleString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', secconds: '2-digit' });
    const exactDateTimestamp = new Date(currentDate).getTime();

    const uploadPdf = async (pdfBlob) => {
        try {
            const formData = new FormData();
            formData.append('pdf', pdfBlob, 'filename.pdf');
            formData.append('projectid', `${projectId}`)
            const response = await axios.post(`${basePath}/uploadpdf`, formData, {
                headers: {
                    authorization: `Bearer ${props.login.token}`,
                    'Content-Type': 'multipart/form-data',
                    //   'Content-Disposition': 'form-data'
                },
            });
            setShowToastUploadPdf({ state: true, header: 'Success', body: "PDF Report Saved Successfully" });
        } catch (err) {
            setShowToastUploadPdf({ state: true, header: "Error", body: "Failed to Save Report" });
        }
    };
    const handlePdfClick = (url) => {
        const arr = url.split('/');
        const fileNameFromURL = arr[arr.length - 1];
        const payload = {
            fileName: fileNameFromURL,
            projId: projectId
        };
        axios.post(`${basePath}/uploadpdf/downloadPdfFile`, payload, {
            headers: {
                authorization: `Bearer ${props.login.token}`
            }
        })
            .then(res => {
                const blobData = res.data.url
                const data = new Uint8Array(blobData.data);
                const pdfBlob = new Blob([data], { type: 'application/pdf' });
                const href = URL.createObjectURL(pdfBlob);
                const a = Object.assign(document.createElement("a"), {
                    href,
                    style: "display:none",
                    download: fileNameFromURL,
                });
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(href);
                a.remove();
            })
            .catch(err => {
                setShowToastUploadPdf({ state: true, header: "Error", body: "Failed to download Report" });
            });
    };

    const handleUploadPdf = () => {
        blobData.arrayBuffer().then(buffer => {
            const data = new Uint8Array(buffer);
            const pdfBlob = new Blob([data], { type: 'application/pdf' });
            uploadPdf(pdfBlob);
        });
    }
    const handleClick = async () => {
        try {
            const response = await axios.get(`${basePath}/uploadpdf/getPdfUrls/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            const urlList = response.data.url.map(url => url.split(','));
            if (urlList.length > 0) {
                setShowToast({
                    state: true,
                    header: `CCIC-${projectId} PDF Reports`,
                    body: (
                        <div style={{ fontSize: "13px" }}>
                            {urlList.length > 0 &&
                                urlList.map((urls, index) => (
                                    <div key={index}>
                                        {urls.map((url, subIndex) => (
                                            <div key={subIndex}>
                                                <a
                                                    target="_blank"
                                                    style={{ color: "white" }}
                                                    onClick={() =>
                                                        handlePdfClick(url)
                                                    }
                                                >
                                                    <div style={{ fontSize: '16px' }}>
                                                        {
                                                            `report_${(reportData.report.name).toString().toLowerCase().replace(" ", "_")}-
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).toLocaleString('default', { weekday: 'short' })}-
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).toLocaleString('default', { month: 'short' })}-
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).getDate()}-
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).getFullYear()}_
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).getHours()}_
                                                        ${new Date(Number(url.split('-').slice(-1)[0].split('.')[0])).getMinutes()}.pdf`
                                                        }
                                                        &nbsp;&nbsp;
                                                        <FaDownload className='url-download'></FaDownload>
                                                    </div>
                                                    <br />
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                    ),
                });
            }
            else {
                setShowToast({
                    state: true,
                    header: `CCIC-${projectId} PDF Reports`,
                    body: (<h4>No PDF Reports Found</h4>),
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {
                reportData !== null && reportData !== undefined && reportData.report !== undefined && Object.keys(reportData.report).length > 0
                    && (isFuelCalcExist || isEleCalcExist || isDataCenterCalcExist || isWaterCalcExist || isPlasticCalcExist || isMetalCalcExist/*  || isTransportCalcExist  */|| isElectricalItemsCalcExist || isAviationCalcExist || isSeaFreightCalcExist || isDetailDeliveryCalcExist || isSimpleDeliveryCalcExist || (calculators.hasOwnProperty("paper") && calculators["paper"]["cf"] > 0)) ?
                    (
                        <Card style={{ border: "0", backgroundColor: "#161616" }}>
                            <Container>
                                <Row>
                                    <Col >
                                        <div className="summary-header" style={{ color: 'black' }}>
                                            <Card.Text className="pull-right" style={{ marginBottom: "0px", marginTop: "2px", marginRight: "15px" }} ><FaArrowDown onClick={() => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })} style={{ cursor: "pointer" }} color="white" /></Card.Text>
                                            <div style={{ margin: "30px 0px" }}>
                                                <ProgressBarCustom calculators={calculators} type={"value"} projectId={projectId} key={`progressBar-${reload}`} />
                                            </div>

                                        </div>

                                        <div className="container text-right" style={{ marginTop: '1rem', color: '#f0f0f0' }}>
                                            {
                                                fuelName !== "" && Object.keys(fuel).length > 0 && calculators.hasOwnProperty("fuel") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink fuel" to="scrollFuel" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{fuelName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["fuel"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(fuel).map(k => {
                                                                return itemWise(fuel[k], "fuel");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                electricityName !== "" && Object.keys(electricity).length > 0 && calculators.hasOwnProperty("electricity") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink electricity" to="scrollElectricity" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{electricityName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["electricity"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(electricity).map(k => {
                                                                return itemWise(electricity[k], "electricity");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                waterName !== "" && Object.keys(water).length > 0 && calculators.hasOwnProperty("water") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink water" to="scrollWater" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{waterName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["water"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(water).map(k => {
                                                                return itemWise(water[k], "water");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                paperName !== "" && Object.keys(paper).length > 0 && calculators.hasOwnProperty("paper") ? (

                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink paper" to="scrollPaper" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{paperName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["paper"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(paper).map(k => {
                                                                return itemWise(paper[k], "paper");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                plasticName !== "" && Object.keys(plastic).length > 0 && calculators.hasOwnProperty("plastic") ? (

                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink plastic" to="scrollPlastic" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{plasticName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["plastic"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(plastic).map(k => {
                                                                return itemWise(plastic[k], "plastic");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                metalName !== "" && Object.keys(metal).length > 0 && calculators.hasOwnProperty("metal") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink metal" to="scrollMetal" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{metalName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["metal"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(metal).map(k => {
                                                                return itemWise(metal[k], "metal");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {/* {
                                                transportName !== "" && Object.keys(transport).length > 0 && calculators.hasOwnProperty("transport") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink transport" to="scrollTransport" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{transportName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["transport"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(transport).map(k => {
                                                                return itemWise(transport[k], "transport");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            } */}
                                            {
                                                electricalItemsName !== "" && Object.keys(electricalItems).length > 0 && calculators.hasOwnProperty("electricalItems") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink electricalItems" to="scrollElectricalItems" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{electricalItemsName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["electricalItems"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(electricalItems).map(k => {
                                                                return itemWise(electricalItems[k], "electricalItems");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                aviationName !== "" && Object.keys(aviation).length > 0 && calculators.hasOwnProperty("aviation") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink aviation" to="scrollAviation" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{aviationName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["aviation"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(aviation).map(k => {
                                                                return itemWise(aviation[k], "aviation");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                seaFreightName !== "" && Object.keys(seaFreight).length > 0 && calculators.hasOwnProperty("seaFreight") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink seaFreight" to="scrollSeaFreight" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{seaFreightName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["seaFreight"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(seaFreight).map(k => {
                                                                return itemWise(seaFreight[k], "seaFreight");
                                                            })
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                dataCentreName !== "" && Object.keys(dataCentre).length > 0 && calculators.hasOwnProperty("dataCentre") ? (
                                                    <>
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink dataCentre" to="scrollDataCentre" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{dataCentreName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["dataCentre"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(dataCentre).map(k => {
                                                                return itemWise(dataCentre[k], "dataCentre");
                                                            })
                                                        }
                                                        {
                                                            ecsSubTotal < 0 ? (<>
                                                                <Row className={"summary-entry-header"}>
                                                                    <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                        <span>Sub Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >({ecsSubTotal > 0 ? `carbon consumption` : `carbon emission`} )</span></span>
                                                                    </Col>
                                                                </Row>
                                                                <ColoredLine color="#f0f0f0" height={0.5} />
                                                                <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                                    <Col style={{ paddingRight: "13px" }}>
                                                                        <p style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{numberWithCommas(parseFloat(-1 * ecsSubTotal).toFixed(3))}</p>
                                                                        <p style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / year</p>
                                                                    </Col>
                                                                </Row>
                                                            </>) : (<></>)
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                ((calculators.hasOwnProperty("fuel") && calculators["fuel"]["cf"] > 0) || (calculators.hasOwnProperty("electricity") && calculators["electricity"]["cf"] > 0) || (calculators.hasOwnProperty("dataCentre") && calculators["dataCentre"]["cf"] > 0) || (calculators.hasOwnProperty("water") && calculators["water"]["cf"] > 0) || (calculators.hasOwnProperty("paper") && calculators["paper"]["cf"] > 0) || (calculators.hasOwnProperty("plastic") && calculators["plastic"]["cf"] > 0) || (calculators.hasOwnProperty("metal") && calculators["metal"]["cf"] > 0)
                                                 || /* (calculators.hasOwnProperty("transport") && calculators["transport"]["cf"] > 0) || */ (calculators.hasOwnProperty("electricalItems") && calculators["electricalItems"]["cf"] > 0) || (calculators.hasOwnProperty("aviation") && calculators["aviation"]["cf"] > 0) || (calculators.hasOwnProperty("seaFreight") && calculators["seaFreight"]["cf"] > 0)) && (ecsSubTotal > 0) ? (
                                                    <>  { reportData.report.noOfYears > 1 ?
                                                        <>
                                                            <Row className={"summary-entry-header"}>
                                                                <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                    <span>Sub Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >(carbon savings)</span></span>
                                                                </Col>
                                                            </Row>
                                                            <ColoredLine color="#f0f0f0" height={0.5} />
                                                            <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                                <Col style={{ paddingRight: "13px" }}>
                                                                    <p style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{numberWithCommas(parseFloat(ecsSubTotal).toFixed(3))}</p>
                                                                    <p style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / year</p>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        : null }
                                                        {reportData.report.noOfYears == null ?
                                                            <>
                                                                <Row className={"summary-entry-header"}>
                                                                    <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                        <span>Gross Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >(carbon savings)</span></span>
                                                                    </Col>
                                                                </Row>
                                                                <ColoredLine color="#f0f0f0" height={0.5} />
                                                                <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                                    <Col style={{ paddingRight: "13px" }}>
                                                                        <p data-tip data-for='netTotal' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{numberWithCommas(parseFloat((1) * ecsSubTotal).toFixed(3))}</p>
                                                                        <p data-tip data-for='netTotal' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / year</p>
                                                                        {/* <ReactTooltip id="netTotal" className='customCalculatorTooltip' place="top" offset={{ top: -5 }} clickable={true} arrowColor="#000000">
                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "300px" }}>
                                                                        <p>Capgemini can recognize any client carbon savings for 3 years. For more information please contact the <a href="https://x-port.capgemini.com/carbon-impact-solution-group-cisg/" target="_blank">Carbon Impact Solutioning Group</a></p>
                                                                    </div>
                                                                </ReactTooltip> */}
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <>
                                                                <Row className={"summary-entry-header"}>
                                                                    <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                        <span>Gross Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >(carbon savings)</span></span>
                                                                    </Col>
                                                                </Row>
                                                                <ColoredLine color="#f0f0f0" height={0.5} />
                                                                <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                                    <Col style={{ paddingRight: "13px" }}>
                                                                        <p data-tip data-for='netTotal' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{numberWithCommas(parseFloat((reportData.report.noOfYears) * ecsSubTotal).toFixed(3))}</p>
                                                                        <p data-tip data-for='netTotal' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / {reportData.report.noOfYears} years</p>

                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                peopleName !== "" && Object.keys(people).length > 0 ? (
                                                    <>
                                                        <br />
                                                        <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink delivery" to="scrollDelivery" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>{peopleName}</span><span style={{ padding: "3px", backgroundColor: calculatorColor["delivery"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(people).map(k => {
                                                                return itemWise(people[k], "delivery");
                                                            })
                                                        }
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                <span>Sub Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >(delivery emission)</span></span>
                                                            </Col>
                                                        </Row>
                                                        <ColoredLine color="#f0f0f0" height={0.5} />
                                                        <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                            <Col style={{ paddingRight: "13px" }}>
                                                                <p data-tip data-for='subTotalSimple' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-piSubTotal).toFixed(3)) : numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</p>
                                                                <p data-tip data-for='subTotalSimple' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / project duration</p>
                                                                {ecsSubTotal > 0 ? <><ReactTooltip id="subTotalSimple" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000"><p>This number is negative as it represent the estimated CO<sub>2</sub>e emitted by the Capgemini team during the project delivery. </p> </ReactTooltip></> : <></>}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                detailedDeliveryName !== "" && Object.keys(detailedDelivery).length > 0 ? (
                                                    <>
                                                        <br />
                                                        <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                            <Col style={{ paddingRight: "0px" }}>
                                                                <ScrollLink className="scrollLink detailedDelivery" to="scrollDetailedDelivery" spy={true} smooth={true} duration={500} offset={-50} >
                                                                    <span style={{ padding: "10px" }}>Detailed Project Impact ({commonActiveTab[0].toUpperCase() + commonActiveTab.slice(1)})</span><span style={{ padding: "3px", backgroundColor: calculatorColor["detailedDelivery"].color }}></span>
                                                                </ScrollLink>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Object.keys(detailedDelivery).map(k => {
                                                                detailCf = detailedDelivery;
                                                                return itemWise(detailedDelivery[k], "detailedDelivery");
                                                            })
                                                        }
                                                        {/* <br /> */}
                                                        <Row className={"summary-entry-header"}>
                                                            <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                                <span>Sub Total<span style={{ marginLeft: "10px", fontSize: "10px" }} >({`${commonActiveTab} delivery emission`})</span></span>
                                                            </Col>
                                                        </Row>
                                                        <ColoredLine color="#f0f0f0" height={0.5} />
                                                        <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                            <Col style={{ paddingRight: "13px" }}>
                                                                <p data-tip data-for='subTotal' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px" }} className="numberType">{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-piSubTotal).toFixed(3)) : numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</p>
                                                                <p data-tip data-for='subTotal' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / project duration</p>
                                                                {ecsSubTotal > 0 ? <><ReactTooltip id="subTotal" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000"><p>This number is negative as it represent the estimated CO<sub>2</sub>e emitted by the Capgemini team during the project delivery. </p> </ReactTooltip></> : <></>}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (<></>)
                                            }
                                            <br />
                                            <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                <Col style={{ paddingRight: "13px", textAlign: "left" }}>
                                                    <span>{ecsSubTotal > 0 ? `Net Total  (carbon savings)` : `Net Total  (carbon emission)`}</span>
                                                </Col>
                                            </Row>
                                            <ColoredLine color="#f0f0f0" height={0.5} />
                                            <Row className={"summary-entry-header"} style={{ marginTop: "0px" }}>
                                                <Col style={{ paddingRight: "13px" }}>
                                                    {reportData.report.noOfYears == null ?
                                                        <p data-tip data-for='totalCF' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px", fontWeight: "bold" }} className="numberType">{ecsSubTotal > 0 ? numberWithCommas(parseFloat((ecsSubTotal * (1)) - piSubTotal).toFixed(3)) : numberWithCommas(parseFloat(-1 * (ecsSubTotal - piSubTotal)).toFixed(3))}</p>
                                                        :
                                                        <p data-tip data-for='totalCF' style={{ margin: "0px", fontSize: "20px", lineHeight: "20px", fontWeight: "bold" }} className="numberType">{ecsSubTotal > 0 ? numberWithCommas(parseFloat((ecsSubTotal * (reportData.report.noOfYears)) - piSubTotal).toFixed(3)) : numberWithCommas(parseFloat(-1 * (ecsSubTotal - piSubTotal)).toFixed(3))}</p>
                                                    }
                                                    {ecsSubTotal > 0 ?
                                                        <>
                                                            {reportData.report.noOfYears == null ?
                                                                <p data-tip data-for='totalCF' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / year</p>
                                                                :
                                                                <p data-tip data-for='totalCF' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e / {reportData.report.noOfYears} years</p>
                                                            }
                                                        </>
                                                        :
                                                        <p data-tip data-for='totalCF' style={{ fontSize: "8px", fontWeight: "normal", margin: "0px" }}>tonnes CO<sub>2</sub>e</p>
                                                    }
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "300px" }}>
                                                        {
                                                            totalData === piSubTotal ? <><ReactTooltip id="totalCF" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000"><p><b>No client savings - only delivery impact calculated</b> <br /> This number represents the estimated CO<sub>2</sub>e emitted by the <br /> Capgemini team during the project delivery. </p> </ReactTooltip></> :
                                                                <>{totalData < 0 ? <><ReactTooltip id="totalCF" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000"><p>This value is negative as although the project is estimating a client saving, the estimated emission from delivering the project is greater than the saving. </p>  </ReactTooltip></> :
                                                                    <></>}
                                                                </>
                                                        }
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            generatedImage === true ? (
                                                <>
                                                    <PDFDownloadLink document={<CreatePDF timeStampPdfVal={timeStampPdf} data={reportData} fuelInput={fuelData} ddAssumpData={ddAssumpData} dcAssumpData={dcAssumpData} characterdetails={characterdata} initialimpacttonnes={initialtonnes} targetimpacttonnes={targettonnes} reductiontoinitialtargetimpact={initialtargetimpact} actualimpacttonnes={actualtonnes} reductiontoinitial={comparedtoinitial} reductiontotarget={comparedtotarget} carboninput={input} waterInput={waterData} plasticInput={plasticData} metalInput={metalData} 
                                                   /*  transportInput={transportData}  */electricalItemsInput={electricalItemsData} electricityInput={electricityData} aviationInput={aviationData} seaFreightInput={seaFreightData} dataCentreInput={dataCentreData} simpleInput={simpleData} detailCf={detailCf} />} fileName={`report_${(reportData.report.name).toString().toLowerCase().replace(" ", "_")}-${timeStampVal}.pdf`}>
                                                        {({ blob, url, loading, error }) => (loading ?
                                                            (
                                                                <button type="button" className="summary btn btn-primary text-center" style={{ width: "100%", border: "0px", borderRadius: "0px", backgroundColor: "#525252", borderColor: "#525252" }}>
                                                                    Generating Report...
                                                                </button>
                                                            ) :
                                                            (
                                                                <div >
                                                                    <button data-tip data-for="downloadReport" type="button" className="summary btn btn-primary text-center"
                                                                        style={{ width: "100%", border: "0px", borderRadius: "0px", backgroundColor: "#525252", borderColor: "#525252" }}>
                                                                        Download Report &nbsp;<FaDownload />
                                                                    </button>
                                                                    <ReactTooltip id="downloadReport" className='customCalculatorTooltip' place="top" offset={{ top: -5 }} clickable={true} arrowColor="#000000">
                                                                        To download report on your local system
                                                                    </ReactTooltip>
                                                                    <div>{(blobData = blob) && false}</div>
                                                                </div>
                                                            )
                                                        )}
                                                    </PDFDownloadLink>
                                                </>
                                            ) : (
                                                <button type="button" className="summary btn btn-primary text-center" style={{ width: "100%", border: "0px", borderRadius: "0px", backgroundColor: "#525252", borderColor: "#525252" }}>
                                                    Generating Report...
                                                </button>
                                            )
                                        }

                                    </Col>
                                </Row>
                                <br />
                                <div>
                                    <Row>
                                        <Col>
                                            <div>
                                                <button data-tip data-for="savePdf" type="button" className="summary btn btn-primary text-center"
                                                    style={{ width: "100%", border: "0px", borderRadius: "0px", backgroundColor: "green", borderColor: "#525252" }}
                                                    onClick={handleUploadPdf}>Save PDF Report &nbsp; <FaSave />
                                                </button>
                                                <ReactTooltip id="savePdf" className='customCalculatorTooltip' place="top" offset={{ top: -5 }} clickable={true} arrowColor="#000000">
                                                    To save current PDF version & later available in 'Show PDF Revisions' popup
                                                </ReactTooltip>
                                            </div>
                                            <ToastCustom variant="bw" onClose={() => setShowToastUploadPdf({ state: false, header: "", body: "" })} show={showToastUploadPdf.state} header={showToastUploadPdf.header} body={showToastUploadPdf.body} autoHide={true} />
                                        </Col>
                                    </Row>
                                </div>
                                <br />
                                <div>
                                    <Row>
                                        <Col>
                                            <div>
                                                <button data-tip data-for="showPdf" type="button" className="summary btn btn-primary text-center"
                                                    style={{ width: "100%", border: "0px", borderRadius: "0px", backgroundColor: "#7444DA", borderColor: "#525252" }}
                                                    onClick={handleClick}>Show PDF Revisions &nbsp; <FaList />
                                                </button>
                                                <ReactTooltip id="showPdf" className='customCalculatorTooltip' place="top" offset={{ top: -5 }} clickable={true} arrowColor="#000000">
                                                    It shows Saved PDF versions lists
                                                </ReactTooltip>
                                            </div>
                                            <ToastCustomPDF variant="bw" onClose={() => setShowToast({ state: false, header: "", body: "" })} show={showToast.state} delay={35000} header={showToast.header} body={showToast.body} autoHide={false} />

                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Card >
                    ) : (
                        <></>
                    )
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.login,
    project: state.project,
    master: state.master
})

export default connect(mapStateToProps)(SummaryComponent)