import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row, Col, CardDeck } from "react-bootstrap";
import { connect } from "react-redux";
import Card from "../../components/card";
import {

    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import VisibilitySensor from 'react-visibility-sensor';
import aboutimage from '../../assets/images/aboutimage.png';
import aboutImage1 from '../../assets/images/aboutimage1.png';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactPlayer from "react-player";
import "./Home.scss";
import { clearSession } from "../../store/actions/loginActions/clearSessionAction";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { unsetCurrentProject } from "../../store/actions/projectActions/projectAction";

const sasBlobStorage = process.env.REACT_APP_API_BLOB_STORAGE_SAS_DEV;
const basePath = process.env.REACT_APP_API_BLOB_STORAGE_BASE_PATH;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });
    return isMountedRef;
}

const Home = (props) => {

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (isMountedRef.current) {
            props.unsetCP();
        }
    }, []);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.login.sessionExpired) {
                props.clearSession();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.login.sessionExpired, isMountedRef]);

    return (
        <>
            {
                props.login.isValidUser ? (
                    <React.Fragment>
                        <Container style={{ width: "90%" }}>
                            <Row style={{ textAlign: "left" }}>
                                <Col sm={12} md={12} lg={12} style={{ paddingLeft: "15px", marginBottom: "-12px" }}>
                                    <span style={{ fontSize: "52px", fontWeight: "bold", letterSpacing: "1.333px" }}>Client Carbon Impact</span>
                                </Col>
                                <Col sm={12} md={12} lg={12} style={{ paddingLeft: "15px" }}>
                                    <span style={{ fontSize: "52px", fontWeight: "normal", letterSpacing: "1.333px", fontFamily: "Ubuntu Mono" }}>Calculator</span>
                                </Col>
                                <Col sm={12} md={12} lg={12} style={{ paddingLeft: "17px" }}>
                                    <span style={{ fontFamily: "Ubuntu Light", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)", lineHeight: "49px", letterSpacing: "0.216px" }} >
                                        Helping Capgemini achieve its commitment in helping our clients save 10 million tonnes of CO2e by 2030.
                                    </span>
                                </Col>
                            </Row>

                            <CardDeck style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", margin: 0, marginRight: "8px", marginTop: 50, justifyContent: "space-between" }}>
                                <Card key={"cleint-new-project"} id={"client-new-project"} title="Client Carbon Impact" subTitle="Calculate how much carbon we can save for our clients through the project we deliver" style={{ cursor: "pointer", height: "130px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} onClick={() => props.history.push("/calculator")} isCalculatorTypeButton={false} clickable={true} />
                                <Card key={"delivery-new-project"} id={"delivery-new-project"} title="Delivery Carbon Impact" subTitle="Calculate the carbon impact from the Capgemini delivery team" style={{ cursor: "pointer", height: "130px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} onClick={() => props.history.push({ pathname: "/calculator", state: { calculatorType: "detailedDelivery" } })} isCalculatorTypeButton={false} clickable={true} />
                                <Card key={"my-projects"} id={"my-projects"} title="My Projects" subTitle="View all my projects" style={{ cursor: "pointer", height: "130px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} onClick={() => props.history.push("/projects")} isCalculatorTypeButton={false} clickable={true} />
                                <Card key={"blank1"} type="homeCard" style={{ height: "130px", flex: "1 0 350px", border: 0, background: "#161616", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={false} />
                            </CardDeck>

                            <div style={{ marginTop: 70, textAlign: "left" }}>
                                <div style={{ borderBottomWidth: 1, borderBottomColor: "rgba(255, 255, 255, 0.87)", borderBottomStyle: "solid", fontWeight: "bold", marginLeft: "21px", paddingLeft: "21px", paddingBottom: "10px" }}>
                                    More Information
                                </div>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Capgemini Sustainability Ambitions
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>The greatest environmental impact we can have is through the services we deliver to our clients. We have an ambition to help our clients save 10 million carbon tonnes by 2030.

                                            </p>

                                            <p>Companies around the world are investing heavily in minimizing their environmental impacts and seeking to be more resource efficient. Increasingly clients are asking us about the carbon impacts of the services we provide, and the potential for technology to help them address their sustainability challenges.</p>

                                            <p>As a sustainable business, we want to play a leadership role in ensuring technology creates a sustainable future, particularly enabling our clients to be smarter about their resources in the products and services they create. The ICT sector has the potential to cut 9.7 times more greenhouse gases than it produces and our position as a leading technology services provider working with some of the world’s largest companies puts us in a unique position to leverage all our capabilities, expertise and solutions to help our clients.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Introduction to the Client Carbon Impact Calculator
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>As part of our action on climate change, we have set out a global ambition to work collaboratively with clients to help them save 10 million tonnes of carbon emissions by 2030.

                                                Critical to being able to deliver this aim, is giving our client teams the ability to easily calculate the carbon impact of the projects and services we are providing. To achieve this, today we are launching our Client Carbon Impact Calculator.</p>

                                            <p>The calculator provides an adaptable framework to enable the measurement of the carbon impacts across a broad range of sectors and projects. In addition, it also assesses the impacts of our project delivery teams to create an authentic estimate of the overall impact.

                                                Capgemini’s global Environmental Sustainability program in conjunction with the London and Mumbai AIEs have developed and trialled the calculator over the past months in collaboration with our global sales team.</p>

                                            <p><img style={{ width: "100%", marginTop: "15px" }} src={aboutimage} alt={"Capgemini"} /></p>
                                            <p><img width="100%" src={aboutImage1} alt={"Capgemini"} /></p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Using the calculator
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                <span>Please visit the <Link to="/about" style={{ textDecoration: "underline", color: "#8b8be8" }}>About</Link> page to find more details about how to use each calculator</span>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                    <AccordionItem uuid="d">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Carbon Calculator FAQ
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                                <AccordionItem uuid="d1">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            About the tool
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p><b>What is the Carbon Calculator?</b><br></br>The Client Carbon Impact Calculator, or Carbon Calculator for short, is a group tool developed and managed by the Capgemini Sustainably Team. It enables anyone in the business to calculate the carbon impact of any engagement. This includes the negative impact of Capgemini employees working on a project and potential carbon savings we can help our clients achieve because of our projects.  </p>

                                                        <p><b>When should it be used?</b><br></br>The tool is intended to be used during the sales stage of any new deal although it can also be used retrospectively if required.  </p>

                                                        <p><b>Who should use it?</b><br></br>The tool can be accessed and used by anyone with a Capgemini email via SSO. The primary user group includes anyone working on a proposal.  </p>

                                                        <p><b>Who owns the tool?</b><br></br>The tool was developed and is managed by the AIE & AMS teams in India and the UK and is owned by the group sustainability team under the leadership of James Robey.  </p>

                                                        <p><b>Is this a product we can sell?</b><br></br>The Carbon Calculator has been built as an internal tool for Capgemini and not as a product to be sold to clients. However, should your client express interest in a similar tool, and many have, please speak to your local sustainably contract for more information about how to approach this topic.  </p>

                                                        <p><b>Where does the methodology come from?</b><br></br>The Carbon Calculator uses methodologies developed by DEFRA, a UK government agency, and complies to agreed guidelines for carbon calculations.  </p>

                                                        <p><b>Can we guarantee these numbers?</b><br></br>No. All of the numbers in the calculator are estimates and should be communicated as such per the legal text available. This text can be found on the legal tab or on the downloaded report. The calculator uses several assumptions and is only as accurate as the data entered. For any questions on this please contact your local sustainability point of contact.  </p>



                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>


                                            <AccordionItem uuid="d2">
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Carbon Calculator Calculations
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p><b>Why am I required to enter project data to use the calculator?</b><br></br>The project data is used for reporting and audit purposed by Capgemini sustainably teams.</p>

                                                    <p><b>How to Create a Carbon Calculation?</b><br></br>To create a carbon calculation, select ‘Client Carbon Impact’ on the home page. This will start a new calculator where you will be asked to enter some project data. Once entered press save and select the type of calculation from the list of available calculators.</p>

                                                    <p><b>Which calculator should I use?</b><br></br>The calculator currently has six different calculators available, with new ones expected to be added in future releases. Which calculator to use will depend on the needs of your project  but as a guide, the ‘Simple Delivery Impact’ & and ‘Detailed Project Impact’ calculators are relevant to all projects. These two calculators consider the impact of Capgemini employees delivering the work.</p>

                                                    <p>The other calculators (fuel, electricity, water, data centre) should only be used to calculate any carbon impact on our client because of Capgemini delivering a project. An example would be Capgemini delivering a route optimisation software for a transport company. In this case the Land Transport calculator would be used to work out any potential savings the project will deliver for the client.</p>

                                                    <p><b>I have filled in either the ‘Simple Delivery Impact’ or Detailed Project Impact’ calculator, do I need to fill in anything else?</b><br></br>In most cases the answer will be no. You only need to fill in the other calculators if there is a carbon impact to the client based on the work we deliver. If there is no fuel, electricity, water or data centre charge for the client you do not need to fill anything else in. Energy used to deliver the project such as fuel to drive to the office is already captured in the delivery calculator.</p>

                                                    <p><b>What is the difference between the ‘Simple Delivery Impact’ and ‘Detailed Project Impact’ calculators?</b><br></br>Both look at the impact of Capgemini delivering a project but go into different amounts of detail. It is recommended that you use the ‘Detailed Project Impact’ calculator when possible as it provides more granular information to allow the project take actions to reduce the deliver impact.</p>

                                                    <p><b>Where do I get the data from for the fuel/electricity/water calculator?</b><br></br>This data can come from several sources including those set out by the client in RFI/FRP document, scope documents, during requirement gathering session or from speaking directly to clients or SMEs who have delivered similar projects before. Not every client will have considered this or have this information available. In these instances, asking the question has the potential to open new conversations and demonstrate Capgemini’s commitment to working with our clients to reduce carbon.</p>

                                                    <p><b>Do I enter positive or negative figures?</b><br></br>You can enter both. A positive indicates a saving. For example, if a company used 100L of fuel before and because of the project outcome estimate they will use 60L in the future, the saving would be 40L.  There is occasion where the project might have a negative impact, for example increase fuel use, in which case you can enter a negative number. </p>

                                                    <p><b>I can’t save my calculation, what should I do?</b><br></br>Firstly, check if you have saved the project details. There is a save at the top of the page. If the project details are saved you will see your name. Once that is done, please try saving your calculation again. If this still does not work please contact the support team.  </p>




                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem uuid="d3">
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Detailed Project Impact
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p><b>What do the three tabs (initial, target, actual) mean?</b><br></br>The initial tab is the starting point for any project and assumes we make no effort to reduce our emissions. In a pre-pandemic world this would mean minimal or no hybrid working with consultants traveling to client offices on a regular basis. Even though travel is unlikely to return to pre-pandemic quickly, there is an increased expectation from clients to travel more which be reflect in this tab.</p>

                                                    <p>The target tab is used to identify potential carbon savings based on the proposed project delivery. In this tab users can test different delivery options, including different modes of transport to reduce carbon emissions. The comparison between initial and target has in many cases been presented to clients as part of a proposal. This results in a positive conversation around reducing carbon emission from project delivery.</p>

                                                    <p>The actual tab should be used at the end of a project to reflect what actually happened.</p>

                                                    <p><b>Where does that date for client/Capgemini office and working from home come from?</b><br></br>Office data is country specific and is based on the average emissions from all offices within a given country. Working from home is based on an average value for a person in the UK working from home. This figure is constantly being reviewed and should only be seen as an estimate, as home conditions differ.</p>

                                                    <p><b>How do I work out the hours of flights/train?</b><br></br>Consider what routes the team is likely to travel during the project and how often. For example, a one-way flight from New York to San Francisco take roughly 6.5 hours, so if two members of the project teams needs to make this trip 5 times during the project the total hours of flight time would be 130 hours. Remember this is an estimate based on your current knowledge of the project.</p>

                                                    <p><b>What is a fast train?</b><br></br>Examples of fast trains would be the TGV in France, ICE in Germany or the Bullet Train in Japan. If you are unsure, please use ‘average train’.</p>

                                                    <p><b>Do I need to enter anything under public transport or individual car?</b><br></br>Yes, currently the tool requires you to enter something here even if your project is fully removed. Please enter the lowers value possible, 0.1. Work is being done to update this for future release.</p>

                                                    <p><b>What do the red lines mean?</b><br></br>Red lines mean some information is missing. For example, the percentages might not add up to 100%. Please either enter a value or adjust the others to correct this.</p>

                                                    <p><b>What is soft transport?</b><br></br>Soft transport is any transport that does not use an engine such as walking, cycling, or even running to work.</p>

                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem uuid="d4">
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Data Outputs
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p><b>Why is my value negative?</b><br></br>A negative value indicates that the project has no saving to the client. The most common example of this is if you only fill in the delivery calculator (this will be most projects). In this case our teams will produce carbon as part of the delivery, hence the negative value.  </p>

                                                    <p>A negative value should not be seen a bad thing, but a recognition that there is an impact of us deliver work. The important aspect is to consider what we can do to reduce this impact.</p>

                                                    <p>If the project also helps the client save carbon, for example from a cloud migration, the ‘total carbon saving’ line is the difference between the positive impact of the cloud migration against the negative impact of our teams delivering the work.</p>

                                                    <p><b>Why are any savings considered for 3 years?</b><br></br>Capgemini recognise any carbon savings we help our clients achieve for 3 years. Any longer than that it is likely further improvements have been implemented. For any question around this please contact your local sustainability team. </p>

                                                    <p><b>What does the carbon figure mean and what do I do now?</b><br></br>Every project depending on its scope and size will either have a negative or positive carbon statement. To most people this figure means very little so to help put it into perspective here are a few examples:

                                                        <br></br>A return flight London to Boston emits about one tone of carbon per passenger

                                                        <br></br>An average car in the US emits about 5 tonnes or carbon per year

                                                        <br></br>The average emissions of a person living in Belgium is eight tonnes per year compared to 13 in the UK

                                                        <br></br>To capture one tonne of carbon 50 trees must grow for one year

                                                        <br></br>One tonne of carbon is the same as 125m3 of cola</p>

                                                    <p>Having calculated the impact it is important to consider what can be done to reduce the emissions. This could be a conversation with our clients about reducing the number of days in their office or encouraging people to take the train instead of flying (when viable). If delivering a cloud migration this could involve speaking to the CTO about low carbon hosting, exploring low code option or auto shut down of system when not in use. </p>

                                                    <p>There is not one answer to what to do with the data and no one is expected to be a sustainably expert. The important first step is that we calculate it and stop to consider what we can do differently. Within the Capgemini Group we have sustainably experts who will be happy to help.</p>



                                                </AccordionItemPanel>
                                            </AccordionItem>





                                            <AccordionItem uuid="d5">
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        Need Support
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p><b>Where can I find more information?</b><br></br>
                                                    <p>You can find more information about each calculator, including a video example, on the about page. For more information please visit the <br></br> <a href="https://x-port.capgemini.com/carbon-impact-solution-group-cisg/" target="_blank" style={{ fontSize: "13px", color: "#FFFFFF" }}><u>Carbon Impact Solution Group</u></a> page and if you require support please use the  <a href=" https://forms.office.com/pages/responsepage.aspx?id=Wq6idgCfa0-V7V0z13xNYTCeeL7jB-FMrulVSv7B4GxUQ0I1UlI5WDlETUtKS0xTOUNOTE5VNVlFQyQlQCN0PWcu" target="_blank" style={{ fontSize: "13px", color: "#FFFFFF" }}><u>request form</u></a> to raise a support ticket.</p>

                                                    {/* You can find more information about each calculator, including a video example, on the about page. You can also reach out to your local sustainability team for more support. */}
                                                    </p>

                                                    <p><b>I want to calculate the savings for something else not available in the calculator, what should it do?</b><br></br>The tool is constantly being improved based on your feedback and needs. Please reach out to your local sustainably team who can help you with other calculations and include your requirements into future release.  </p>


                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </AccordionItemPanel>
                                    </AccordionItem>




                                    <AccordionItem uuid="e">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Need help?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {/* <p>
                                                If you have any questions about the calculator, how it works and how the best use these numbers with your clients, please contact Matt Bradley (<span><a href="mailto:matthew.bradley@capgemini.com" style={{ color: "inherit" }}>matthew.bradley@capgemini.com</a></span>)
                                        </p>
                                            <p>
                                                For any technical questions please contact Johan Lonn (<span><a href="mailto:johan.lonn@capgemini.com" style={{ color: "inherit" }}>johan.lonn@capgemini.com</a></span>)
                                        </p> */}                                           
                                            <p>
                                                For more information please visit the <a href="https://x-port.capgemini.com/carbon-impact-solution-group-cisg/" target="_blank" style={{ fontSize: "13px", color: "#FFFFFF" }}><u>Carbon Impact Solution Group</u></a> page. If you require support with calculations please use the <a href="https://forms.office.com/pages/responsepage.aspx?id=Wq6idgCfa0-V7V0z13xNYTCeeL7jB-FMrulVSv7B4GxUQ0I1UlI5WDlETUtKS0xTOUNOTE5VNVlFQyQlQCN0PWcu" target="_blank" style={{ fontSize: "13px", color: "#ffffff" }}><u>request from</u></a> and a member of the team will be in contact. Should you have any feedback please email (<span><a href="mailto: carbonimpactsolutiongroup.global@capgemini.com" style={{ color: "#ffffff" }} ><u>carbonimpactsolutiongroup.global@capgemini.com</u></a></span>).
                                            </p>

                                        </AccordionItemPanel>
                                    </AccordionItem>


                                </Accordion>




                            </div>
                        </Container>
                    </React.Fragment>
                ) : (<div>Loading...</div>)
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSession: () => {
            dispatch(clearSession());
        },
        unsetCP: () => {
            dispatch(unsetCurrentProject());
        }
    }
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps, mapDispatchToProps)(withRouter(Home)));