import React from 'react';
import ReactTooltip from "react-tooltip";
import "./ProgressBarCustom.scss";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const calculatorColor = {
    fuel: { name: "Land Transport", color: "#F99500" },
    electricity: { name: "Electricity", color: "#FFFF00" },
    paper: { name: "Paper", color: "#FFFFFF" },
    plastic: { name: "Plastic", color: "#40A0B5" },
    metal: { name: "Metal", color: "#d7dbdc" },/* 
    transport: { name: "Transport", color: "#f37d7d" }, */
    electricalItems: { name: "Electrical Items", color: "#228C22" },
    aviation: { name: "Aviation", color: "#6f91ad" },
    seaFreight: { name: "Sea Freight", color: "#009dc4" },
    dataCentre: { name: "Data Centre", color: "#66FFCC" },
    waste: { name: "Waste", color: "#40A0B5" },
    delivery: { name: "Delivery", color: "#7444DA" },
    people: { name: "Delivery", color: "#7444DA" },
    water: { name: "Water", color: "#148AFF" },
    detailedDelivery: { name: "Detailed Delivery", color: "#7444DA" },
}

const ProgressBarCustom = (props) => {
    const {  type, height, projectId } = props;
    let totalCF = 0;
    const calculatorspre = props.calculators;
    const { transport, ...calculators } = calculatorspre;

    //console.log(calculators);
    Object.keys(calculators).forEach(function (k, i) {
        totalCF += Math.abs(calculators[k].cf);
    });
    let bars = Object.keys(calculators).map((k, i) => {
        if (type === "equal") {
            return (
                <div className="bar" style={{ backgroundColor: calculatorColor[k].color, width: (100 / Object.keys(calculators).length) + '%', height: (height !== undefined && height !== null && height !== "") ? height : "15px" }} key={`progressbar-div-${projectId}-${k}-${i}`}>
                </div>
            )
        } else {
            if (Math.abs(calculators[k].cf) > 0) {
                return (
                    <React.Fragment key={`progressbar-div-${projectId}-${k}-${i}`}>
                        <div className="bar" data-tip data-for={`calcHover-${projectId}-${k}-${i}`} style={{ backgroundColor: calculatorColor[k].color, width: ((Math.abs(calculators[k].cf) / totalCF) * 100) + '%', height: (height !== undefined && height !== null && height !== "") ? height : "15px" }} key={`progressbar-div-${projectId}-${k}-${i}`}>
                        </div>
                        <ReactTooltip id={`calcHover-${projectId}-${k}-${i}`} className='customTooltip' place="bottom" >
                            <div style={{ textAlign: "left" }}>
                                <p style={{ fontSize: "12px", fontWeight: "normal", marginBottom: "0px", bottom: "0px" }}>
                                    <span>{calculatorColor[k].name}</span>
                                </p>
                                <p style={{ fontSize: "20px", fontWeight: "normal", marginBottom: "-5px", bottom: "0px" }}>
                                    <span className="numberType">
                                        {Math.abs(calculators[k].cf) > 0 ? ((calculatorColor[k].color === "#7444DA") ? numberWithCommas(parseFloat(-calculators[k].cf).toFixed(3)) : numberWithCommas(parseFloat(calculators[k].cf).toFixed(3))) : "0.0"}
                                    </span>
                                </p>
                                <p style={{ fontSize: "8px", fontWeight: "normal", marginBottom: "5px" }}>
                                    <span>tonnes of CO<sub>2</sub>e</span>
                                </p>
                            </div>
                        </ReactTooltip>
                    </React.Fragment>
                )
            } else {
                return false;
            }
        }

    });

    return (
        <div className="multicolor-bar">
            <div className="bars">
                {bars === '' ? '' : bars}
            </div>
        </div>
    )
}

export default ProgressBarCustom;