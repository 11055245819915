import React, { useState, useReducer, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./FuelTabs.css";
import CollapseFuelConsumptionCustom from "./CollapseFuelConsumptionCustom";
import CollapseDistanceCustom from "./CollapseDistanceCustom";
import NewCollapseDistanceCustom from "./NewCollapseDistanceCustom"
import CollapseFuelCostCustom from "./CollapseFuelCostCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { deleteConfirm } from "../deleteConfirm";
import FuelAssumption from "./FuelAssumption";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

let SELECT_NOV_OPTIONS = [{ value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }, { value: "annually", label: "Annually" }];

const INITIAL_COLLAPSE_STATE = {
    diesel: { parent: "", cf: {}, vehicleType: [], vehicleType1: [], status: false },
    petrol: { parent: "", cf: {}, vehicleType: [], vehicleType1: [], status: false },
    cng: { parent: "", cf: {}, vehicleType: [], vehicleType1: [], status: false },
    electric: { parent: "", cf: {}, vehicleType: [], vehicleType1: [], status: false },
    lpg: { parent: "", cf: {}, vehicleType1: [], status: false },
    hybrid: { parent: "", cf: {}, vehicleType1: [], status: false }
};

const ladenOptions = {
    "0%": { value: "0%", label: "0%" },
    "50%": { value: "50%", label: "50%" },
    "100%": { value: "100%", label: "100%" },
    "average": { value: "average", label: "Average" },
    "other": { value: "other", label: "Other" }
};

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: "", cf: {}, vehicleType: [], status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            delete state[action.payload.name];
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_VEHICLE_TYPE":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], vehicleType: action.payload.vehicleType }
            };
            break;
        case "COLLAPSE_SET_VEHICLE_TYPE1":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], vehicleType1: action.payload.vehicleType1 }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var primaryCountry;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();
var defaultCostPrice = 1.36;

const FuelTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    primaryCountry = props.project.currentProjectPrimaryCountry;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, INITIAL_COLLAPSE_STATE);
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [fuelSourceType, setFuelSourceType] = useState({});
    const [fuelType, setFuelType] = useState({});
    const [unitSelectOptions, setUnitSelectOptions] = useState([]);
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [vehicleType, setVehicleType] = useState({});
    const [vehicleType1, setVehicleType1] = useState({});
    const [clickedSave, setClickedSave] = useState(false);
    const [distanceMsg, setDistanceMsg] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getNovOptionsData = (inputValue, callback) => {
        callback(SELECT_NOV_OPTIONS);
    }

    const getOptionsData = (inputValue, callback) => {
        callback(unitSelectOptions);
    }


    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const selectChange = (item, parent, val) => {
        setValue(`${componentId}-${parent}-${item}-select`, val.value);
        setRefresh(prevValue => ++prevValue);
    }

    const setDefaultSelectedValue = (item, parent, val) => {
        if (typeof val !== "object")
            val = { value: "", label: "" }
        setValue(`${componentId}-${parent}-${item}-select`, val.value);
        return val;
    }

    const getCurrentNovSelectedValue = (item, parent) => {
        var selectedElem = {};
        for (const elem of SELECT_NOV_OPTIONS) {
            if (elem.value === getValues(`${componentId}-${parent}-${item}-select`)) {
                selectedElem = elem;
                break;
            }
        }
        return selectedElem;
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            if (!data["dataSource"]) {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a tab in Land Transport calculator and fill in the details!" });
                return false;
            }
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "fuelConsumption") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, amount: getValues(`${key}-${item}-amount`), unit: getValues(`${key}-${item}-amount-select`), carbonFootprint: collapseState[item].cf.value, consumption: collapseState[item].cf.consumption, upstream: collapseState[item].cf.upstream, year: collapseState[item].cf.year } }
                } else if (collapseState[item].parent === "distance") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, vehicleType: collapseState[item].vehicleType } }
                } else if (collapseState[item].parent === "distanceNew") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, vehicleType1: collapseState[item].vehicleType1 } }
                } else if (collapseState[item].parent === "fuelCost") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, value: getValues(`${key}-${item}-value`), price: getValues(`${key}-${item}-price`), carbonFootprint: collapseState[item].cf.value, consumption: collapseState[item].cf.consumption, upstream: collapseState[item].cf.upstream, year: collapseState[item].cf.year } }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    //     }
                    // }
                    // if (Object.keys(dataArray).length > 0) {
                    //     if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (fuelSourceType[dataArray[item]["dataSource"]] === "Fuel consumption") {
                            if (dataArray[item]["amount"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": dataArray[item]["amount"] === undefined || dataArray[item]["amount"] === "" ? null : parseFloat(dataArray[item]["amount"]).toFixed(3),
                                            "distance": null,
                                            "efficiency": null,
                                            "value": null,
                                            "price": null,
                                            "laden": null,
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "projectId": projectId,
                                            "fuelSourceType": {
                                                "name": fuelSourceType[dataArray[item]["dataSource"]]
                                            },
                                            "fuelType": {
                                                "name": fuelType[item]
                                            },
                                            "unitOfMeasurement": {
                                                "name": dataArray[item]["unit"].value
                                            },
                                            "fuelFactor": {
                                                "consumption": parseFloat(dataArray[item]["consumption"]),
                                                "upstream": parseFloat(dataArray[item]["upstream"]),
                                                "year": parseFloat(dataArray[item]["year"])
                                            }
                                        }
                                    ]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }
                        } else if ((fuelSourceType[dataArray[item]["dataSource"]]) === "Distance") {
                            jsonData = {
                                "projectId": projectId,
                                "data": []
                            };
                            if (dataArray[item]["vehicleType"].length > 0) {
                                for (const vt of dataArray[item]["vehicleType"]) {
                                    jsonData["data"].push({
                                        "amount": null,
                                        "distance": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`) === "" ? null : parseFloat(getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`)).toFixed(3),
                                        "efficiency": null,
                                        "value": null,
                                        "price": null,
                                        "laden": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`).value,
                                        "carbonFootprint": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`) === "" ? null : parseFloat(getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`)).toFixed(3),
                                        "projectId": projectId,
                                        "fuelSourceType": {
                                            "name": fuelSourceType[dataArray[item]["dataSource"]]
                                        },
                                        "fuelType": {
                                            "name": fuelType[item]
                                        },
                                        "unitOfMeasurement": {
                                            "name": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`).value,
                                        },
                                        "fuelFactor": {
                                            "consumption": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`) === "" ? 0 : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`),
                                            "upstream": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`) === "" ? 0 : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`),
                                            "year": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`) === "" ? new Date().getFullYear() : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`)
                                        },
                                        "vehicleType": {
                                            "name": vt,
                                        },
                                        "vehicleType1": {
                                            "name": null,
                                        }
                                    });
                                }
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please select one or more vehicle type(s) and fill the details!" });
                                return false;
                            }
                        } else if ((fuelSourceType[dataArray[item]["dataSource"]]) === "Distance New") {
                            jsonData = {
                                "projectId": projectId,
                                "data": []
                            };
                            if (dataArray[item]["vehicleType1"].length > 0) {
                                for (const vt of dataArray[item]["vehicleType1"]) {
                                    if (getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`) > 0) {
                                        jsonData["data"].push({
                                            "amount": null,
                                            "distance": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`) === "" ? null : parseFloat(getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt`)).toFixed(3),
                                            "efficiency": null,
                                            "value": null,
                                            "price": null,
                                            "laden": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`).value,
                                            "otherLaden": ((getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-laden-select`).value) === 'other') ?
                                                (getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-otherLaden`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-otherLaden`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-otherLaden`)) : null,
                                            "carbonFootprint": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`) === "" ? null : parseFloat(getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-cf`)).toFixed(3),
                                            "projectId": projectId,
                                            "fuelSourceType": {
                                                "name": fuelSourceType[dataArray[item]["dataSource"]]
                                            },
                                            "fuelType": {
                                                "name": fuelType[item]
                                            },
                                            "unitOfMeasurement": {
                                                "name": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`) === "" ? null : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-select`).value,
                                            },
                                            "fuelFactor": {
                                                "consumption": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`) === "" ? 0 : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-consumption`),
                                                "upstream": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`) === "" ? 0 : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-upstream`),
                                                "year": getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`) === undefined || getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`) === "" ? new Date().getFullYear() : getValues(`${key}-${item}-distanceCollapse-${vt}-distance-vt-year`)
                                            },
                                            "vehicleType": {
                                                "name": null,
                                            },
                                            "vehicleType1": {
                                                "name": vt,
                                            }
                                        });
                                    } else {
                                        props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                        return false;
                                    }
                                }
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please select one or more vehicle type(s) and fill the details!" });
                                return false;
                            }
                        } else if (fuelSourceType[dataArray[item]["dataSource"]] === "Fuel cost") {
                            if (dataArray[item]["value"] && dataArray[item]["price"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": null,
                                            "distance": null,
                                            "efficiency": null,
                                            "value": dataArray[item]["value"] === undefined || dataArray[item]["value"] === "" ? null : parseFloat(dataArray[item]["value"]).toFixed(3),
                                            "price": dataArray[item]["price"] === undefined || dataArray[item]["price"] === "" ? 1.36 : parseFloat(dataArray[item]["price"]).toFixed(3),
                                            "laden": null,
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "projectId": projectId,
                                            "fuelSourceType": {
                                                "name": fuelSourceType[dataArray[item]["dataSource"]]
                                            },
                                            "fuelType": {
                                                "name": fuelType[item]
                                            },
                                            "fuelFactor": {
                                                "consumption": parseFloat(dataArray[item]["consumption"]),
                                                "upstream": parseFloat(dataArray[item]["upstream"]),
                                                "year": parseFloat(dataArray[item]["year"])
                                            }
                                        }
                                    ]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values  before saving" });
                                return false;
                            }

                        }

                        axios.post(`${basePath}/calculator/fuel`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Land Transport calculator data saved!" });
                                assumptionSaveData();
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a fuel type and fill in the details!" });
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a fuel type and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let fuelassumption = getValues(`${key}-assumptions`)
        if (fuelassumption === "" || fuelassumption.length > 0) {
            let fueldata = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": fuelassumption
                }]
            }
            axios.post(`${basePath}/calculator/fuelAssumption`, fueldata, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                    //  props.toastMessage({ state: true, header: "Success", body: "Land Transport calculator data saved!" });
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error from assumption!" });
                });
        }
    }
    const onVehicleTypeChange = (val) => {
        for (const key in val) {
            dispatchCollapseState({ type: "COLLAPSE_SET_VEHICLE_TYPE", payload: { name: key, vehicleType: val[key] } });
        }
    }

    const onVehicleType1Change = (val) => {
        for (const key in val) {
            dispatchCollapseState({ type: "COLLAPSE_SET_VEHICLE_TYPE1", payload: { name: key, vehicleType1: val[key] } });
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/fuel/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Land Transport calculator cannot be deleted! Server Error!" });
                    });
                axios.delete(`${basePath}/calculator/fuelAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Land Transport Assumption cannot be deleted! Server Error" })
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(fuelSourceType).length === 0) {
                    for (const fst of props.master.fuelSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setFuelSourceType(data);
                }
                if (Object.keys(fuelType).length === 0) {
                    data = {};
                    for (const ft of props.master.fuelType) {
                        let val = "";
                        val = ft.value;
                        data[camelize(val.toLowerCase())] = ft.value
                    }
                    setFuelType(data);
                }
                if (unitSelectOptions.length === 0) {
                    setUnitSelectOptions(props.master.unitOfMeasurement);
                }
                // if (Object.keys(sources).length === 0) {
                //     for (const ffv of Object.keys(props.master.fuelFactorVolume)) {
                //         if (!years.includes(props.master.fuelFactorVolume[ffv].year)) {
                //             years.push(props.master.fuelFactorVolume[ffv].year);
                //         }
                //         if (sources[props.master.fuelFactorVolume[ffv].year] === undefined) {
                //             sources[props.master.fuelFactorVolume[ffv].year] = { source: [] };
                //         }
                //         if (!sources[props.master.fuelFactorVolume[ffv].year].source.includes(props.master.fuelFactorVolume[ffv].source)) {
                //             sources[props.master.fuelFactorVolume[ffv].year].source.push(props.master.fuelFactorVolume[ffv].source);
                //             sources[props.master.fuelFactorVolume[ffv].year].source.sort();
                //         }
                //     }
                //     years = years.sort((a, b) => a - b);
                // }
            }
            if (Object.keys(sources).length === 0) {
                for (const ffm of Object.keys(props.master.fuelFactorMaster)) {
                    if (!years.includes(props.master.fuelFactorMaster[ffm].year)) {
                        years.push(props.master.fuelFactorMaster[ffm].year);
                    }
                    if (sources[props.master.fuelFactorMaster[ffm].year] === undefined) {
                        sources[props.master.fuelFactorMaster[ffm].year] = { source: [] };
                    }
                    if (!sources[props.master.fuelFactorMaster[ffm].year].source.includes(props.master.fuelFactorMaster[ffm].source)) {
                        sources[props.master.fuelFactorMaster[ffm].year].source.push(props.master.fuelFactorMaster[ffm].source);
                        sources[props.master.fuelFactorMaster[ffm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (unitSelectOptions.length > 0) {
                if (props.data !== undefined && props.data.data.length > 0) {
                    if (props.data.data[0].fuelSourceType.name === "Fuel consumption") {
                        data["dataSource"] = "fuelConsumption";
                        setKey(`${componentId}-fuelConsumption`);
                    } else if (props.data.data[0].fuelSourceType.name === "Distance") {
                        data["dataSource"] = "distance";
                        setKey(`${componentId}-distance`);
                    } else if (props.data.data[0].fuelSourceType.name === "Distance New") {
                        data["dataSource"] = "distanceNew";
                        setKey(`${componentId}-distanceNew`);
                    } else if (props.data.data[0].fuelSourceType.name === "Fuel cost") {
                        data["dataSource"] = "fuelCost";
                        setKey(`${componentId}-fuelCost`);
                    }
                } else {
                    setIsDataPresent(false);
                }
            }
            //fuelAssumption
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.data[0].fuelSourceType.name === "Fuel consumption") {
                    data["dataSource"] = "fuelConsumption";
                    setKey(`${componentId}-fuelConsumption`);
                } else if (props.data.data[0].fuelSourceType.name === "Distance New") {
                    data["dataSource"] = "distanceNew";
                    setKey(`${componentId}-distanceNew`);
                } else if (props.data.data[0].fuelSourceType.name === "Fuel cost") {
                    data["dataSource"] = "fuelCost";
                    setKey(`${componentId}-fuelCost`);
                }
            } else {
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [unitSelectOptions, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {

            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var vType = {};
                    var vType1 = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        switch (obj.fuelSourceType.name) {
                            case "Fuel consumption":
                                fSourceType = "fuelConsumption";
                                break;
                            case "Distance":
                                fSourceType = "distance";
                                break;
                            case "Distance New":
                                fSourceType = "distanceNew";
                                break;
                            case "Fuel cost":
                                fSourceType = "fuelCost";
                                break;
                            default:
                                fSourceType = "fuelConsumption";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.fuelType.name.toLowerCase(), parent: fSourceType, status: true } });
                        // dispatchCollapseState({ type: "COLLAPSE_SET_PARENT", payload: { name: obj.fuelType.name.toLowerCase(), parent: fSourceType, status: true } });
                        if (obj.unitOfMeasurement !== null) {
                            for (const u of unitSelectOptions) {
                                if (u.value === obj.unitOfMeasurement.name) {
                                    if (fSourceType === "fuelConsumption") {
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-amount`, obj.amount);
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-amount-select`, { value: u.value, label: u.shortName });
                                        if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                            props.data.dataAssumption.forEach(obj => {
                                                setValue(`${componentId}-fuelConsumption-assumptions`, obj.assumptions);
                                            });
                                        }
                                    } else if (fSourceType === "distance") {
                                        if (!vType[obj.fuelType.name.toLowerCase()]) {
                                            vType[obj.fuelType.name.toLowerCase()] = [];
                                        }
                                        vType[obj.fuelType.name.toLowerCase()].push(obj.vehicleType.name);
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType.name}-distance-vt`, obj.distance);
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType.name}-distance-vt-select`, { value: u.value, label: u.shortName });
                                        if (obj.laden !== undefined && obj.laden !== null && obj.laden !== "") {
                                            setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType.name}-distance-vt-laden-select`, { value: ladenOptions[obj.laden].value, label: ladenOptions[obj.laden].label });
                                        }
                                    } else if (fSourceType === "distanceNew") {
                                        if (!vType1[obj.fuelType.name.toLowerCase()]) {
                                            vType1[obj.fuelType.name.toLowerCase()] = [];
                                        }
                                        vType1[obj.fuelType.name.toLowerCase()].push(obj.vehicleType1.name);
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType1.name}-distance-vt`, obj.distance);
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType1.name}-distance-vt-select`, { value: u.value, label: u.shortName });
                                        if (obj.laden !== undefined && obj.laden !== null && obj.laden !== "") {
                                            setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType1.name}-distance-vt-laden-select`, { value: ladenOptions[obj.laden].value, label: ladenOptions[obj.laden].label });
                                        }
                                        //  if (obj.laden !== undefined && obj.laden !== null && obj.laden !== "") {
                                        setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-distanceCollapse-${obj.vehicleType1.name}-distance-vt-otherLaden`, obj.otherLaden);
                                        //   }
                                        if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                            props.data.dataAssumption.forEach(obj => {
                                                setValue(`${componentId}-distanceNew-assumptions`, obj.assumptions);
                                            });
                                        }
                                    }
                                }
                            }
                        } else {
                            if (fSourceType === "fuelCost") {
                                setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-value`, obj.value);
                                setValue(`${componentId}-${fSourceType}-${obj.fuelType.name.toLowerCase()}-price`, obj.price);
                                //fuelAssumption
                                if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                    props.data.dataAssumption.forEach(obj => {
                                        setValue(`${componentId}-fuelCost-assumptions`, obj.assumptions);
                                    });
                                }
                            }
                        }
                        st = obj.createdAt;
                    });
                    setVehicleType(vType);
                    setVehicleType1(vType1);
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }

        // eslint-disable-next-line
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);

    useEffect(() => {
        if (key) {
            let key_arr = key.split('-');
            if (key_arr[4] && key_arr[4] == 'distance') {
                setDistanceMsg(true)
            } else {
                setDistanceMsg(false)
            }
        } else {
            setDistanceMsg(false)
        }
    }, [key]);
    let collapseFCAssumption, collapseDistanceAssumption, collapseFuelCostAssumption;
    if (isMountedRef.current) {
        Object.keys(collapseState).forEach(item => {
            if (collapseState[item].status === true) {
                collapseFCAssumption = <FuelAssumption
                    key={`${componentId}-fuelConsumption-collapse`}
                    collapseControll={getLogic(item, "fuelConsumption")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-fuelConsumption`}
                    inputName={item}
                    textValue={getValues(`${componentId}-fuelConsumption-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
                collapseDistanceAssumption = <FuelAssumption
                    key={`${componentId}-distanceNew-collapse`}
                    collapseControll={getLogic(item, "distanceNew")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-distanceNew`}
                    inputName={item}
                    textValue={getValues(`${componentId}-distanceNew-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
                collapseFuelCostAssumption = <FuelAssumption
                    key={`${componentId}-fuelCost-collapse`}
                    collapseControll={getLogic(item, "fuelCost")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-fuelCost`}
                    inputName={item}
                    textValue={getValues(`${componentId}-fuelCost-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
            }
        })
    }

    const fuelSourceTabs = {
        [`${componentId}-fuelConsumption`]: (
            <Tab eventKey={`${componentId}-fuelConsumption`} title="Fuel Consumption" disabled={key !== "" && key !== `${componentId}-fuelConsumption`} key={`${componentId}-fuelConsumption`} >
                {/* {evData ? */}
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-fuelPills`} className="flex-row fuelPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <>
                                                {item === "hybrid" ?
                                                    <Nav.Item key={`${componentId}-fuelConsumption-${item}-collapsePills`} className="fuelPills nav-item1" >
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "fuelConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "fuelConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-fuelConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "fuelConsumption", status: !collapseState[item].status } })}>{item === "cng" || item === "lpg" ? item.toLocaleUpperCase() : item === "hybrid" ? null : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                    :
                                                    <Nav.Item key={`${componentId}-fuelConsumption-${item}-collapsePills`}  >
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "fuelConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "fuelConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-fuelConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "fuelConsumption", status: !collapseState[item].status } })}>{item === "cng" || item === "lpg" ? item.toLocaleUpperCase() : item === "hybrid" ? null : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <div className="fuelPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseFuelConsumptionCustom
                                            key={`${componentId}-fuelConsumption-${item}-collapse`}
                                            collapseControll={getLogic(item, "fuelConsumption")}
                                            id={`${componentId}-fuelConsumption`}
                                            inputName={item}
                                            inputValue={getValues(`${componentId}-fuelConsumption-${item}-amount`)}
                                            inputLabel={item === "cng" ? "CNG Amount" : item === "lpg" ? "LPG Amount" : `${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                            inputPlaceholder={"0.0"}
                                            selectName={item}
                                            selectLabel={"Units"}
                                            selectValue={getValues(`${componentId}-fuelConsumption-${item}-amount-select`)}
                                            loadOptions={getOptionsData}
                                            primaryCountry={primaryCountry}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseFCAssumption}
                    </Row>
                </Tab.Container>

            </Tab>

        ),
        /* [`${componentId}-distance`]: (
             <Tab eventKey={`${componentId}-distance`} title="Distance" disabled={key !== "" && key !== `${componentId}-distance`} key={`${componentId}-distance`}>
                 <Tab.Container>
                     <Row>
                         <Col sm={12}>
                             <Nav variant="pills" id={`${componentId}-distancePills`} className="flex-row fuelPills">
                                 {
                                     Object.keys(collapseState).map(item => {
                                         return (
                                             <Nav.Item key={`${componentId}-distance-${item}-collapsePills`}>
                                                 <Nav.Link eventKey={item} active={collapseState[item].parent === "distance" && collapseState[item].status === true} disabled={collapseState[item].parent !== "distance" && collapseState[item].status === true} aria-controls={`${componentId}-distance-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "distance", status: !collapseState[item].status } })}>{item === "cng" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                             </Nav.Item>
                                         )
                                     })
                                 }
                             </Nav>
                         </Col>
                         <Col sm={12}>
                             <div className="fuelPillsCollapse">
                                 {
                                     Object.keys(collapseState).map(item => (
                                         <CollapseDistanceCustom
                                             key={`${componentId}-distance-${item}-collapse`}
                                             collapseControll={getLogic(item, "distance")}
                                             id={`${componentId}-distance`}
                                             primaryCountry={primaryCountry}
 
                                             label={item === "cng" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                             inputName={item}
                                             inputLabel={`Distance reduced per vehicle`}
                                             inputPlaceholder={"0.0"}
                                             selectName={item}
                                             selectLabel={"Units"}
                                             loadOptions={getOptionsData}
 
                                             vehicleType={vehicleType}
                                             onVehicleTypeChange={(val) => onVehicleTypeChange(val)}
 
                                             inputNumberOfVehiclesLabel={`Number of Vehicles`}
                                             inputNumberOfVehiclesName={`${item}-nov`}
 
                                             selectNumberOfVehiclesName={`${item}-nov`}
                                             selectNumberOfVehiclesLabel={"Units"}
                                             selectNumberOfVehiclesValue={getValues(`${componentId}-distance-${item}-nov-select`) === undefined ? setDefaultSelectedValue(`${item}-nov`, "distance", SELECT_NOV_OPTIONS[1]) : getCurrentNovSelectedValue(`${item}-nov`, "distance")}
                                             loadOptionsNumberOfVehicles={getNovOptionsData}
                                             onChangeNumberOfVehicles={(val) => selectChange(`${item}-nov`, "distance", val)}
 
                                             control={control}
                                             form={form}
                                             getValues={getValues}
                                             register={register}
                                             errors={errors}
                                             cFootprint={(val) => calulateTotalCF(val)}
                                         />
                                     ))
                                 }
                             </div>
                         </Col>
                     </Row>
                 </Tab.Container>
             </Tab>
         ), */
        [`${componentId}-distanceNew`]: (
            <Tab eventKey={`${componentId}-distanceNew`} title="Distance" disabled={key !== "" && key !== `${componentId}-distanceNew`} key={`${componentId}-distanceNew`}>
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-distancePills`} className="flex-row fuelPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <Nav.Item key={`${componentId}-distanceNew-${item}-collapsePills`}>
                                                <Nav.Link eventKey={item} active={collapseState[item].parent === "distanceNew" && collapseState[item].status === true} disabled={collapseState[item].parent !== "distanceNew" && collapseState[item].status === true} aria-controls={`${componentId}-distanceNew-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "distanceNew", status: !collapseState[item].status } })}>{item === "cng" || item === "lpg" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <div className="fuelPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <NewCollapseDistanceCustom
                                            key={`${componentId}-distanceNew-${item}-collapse`}
                                            collapseControll={getLogic(item, "distanceNew")}
                                            id={`${componentId}-distanceNew`}
                                            primaryCountry={primaryCountry}
                                            label={item === "cng" ? item.toLocaleUpperCase() : item === "lpg" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                            inputName={item}
                                            inputLabel={`Distance reduced per vehicle`}
                                            inputPlaceholder={"0.0"}
                                            selectName={item}
                                            selectLabel={"Units"}
                                            loadOptions={getOptionsData}
                                            vehicleType1={vehicleType1}
                                            onVehicleTypeChange={(val) => onVehicleType1Change(val)}

                                            inputNumberOfVehiclesLabel={`Number of Vehicles`}
                                            inputNumberOfVehiclesName={`${item}-nov`}

                                            selectNumberOfVehiclesName={`${item}-nov`}
                                            selectNumberOfVehiclesLabel={"Units"}
                                            selectNumberOfVehiclesValue={getValues(`${componentId}-distanceNew-${item}-nov-select`) === undefined ? setDefaultSelectedValue(`${item}-nov`, "distanceNew", SELECT_NOV_OPTIONS[1]) : getCurrentNovSelectedValue(`${item}-nov`, "distanceNew")}
                                            loadOptionsNumberOfVehicles={getNovOptionsData}
                                            onChangeNumberOfVehicles={(val) => selectChange(`${item}-nov`, "distanceNew", val)}

                                            control={control}
                                            form={form}
                                            getValues={getValues}
                                            register={register}
                                            errors={errors}

                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseDistanceAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        ),
        [`${componentId}-fuelCost`]: (
            <Tab eventKey={`${componentId}-fuelCost`} title="Fuel Cost" disabled={key !== "" && key !== `${componentId}-fuelCost`} key={`${componentId}-fuelCost`}>
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-fuelCostPills`} className="flex-row fuelPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <>
                                                {item === "hybrid" ?
                                                    <Nav.Item key={`${componentId}-fuelCost-${item}-collapsePills`} className="fuelPills nav-item1">
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "fuelCost" && collapseState[item].status === true} disabled={collapseState[item].parent !== "fuelCost" && collapseState[item].status === true} aria-controls={`${componentId}-fuelCost-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "fuelCost", status: !collapseState[item].status } })}>{item === "cng" || item === "lpg" ? item.toLocaleUpperCase() : item === "hybrid" ? null : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                    :
                                                    <Nav.Item key={`${componentId}-fuelCost-${item}-collapsePills`}>
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "fuelCost" && collapseState[item].status === true} disabled={collapseState[item].parent !== "fuelCost" && collapseState[item].status === true} aria-controls={`${componentId}-fuelCost-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "fuelCost", status: !collapseState[item].status } })}>{item === "cng" || item === "lpg" ? item.toLocaleUpperCase() : item === "hybrid" ? null : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                }
                                            </>

                                        )
                                    })
                                }
                            </Nav>

                        </Col>
                        <Col sm={12}>
                            <div className="fuelPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseFuelCostCustom
                                            key={`${componentId}-fuelCost-${item}-collapse`}
                                            collapseControll={getLogic(item, "fuelCost")}
                                            label={item === "cng" ? "CNG Amount" : item === "lpg" ? "LPG Amount" : `${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                            id={`${componentId}-fuelCost`}
                                            inputName={item}
                                            inputValue={getValues(`${componentId}-fuelCost-${item}-value`)}
                                            inputLabel={`Expected yearly savings in EURO`}
                                            inputPlaceholder={"0.0"}
                                            inputPrice={getValues(`${componentId}-fuelCost-${item}-price`)}
                                            primaryCountry={primaryCountry}
                                            defaultCostPrice={defaultCostPrice}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseFuelCostAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        )
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#F99500", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                    <span>Land Transport</span> {/* changes */}
                                   
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Land Transport calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            {
                                distanceMsg ? <span style={{ paddingTop: "44px", paddingLeft: "10px", paddingRight: "0px", color: "#eb4f6b", fontSize: "15px" }}>This distance calculator is currently being re-developed. <br /> <span style={{ paddingLeft: "10px" }}>Please use the new distance calculator instead for any fuel distance calculation !</span></span> : (<></>)
                            }
                            <p style={{ paddingLeft: "10px", textAlign: "left" }}>This module has been renamed from Fuel to Land Transport to better reflect its purpose. </p>
                            
                            <p style={{ paddingLeft: "10px", textAlign: "left" , fontSize: "12px" }}>Please select one of the following data sources you have available </p>
                            <div className="fuelTabs">
                                <Tabs
                                    id={`${componentId}-fuelTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >

                                    {
                                        Object.keys(fuelSourceTabs).map(tabItem => {
                                            if (tabItem === key) {
                                                return (fuelSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }


                                    {
                                        Object.keys(fuelSourceTabs).map(tabItem => {
                                            if (tabItem !== key) {
                                                return (fuelSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }
                                </Tabs>
                            </div>
                        </div>
                        <div className="fuelBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in fuel consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(memo(FuelTabs)));